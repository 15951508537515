
import { FloorPlanObject } from "./floor-plan-object.js";

export class FloorPlanObjectTable extends FloorPlanObject {
  constructor(floorPlanLayout, dataJson='') {
    super(floorPlanLayout, dataJson);
    let _this = this;
    
    _this.settings = {
      width: 50,
      height: 50,
      fontSize: 11,
    }
    _this.kind = 'table';

    _this.loadOrCreateShape();
    _this.bindMainEvents();    
    _this.bindEvents();    
  }

  bindEvents () {
    let _this = this;

    //----------------------------- START EDIT MODE
    if(_this.floorPlanLayout.mode == 'edit'){
      _this.textNode.on('pointerdown', function (e) {
        _this.floorPlanLayout.currentShape = e.target.getParent();
        if(!_this.floorPlanLayout.currentShape.draggable()){
          _this.floorPlanLayout.clearDragSelection();
          _this.floorPlanLayout.closeMenuNodes();
        }
      });
    
      _this.textNode.on('pointerdblclick', function (e) {
        _this.floorPlanLayout.currentShape = e.target.getParent();

        _this.floorPlanLayout.clearDragSelection();
        _this.floorPlanLayout.openMenuNodeMember(_this.kind);
      });

      _this.textNode2.on('pointerdown', function (e) {
        _this.floorPlanLayout.currentShape = e.target.getParent();
        if(!_this.floorPlanLayout.currentShape.draggable()){
          _this.floorPlanLayout.clearDragSelection();
          _this.floorPlanLayout.closeMenuNodes();
        }
      });
      
      _this.textNode2.on('pointerdblclick', function (e) {
        _this.floorPlanLayout.currentShape = e.target.getParent();
        
        _this.floorPlanLayout.clearDragSelection();
        _this.floorPlanLayout.openMenuNodeMember(_this.kind);
      });

      _this.group.on('transform', function () {
        _this.textNode.setAttrs({
          width: _this.box.width(),
          y: (_this.box.height() / 2) - (_this.settings.fontSize/2)
        });
        _this.textNode2.setAttrs({
          width: _this.box.width(),
        });
      });
    }
    //----------------------------- END EDIT MODE

    //----------------------------- START NORMAL MODE
    if(_this.floorPlanLayout.mode == 'normal'){
      _this.group.on('mouseover', function () {      
        document.body.style.cursor = 'pointer';
      });
      _this.group.on('mouseout', function () {        
        document.body.style.cursor = 'default';
      });
      
      _this.box.on('pointerdown', function (e) {
        let num = _this.textNode2.text().substring(1);
        //$('#canvas-container').data('floor-plan').changeColor(num, 'red');

        $('#js-check-table-button').attr('href', _this.updateQueryStringParameter($('#js-check-table-button').attr('href'), 'source', num));
        $.rails.fire($('#js-check-table-button').get(0), 'click');
      });
      
      _this.textNode.on('pointerdown', function (e) {
        let num = _this.textNode2.text().substring(1);
        //$('#canvas-container').data('floor-plan').changeColor(num, 'red');

        $('#js-check-table-button').attr('href', _this.updateQueryStringParameter($('#js-check-table-button').attr('href'), 'source', num));
        $.rails.fire($('#js-check-table-button').get(0), 'click');
      });
      
      _this.textNode2.on('pointerdown', function (e) {
        let num = _this.textNode2.text().substring(1);
        //$('#canvas-container').data('floor-plan').changeColor(num, 'red');

        $('#js-check-table-button').attr('href', _this.updateQueryStringParameter($('#js-check-table-button').attr('href'), 'source', num));
        $.rails.fire($('#js-check-table-button').get(0), 'click'); 
      });
    }
    //----------------------------- END NORMAL MODE
    
  }

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  createShape(){
    let _this = this;
    
    _this.box = new Konva.Rect({
      x: 0,
      y: 0,
      stroke: '#FFFFFF',
      strokeWidth: 1,
      fill: 'grey', 
      width: _this.settings.width,
      height: _this.settings.height,
      strokeScaleEnabled: false,
      name: 'Shape',
      kind: _this.kind
    });

    _this.textNode = new Konva.Text({
      text: 'Name',
      x: 0,
      y: (_this.box.height() / 2) - (_this.settings.fontSize/2),
      fill: '#FFFFFF',
      width: _this.settings.width,
      fontSize: _this.settings.fontSize,
      align: 'center',
      name: 'Label'
    });

    _this.textNode2 = new Konva.Text({
      text: '#Num',
      x: 0,
      y: 0,
      fill: '#FFFFFF',
      padding: 2,
      width: _this.settings.width,
      fontSize: (_this.settings.fontSize - 2),
      align: 'right',
      name: 'Id'
    });

    _this.group = new Konva.Group({
      x: 0,
      y: 0,
      draggable: false,
      name: 'FloorPlanObjectTable'
    });


    _this.group.add(_this.box);
    _this.group.add(_this.textNode);
    _this.group.add(_this.textNode2);
  }
}
