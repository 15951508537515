import { FloorPlanLayout } from "./floor-plan-layout.js";
import '../../vendor/konva.js'

export class FloorPlan {
  
  initialize(aspectRatio) {
    let _this = this;

    setTimeout(function() {
      $('#canvas-container').html('');
      _this.floorPlanLayout = new FloorPlanLayout('#canvas-container', aspectRatio, $('#canvas-container').data('mode'));

    }, 300);

  }

  changeAspectRatio(aspectRatio){
    let _this = this;

    _this.floorPlanLayout.changeAspectRatio(aspectRatio);
  }

  changeColor(num, color){
    let _this = this;

    _this.floorPlanLayout.changeColor(num, color);
  }
}
