
import { Controller } from 'stimulus';
import { Select2 } from '../../lib/select2.js';
import { Datetimepicker } from '../../lib/datetimepicker.js';
import { Daterangepicker } from '../../lib/daterangepicker.js';
import { ChartComponent } from '../../lib/chart-component.js';
import { SbAdmin2 } from '../../lib/sb-admin-2.js';
import { Scannerdetection } from '../../lib/scannerdetection.js';
import { TooltipPopover } from '../../lib/tooltip-popover.js';
import { FlashMessages } from '../../lib/flash-messages.js';
import { Modals } from '../../lib/modals.js';

export default class extends Controller {
  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SbAdmin2 controller");

      this.initSelect2();
      this.initDatetimepicker();
      this.initDaterangepicker();
      this.initChart();
      this.initSbAdmin2();
      this.initScannerdetection();
      this.initTooltipPopover();
    }

    if($('.js-open-modal').length != 0){
      $.rails.fire($('.js-open-modal').get(0), 'click');
    }
  }

  // disable button
  modalOpenEntitiesDisableButton(event){
    const [data, status, xhr] = event.detail;
    
    $('#js-modalOpenEntitiesButton').addClass('disabled');
  }

  // Success modal open window (new, edit and show)
  modalOpenEntitiesSuccess(event){
    const [data, status, xhr] = event.detail;
    const modalClass = event.target.dataset.modalClass;
    $('#js-modalOpenEntitiesButton').removeClass('disabled');
    
    event.stopPropagation();
    Modals.modalShow();
    Modals.modalSize(modalClass);
    Modals.modalFill(xhr.responseText);
  }

  // Fail modal open window (no permision for example)
  modalOpenEntitiesError(event){
    const [data, status, xhr] = event.detail;
    $('#js-modalOpenEntitiesButton').removeClass('disabled');
    
    event.stopPropagation();
    FlashMessages.message(this.parseFlashMessage(xhr));
  }


  initTooltipPopover(){
    let tooltipPopover = new TooltipPopover();
    tooltipPopover.initialize();
  }

  initSelect2(){
    let select2 = new Select2();
    select2.initialize();
  }

  initDatetimepicker(){
    let datetimepicker = new Datetimepicker();
    datetimepicker.initialize();
  }

  initDaterangepicker(){
    let daterangepicker = new Daterangepicker();
    daterangepicker.initialize();
  }

  initChart(){
    let chartComponent = new ChartComponent();
    chartComponent.initialize();
  }
    
  initSbAdmin2(){
    let sbAdmin2 = new SbAdmin2();
    sbAdmin2.initialize();
  }

  initScannerdetection(){
    let scannerdetection = new Scannerdetection();
    scannerdetection.initialize();
  }
}
