
import  ApplicationController from "../application_controller.js"
import { RefundCalculation } from "../../lib/refunds_form/refund_calculation.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SaleForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;

      $(controllerContainer).on('cocoon:after-insert', '#sale_items_container', function(e, insertedItem, originalEvent) {
        let saleItem = $(insertedItem).closest('.nested-fields');
        _this.bindSaleItem(controllerContainer, saleItem);
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('cocoon:before-insert', '#sale_items_container', function(e, insertedItem, originalEvent) {
        let productIdField = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id');
        let amountField = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_amount_field');
        let productIdValue = productIdField.select2('val');
        let addedProductIds = $("#sale_items_container .js-sale_item_product_id:visible").map(function() { return $(this).val(); }).get()

        productIdField.removeClass('is-invalid');
        amountField.removeClass('is-invalid');

        if (productIdValue == ''){
          productIdField.addClass('is-invalid');
          productIdField.select2('focus');
          e.preventDefault();
        }
        if (amountField.val() == ''){
          amountField.addClass('is-invalid');
          amountField.trigger('focus');
          e.preventDefault();
        }

        if (jQuery.inArray(productIdValue, addedProductIds) !== -1){
          let input = $('#sale_items_container .js-sale_item_product_id option[value="'+productIdValue+'"]:selected')
          let container = input.closest('.nested-fields');
          let saleItemAmountField = container.find('.js-sale_item_amount_field');
          
          let saleItemAmountValue = parseFloat(saleItemAmountField.val());
          saleItemAmountValue = (isNaN(saleItemAmountValue)) ? 0.0 : saleItemAmountValue;

          let amountFieldValue = parseFloat(amountField.val());
          amountFieldValue = (isNaN(amountFieldValue)) ? 0.0 : amountFieldValue;

          saleItemAmountField.val((saleItemAmountValue+amountFieldValue));
          saleItemAmountField.trigger('input');
          _this.cleanNestedSaleItem(controllerContainer);
          e.preventDefault();
        }
        else{
          _this.populateSaleItem(controllerContainer, insertedItem);
        }

      });

      $('#sale_items_container .nested-fields, #nested_sale_item_data').each(function(){
        _this.bindSaleItem(controllerContainer, $(this));
      });

      $(controllerContainer).on('cocoon:after-remove', '#sale_items_container', function(e, insertedItem, originalEvent) {
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('cocoon:after-insert', '#sale_payments_container', function(e, insertedItem, originalEvent) {
        _this.updateSalePaymentAmountReadonly(controllerContainer);
      });

      $(controllerContainer).on('cocoon:after-remove', '#sale_payments_container', function(e, insertedItem, originalEvent) {
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('input', '.js-sale_discount_value_field', function() {
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('click', '.js-sale_discount_kind_dropdown_option', function(event) {
        event.preventDefault();
        _this.updateSaleDiscountKind(controllerContainer, this);
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('keydown', function(e){
        if(e.keyCode == 13) {
          if ($('#sale_nested_amount').is(':focus') || $('#sale_nested_discount_value').is(':focus')){ 
            $('#js-add_sale_item').trigger('click');
            e.preventDefault();
          }
        }
      });

      _this.updateSaleInvoiceTypeField(controllerContainer);
      _this.updateExternalInvoicedData(controllerContainer);
      _this.updateMultiPayment(controllerContainer);
      setTimeout(function() {
        _this.updateSalePaymentAmountReadonly(controllerContainer);
      }, 200);      
    }
  }

  // Triggerado por el click para multiples pagos
  toggleMultiPayment(){
    let _this = this;
    let controllerContainer = _this.context.element;    
    _this.setMultiPayment(controllerContainer);
    _this.updateMultiPayment(controllerContainer);
  }

  // Triggerado por el click para ver observaciones
  toggleOpenObservation(){
    let _this = this;
    let controllerContainer = _this.context.element;

    let cardBody = $(controllerContainer).find('.card-body');
    cardBody.toggleClass('open-observation');
  }

  // Triggerado por el cambio de cliente
  changeClient(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateClientData(controllerContainer);
  }


  // Triggerado por el cambio de producto
  changeNestedProductId(){
    let _this = this;

    $('#nested_sale_item_data .js-sale_item_amount_field')[0].focus();
  }


  // Triggerado por el cambio el canal
  changeSaleChannel(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setSaleInvoiceTypeField(controllerContainer);

      //Refresh all prices
      $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
        let saleItem = $(this);
        _this.updateSaleItemData(controllerContainer, saleItem);
      });
    _this.updateSaleTotals(controllerContainer);
  }

  // Triggerado por el tipo de factura
  changeInvoiceType(){
    let _this = this;
    let controllerContainer = _this.context.element;

    //Refresh all prices
      $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
        let saleItem = $(this);
        _this.updateSaleItemData(controllerContainer, saleItem);
      });
    _this.updateSaleTotals(controllerContainer);
  }


  clickProductButton(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let button = $(event.currentTarget);

    _this.addProduct(controllerContainer, button);
  }

  changeExternalInvoiced(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let option = $(event.currentTarget);

    event.preventDefault();
    _this.setExternalInvoiced(controllerContainer, option);
  }


  // Triggerado por el cambio de monto de pago
  changeSalePaymentAmount(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let field_changed = $(event.currentTarget);

    if ($('#sale_payments_container .nested-fields:visible').length == 2){

      $('#sale_payments_container .nested-fields:visible').each(function(){
        if($(this).find('.js-payment_method_amount_field').get(0) != field_changed.get(0)){
          let payment_method_amount_field = $(this).find('.js-payment_method_amount_field');
          let saleSubtotalField = $(controllerContainer).find('.js-sale_subtotal_field');
          let saleDiscountField = $(controllerContainer).find('.js-sale_discount_field');
          let saleClientTaxesField = $(controllerContainer).find('.js-sale_client_tax_field');
      
          let subtotal = parseFloat(saleSubtotalField.val());
          subtotal = (isNaN(subtotal)) ? 0.0 : subtotal;
      
          let discount = parseFloat(saleDiscountField.val());
          discount = (isNaN(discount)) ? 0.0 : discount;
      
          let clientTax = parseFloat(saleClientTaxesField.val());
          clientTax = (isNaN(clientTax)) ? 0.0 : clientTax;

          let value_changed = parseFloat(field_changed.val());
          value_changed = (isNaN(value_changed)) ? 0.0 : value_changed;
      
          
          let total_without_card_charge_value = subtotal - discount + clientTax;
          let calculated_amount = total_without_card_charge_value - value_changed
          payment_method_amount_field.val(calculated_amount);
        } 
      })

    }
    _this.updateSaleTotals(controllerContainer); 
  }

  
  private

  updateSaleDiscountKind(controllerContainer, option){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let saleDiscountKindField = $(controllerContainer).find('.js-sale_discount_kind_field');
    let saleDiscountKindContainer = $(controllerContainer).find('.js-sale_discount_kind_container');

    saleDiscountKindField.val(value);
    saleDiscountKindContainer.html(icon);
  }

  setMultiPayment(controllerContainer){
    let multi_payment_field = $(controllerContainer).find('.js-sale_multi_payment_field');
    
    if(multi_payment_field.val() == 'true'){
      multi_payment_field.val('false');
  
      let first_sale_payment = $('#sale_payments_container .nested-fields:visible').first();
      $(controllerContainer).find('#sale_payments_container .nested-fields:visible').each(function(){
        if($(this).get(0) != first_sale_payment.get(0)){
          $(this).find('.remove_fields').trigger('click');
        }
      });
    }
    else{
      multi_payment_field.val('true');
    }
  }

  updateMultiPayment(controllerContainer){
    let button = $(controllerContainer).find('#js-multi_payment-button');
    let multi_payment_field = $(controllerContainer).find('.js-sale_multi_payment_field');
    let add_button = $(controllerContainer).find('#js-add_sale_payment');
    let sale_payment_amount_containers = $(controllerContainer).find('.js-sale_payment_amount-container');
    
    if(multi_payment_field.val() == 'true'){
      sale_payment_amount_containers.fadeIn();
      add_button.fadeIn();
      $(controllerContainer).find('#sale_payments_container .nested-fields').each(function(){
        $(this).find('.remove_fields').fadeIn();
      });

      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      sale_payment_amount_containers.hide();
      add_button.hide();
      $(controllerContainer).find('#sale_payments_container .nested-fields').each(function(){
        $(this).find('.remove_fields').hide();
      });
      
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }

  setExternalInvoiced(controllerContainer, option){
    let _this = this;
    let saleExternalInvoicedField = $(controllerContainer).find('.js-sale_external_invoiced_field');
    
    let value = $(option).attr('href');
    saleExternalInvoicedField.val(value);
    _this.updateExternalInvoicedData(controllerContainer);
  }

  updateExternalInvoicedData(controllerContainer){
    let saleExternalInvoicedValue = $(controllerContainer).find('.js-sale_external_invoiced_field').val();
    let optionSelected = $(controllerContainer).find('.js-sale_external_invoiced_'+saleExternalInvoicedValue);
    let saleExternalInvoicedContainer = $(controllerContainer).find('.js-sale_external_invoiced_container');
    let saleInvoiceNumberField = $(controllerContainer).find('.js-sale_invoice_number_field');
    
    let icon = $(optionSelected).find('.icon').html();
    let text = $(optionSelected).find('.text').html();

    saleExternalInvoicedContainer.html(icon);
    saleInvoiceNumberField.attr('placeholder', text);

    if (saleExternalInvoicedValue == 'true'){
      saleInvoiceNumberField.attr('readonly','readonly');
      saleInvoiceNumberField.val('');
    } else {
      saleInvoiceNumberField.removeAttr('readonly');
    }
  }


  // Agrego un producto
  addProduct(controllerContainer, button){
    let _this = this;
    let productId = $(button).data('id');
    let productName = $(button).data('name');
    let productSymbol = $(button).data('symbol');
    
    $('#nested_sale_item_data .js-sale_item_symbol_container').html(productSymbol);
    $('#nested_sale_item_data .js-sale_item_amount_field').val(1);
    $('#nested_sale_item_data .js-sale_item_product_id').append('<option selected="selected" value="'+productId+'">'+productName+'<option>').trigger('change.select2');
    
    let saleItem = $(controllerContainer).find('#nested_sale_item_data');

    _this.updateSaleItemData(controllerContainer, saleItem);
    $('#js-add_sale_item').trigger('click');
  }


  // Actualiza info del cliente:
  updateClientData(controllerContainer){
    let _this = this;
    let clientIdField = $(controllerContainer).find('.js-client_id_field');
    let clientId = clientIdField.select2('val');
    let saleClientTaxProfileField = $(controllerContainer).find('.js-sale_client_tax_profile_field');
    let saleClientDniField = $(controllerContainer).find('.js-sale_client_dni_field');
    let saleClientCuitField = $(controllerContainer).find('.js-sale_client_cuit_field');
    let saleClientAddressField = $(controllerContainer).find('.js-sale_client_address_field');
    
    let saleDiscountField = $(controllerContainer).find('.js-sale_discount_value_field');
    let saleTaxPercField = $(controllerContainer).find('.js-sale_tax_perc_field');

    let saleSaleChannelField = $(controllerContainer).find('.js-sale_sale_channel_field');

    let productsModalButton = $(controllerContainer).find('#products_modal_button');

    let url = $(clientIdField).data('url-two');
    
    if(clientId != ''){
      url = url.replace('/the_id', '/'+clientId);
      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let taxProfile = data.tax_profile;
        let cuit = data.cuit;
        let dni = data.dni;
        let address = data.address;
        let discountRate = data.discount_rate;
        let taxPerc = data.tax_perc;
        let paymentMethod = data.payment_method;
        let saleChannel = data.sale_channel;




        //Seteo perfil impositivo y tipo de cliente
        saleClientTaxProfileField.val(taxProfile);
        saleClientCuitField.val(cuit);
        saleClientDniField.val(dni);
        saleClientAddressField.val(address);

        //Seteo medio de pago y canal por defecto del cliente
        saleSaleChannelField.val(saleChannel);
        saleSaleChannelField.trigger('change');

        let first_sale_payment = $('#sale_payments_container .nested-fields:visible').first();
        $(controllerContainer).find('#sale_payments_container .nested-fields:visible').each(function(){
          if($(this).get(0) != first_sale_payment.get(0)){
            $(this).find('.remove_fields').trigger('click');
          }
        });
        let paymentMethodField = $(first_sale_payment).find('.js-payment_method_field');

        paymentMethodField.val(paymentMethod);
        paymentMethodField.trigger('select2:select');

        _this.setSaleInvoiceTypeField(controllerContainer);

        //Seteo descuento de cliente
        saleDiscountField.val(discountRate);

        //Seteo impuesto de cliente
        saleTaxPercField.val(taxPerc);


        //Refresh all prices
        $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
          let saleItem = $(this);
          _this.updateSaleItemData(controllerContainer, saleItem);
        });
        _this.updateSaleTotals(controllerContainer);
      });   
      $.ajaxSetup({async: true});   
    }
    else{
      productsModalButton.hide();
    }
  }

  setSaleInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let saleClientTaxProfileValue = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleInvoiceTypeField = $(controllerContainer).find('.js-sale_invoice_type_field');
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let saleExternalInvoicedFalse = $(controllerContainer).find('.js-sale_external_invoiced_false');
    let saleExternalInvoicedTrue = $(controllerContainer).find('.js-sale_external_invoiced_true');  
    let salePointDemantecaActiveValue = $(controllerContainer).find('.js-sale_point_demanteca_active_field').val();

    _this.updateSaleInvoiceTypeField(controllerContainer);


    if(saleSaleChannelValue == 'channel_b'){

      _this.setExternalInvoiced(controllerContainer, saleExternalInvoicedFalse);
      saleInvoiceTypeField.val('credit_note_other');
    }
    else if (saleSaleChannelValue == 'channel_a'){

      if(salePointDemantecaActiveValue == 'true'){
        _this.setExternalInvoiced(controllerContainer, saleExternalInvoicedTrue);
      } else {
        _this.setExternalInvoiced(controllerContainer, saleExternalInvoicedFalse);
      }

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.val('credit_note_c');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.val('credit_note_c');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.val('credit_note_c');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.val('credit_note_c');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.val('credit_note_a');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.val('credit_note_b');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.val('credit_note_b');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.val('credit_note_b');
        }
      }
    }
    saleInvoiceTypeField.trigger('select2:select');
  }

  updateSaleInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let saleClientTaxProfileValue = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleInvoiceTypeField = $(controllerContainer).find('.js-sale_invoice_type_field');
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let inputGroupPrepend = $(controllerContainer).find('.js-sale_external_invoiced_container').closest('.input-group-prepend');
    let salePointDemantecaActiveValue = $(controllerContainer).find('.js-sale_point_demanteca_active_field').val();

    if(saleSaleChannelValue == 'channel_b'){ 
      saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
      saleInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
      saleInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
      saleInvoiceTypeField.find("option[value='credit_note_other']").removeAttr('disabled');
      inputGroupPrepend.hide();
    }
    else if (saleSaleChannelValue == 'channel_a'){
      saleInvoiceTypeField.find("option[value='credit_note_other']").attr('disabled','disabled');
      if(salePointDemantecaActiveValue == 'true'){
        inputGroupPrepend.show();
      } else {
        inputGroupPrepend.hide();
      }

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='credit_note_b']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
      }
    }

    saleInvoiceTypeField.trigger('select2:select');
  }


  populateSaleItem(controllerContainer, saleItem){
    let _this = this;
    let saleItemProductIdData = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').select2('data')[0];
    let saleItemTaxPercValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_perc_field').val();
    let saleItemTaxValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_field').val();
    let saleItemSubtotalValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_subtotal_field').val();
    let saleItemPriceValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_field').val();
    let saleItemOriginalPriceValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_original_price_field').val();
    let saleItemAmountValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_amount_field').val();
    let saleItemDiscountValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_value_field').val();
    let saleItemDiscountKind = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_field').val();
    let saleItemDiscountSymbol = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_container').html();
    let saleItemTotalValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_total_field').val();
    let saleItemPromoText = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_promo_text').html();
    let saleItemSymbol = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_symbol_container').html();

    saleItemPriceValue = parseFloat(saleItemPriceValue);
    saleItemPriceValue = (isNaN(saleItemPriceValue)) ? 0.0 : saleItemPriceValue;

    saleItemOriginalPriceValue = parseFloat(saleItemOriginalPriceValue);
    saleItemOriginalPriceValue = (isNaN(saleItemOriginalPriceValue)) ? 0.0 : saleItemOriginalPriceValue;

    saleItemAmountValue = parseFloat(saleItemAmountValue);
    saleItemAmountValue = (isNaN(saleItemAmountValue)) ? 0.0 : saleItemAmountValue;

    saleItemDiscountValue = parseFloat(saleItemDiscountValue);
    saleItemDiscountValue = (isNaN(saleItemDiscountValue)) ? 0.0 : saleItemDiscountValue;

    saleItem.find('.js-sale_item_product_id').append('<option selected="selected" value="'+saleItemProductIdData.id+'">'+saleItemProductIdData.text+'<option>').trigger('change.select2');
    saleItem.find('.js-sale_item_tax_perc_field').val(saleItemTaxPercValue);
    saleItem.find('.js-sale_item_tax_field').val(saleItemTaxValue);
    saleItem.find('.js-sale_item_subtotal_field').val(saleItemSubtotalValue);
    saleItem.find('.js-sale_item_price_field').val(saleItemPriceValue);
    saleItem.find('.js-sale_item_original_price_field').val(saleItemOriginalPriceValue);
    saleItem.find('.js-sale_item_amount_field').val(saleItemAmountValue);
    saleItem.find('.js-sale_item_discount_value_field').val(saleItemDiscountValue);
    saleItem.find('.js-sale_item_discount_kind_field').val(saleItemDiscountKind);
    saleItem.find('.js-sale_item_discount_kind_container').html(saleItemDiscountSymbol);
    saleItem.find('.js-sale_item_total_field').val(saleItemTotalValue);
    saleItem.find('.js-sale_item_promo_text').html(saleItemPromoText);
    saleItem.find('.js-sale_item_symbol_container').html(saleItemSymbol);

    _this.cleanNestedSaleItem(controllerContainer);

    let timeToRefreshSaleItem = 0;
    clearTimeout(timeToRefreshSaleItem);
    timeToRefreshSaleItem = setTimeout(function () {
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSaleTotals(controllerContainer);
    }, 200);

    // Focus on product select
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').select2('focus');
  }

  cleanNestedSaleItem(controllerContainer){
    let baseDiscountSymbol = "<i class='fas fa-dollar-sign'></i>";
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').val('').trigger('change.select2');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_perc_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_subtotal_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_original_price_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_amount_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_value_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_field').val('discount_kind_fix');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_container').html(baseDiscountSymbol);
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_total_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_promo_text').html('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_symbol_container').html('');
    
    // Remove error alert from list
    $(controllerContainer).find('#section-two .alert.alert-danger').remove();
  }

  cleanSaleItem(controllerContainer, saleItem){
    let baseDiscountSymbol = "<i class='fas fa-dollar-sign'></i>";
    $(saleItem).find('.js-sale_item_product_id').val('').trigger('change.select2');
    $(saleItem).find('.js-sale_item_tax_perc_field').val('');
    $(saleItem).find('.js-sale_item_tax_field').val('');
    $(saleItem).find('.js-sale_item_subtotal_field').val('');
    $(saleItem).find('.js-sale_item_price_field').val('');
    $(saleItem).find('.js-sale_item_original_price_field').val('');
    $(saleItem).find('.js-sale_item_amount_field').val('');
    $(saleItem).find('.js-sale_item_discount_value_field').val('');
    $(saleItem).find('.js-sale_item_discount_kind_field').val('discount_kind_fix');
    $(saleItem).find('.js-sale_item_discount_kind_container').html(baseDiscountSymbol);
    $(saleItem).find('.js-sale_item_total_field').val('');
    $(saleItem).find('.js-sale_item_promo_text').html('');
  }

  bindSaleItem(controllerContainer, saleItem){
    let _this = this;
    let saleItemProductIdField = saleItem.find('.js-sale_item_product_id');
    let saleItemPriceField = saleItem.find('.js-sale_item_price_field');
    let saleItemAmountField = saleItem.find('.js-sale_item_amount_field');
    let saleItemDiscountValueField = saleItem.find('.js-sale_item_discount_value_field');
    let saleItemDiscountKindDropdownOption = saleItem.find('.js-sale_item_discount_kind_dropdown_option');
    
    $(saleItemProductIdField).on('select2:select', function() {
      _this.updateSaleItemData(controllerContainer, saleItem);
    });

    $(saleItemProductIdField).on('select2:unselect', function() {
      _this.updateSaleItemData(controllerContainer, saleItem);
    });


    let timeToRefreshSaleItem = 0;
    $(saleItemPriceField).on('input', function() {
      clearTimeout(timeToRefreshSaleItem);
      timeToRefreshSaleItem = setTimeout(function() {
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSaleTotals(controllerContainer);        
      }, 200);
    });

    $(saleItemAmountField).on('input', function() {
      clearTimeout(timeToRefreshSaleItem);
      timeToRefreshSaleItem = setTimeout(function() {
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSaleTotals(controllerContainer);        
      }, 200);
    });

    $(saleItemDiscountValueField).on('input', function() {
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSaleTotals(controllerContainer);
    });

    $(saleItemDiscountKindDropdownOption).on('click', function(event) {
      event.preventDefault();
      _this.updateSaleItemDiscountKind(this, saleItem);
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSaleTotals(controllerContainer);
    });
  }

  updateSaleItemDiscountKind(option, saleItem){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let saleItemDiscountKindField = saleItem.find('.js-sale_item_discount_kind_field');
    let saleItemDiscountKindContainer = saleItem.find('.js-sale_item_discount_kind_container');

    saleItemDiscountKindField.val(value);
    saleItemDiscountKindContainer.html(icon);
  }

  updateSaleItemData(controllerContainer, saleItem){
    let _this = this;
    let clientIdValue = $(controllerContainer).find('.js-client_id_field').select2('val');
    let saleItemSymbolContainer = saleItem.find('.js-sale_item_symbol_container');
    let saleItemPriceField = saleItem.find('.js-sale_item_price_field');
    let saleItemOriginalPriceField = saleItem.find('.js-sale_item_original_price_field');
    let saleItemTaxPercField = saleItem.find('.js-sale_item_tax_perc_field');
    let saleItemPriceKindField = saleItem.find('.js-sale_item_price_kind_field');
    let saleItemPriceKindContainer = saleItem.find('.js-sale_item_price_kind_container');
    let productIdField = saleItem.find('.js-sale_item_product_id');
    let productAmountField = saleItem.find('.js-sale_item_amount_field');

    let saleItemRemoveFieldsButton = saleItem.find('.remove_fields');
    let productIdValue = productIdField.select2('val');
    let productAmountValue = productAmountField.val();
    let url = $(productIdField).data('url-two');
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let saleClientTaxProfileField = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let saleInvoiceTypeValue = $(controllerContainer).find('.js-sale_invoice_type_field').val();

    if(productIdValue != ''){
      url = url.replace('/the_id/show_for_sale', '/'+productIdValue+'/show_for_sale?client_id='+clientIdValue+'&amount='+productAmountValue);

      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let price = data.price;
        if(price == undefined){
            if (saleItemRemoveFieldsButton.length != 0){
              saleItemRemoveFieldsButton.trigger('click');
            }
            else{
              _this.cleanNestedSaleItem(controllerContainer);
            }
        }
        else{
          let symbol = data.measurement_category_symbol;
          let price = data.price;
          let price_kind = data.price_kind;
          let taxPerc = data.tax_perc;

          saleItemSymbolContainer.html(symbol);
          saleItemSymbolContainer.fadeIn();
          
          price = parseFloat(price);
          price = (isNaN(price)) ? 0.0 : price;


          if (
            (
              (price_kind == 'price_kind_fix') &&
              (salePointTaxProfileValue == 'enroled_responsable') &&
              (saleClientTaxProfileField == 'enroled_responsable') &&
              (saleSaleChannelValue == 'channel_a')
            ) ||
            (
              (price_kind == 'price_kind_fix') &&
              (salePointTaxProfileValue == 'enroled_responsable') &&
              (saleClientTaxProfileField == 'monotax_responsable') &&
              (saleInvoiceTypeValue == 'credit_note_a') &&
              (saleSaleChannelValue == 'channel_a')
            )
          ){
              
            var tax_perc_value = parseFloat(taxPerc);
            tax_perc_value = (isNaN(tax_perc_value)) ? 0.0 : tax_perc_value;
            
            let price_net = price / (1 + (tax_perc_value / 100.0))
            price_net = _this.round(price_net);

            price = _this.round(price);

            saleItemPriceField.val(price_net);
            saleItemOriginalPriceField.val(price);
          }
          else{
            saleItemPriceField.val(price);
            saleItemOriginalPriceField.val(price);
          }


          saleItemPriceKindField.val(price_kind);
          if(price_kind == 'price_kind_fix'){
            saleItemPriceKindContainer.html("<i class='fas fa-dollar-sign'></i>");
          }
          else {
            saleItemPriceKindContainer.html("<i class='fas fa-percent'></i>");
          }

          saleItemTaxPercField.val(taxPerc);
        }
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSaleTotals(controllerContainer);
      });
      $.ajaxSetup({async: true});
    }
    else{
      if (saleItemRemoveFieldsButton.length != 0){
        _this.cleanSaleItem(controllerContainer, saleItem);
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSaleTotals(controllerContainer);
      }
      else{
        _this.cleanNestedSaleItem(controllerContainer);
      }
    }
  }

  updateSaleItemTotals(controllerContainer, saleItem){
    let _this = this;
    
    RefundCalculation.calculateSaleItem(_this, controllerContainer, saleItem);
  }


  updateSalePaymentAmountReadonly(controllerContainer){
    if ($('#sale_payments_container .nested-fields:visible').length == 1){
      let container = $('#sale_payments_container .nested-fields:visible');
      let payment_method_amount_field = $(container).find('.js-payment_method_amount_field');
      payment_method_amount_field.attr('readonly','readonly');
    }
    else {
      let payment_method_amount_field = $(controllerContainer).find('.js-payment_method_amount_field');
      payment_method_amount_field.removeAttr('readonly');
    }
  }

  updateSaleTotals(controllerContainer){
    let _this = this;

    RefundCalculation.calculateTotal(_this, controllerContainer);
  }
}
