
import { Controller } from 'stimulus';
import { FlashMessages } from '../../lib/flash-messages.js';


export default class extends Controller {
  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect FlashMessage controller");

      FlashMessages.message(this.parseFlashMessage());
    }
  }

  parseFlashMessage(){
    return $.parseJSON(this.data.get("flash"));
  }
}
