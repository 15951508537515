
import  ApplicationController from "../application_controller.js"
import { DOMManager } from '../../lib/dom-manager.js';
import { Modals } from '../../lib/modals.js';
import { FlashMessages } from '../../lib/flash-messages.js';
import { Invoice } from '../../lib/invoice.js';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect Refund controller");
    }
  }



  // Before form submit
  beforeSendForm(event){
    const [data, settings] = event.detail;
    let _this = this;    
    if(!$(event.target).is('#js-add-button')){
      let ajax = $(event.target).is('#sale_form');
      Invoice.sale_modal(event, settings, ajax);
    }
  }


  // Success state element
  stateSuccess(event) {
    const [data, status, xhr] = event.detail;
    const targetDomId = event.target.dataset.domId;
    DOMManager.removeRow(targetDomId, '#FFFAAA');
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

  // Fail state element
  stateError(event) {
    const [data, status, xhr] = event.detail;

    event.stopPropagation();
    if (xhr.responseText != ''){
      Modals.modalShow();
      Modals.modalSize('modal-lg');
      Modals.modalFill(xhr.responseText);
    }
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

}
