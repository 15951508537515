
import  ApplicationController from "../application_controller.js"
import { FlashMessages } from '../../lib/flash-messages.js';
import { Modals } from '../../lib/modals.js';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SupplierPayment controller");

      let _this = this;
      let controllerContainer = _this.context.element;
    }
  }

  // Success form submit
  formSuccess(event){
    const [data, status, xhr] = event.detail;
    let _this = this;
    let controllerContainer = this.context.element;

    Modals.modalReset();
    Modals.modalHide();
    _this.addOrRefreshElement(xhr);
    _this.refreshAccountBalance(controllerContainer);
    FlashMessages.message(_this.parseFlashMessage(xhr));
  }

  refreshAccountBalance(controllerContainer){
    let _this = this;
    let accountBalanceContainer = $(controllerContainer).find('#js-account_balance_container');
    
    if (accountBalanceContainer.length != 0){
      let refreshUrl = accountBalanceContainer.data('url');
      $.getJSON(refreshUrl, function(data) {
        let balance = data.balance;
        balance = parseFloat(balance);
        balance = (isNaN(balance)) ? 0.0 : balance;

        accountBalanceContainer.html(_this.formatToCurrency(balance));
      });
    }
  }

}
