
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect BankPromoForm controller")

      let _this = this;
      let controllerContainer = this.context.element;


      _this.updateKindCreditCardFields(controllerContainer);
      _this.updateKindDebitCardFields(controllerContainer);
    }
  }


  changeKindCreditCard(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateKindCreditCardFields(controllerContainer);
  }

  changeKindDebitCard(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateKindDebitCardFields(controllerContainer);
  }
  
  private



  updateKindCreditCardFields(controllerContainer){
    let _this = this
    let bank_promo_kind_credit_card = $(controllerContainer).find('#bank_promo_kind_credit_card');    
    let kind_credit_card_data_container = $(controllerContainer).find('.js-kind_credit_card_data_container');

    if((bank_promo_kind_credit_card.is(':checked')) || (bank_promo_kind_credit_card.val() == 'true')){
      kind_credit_card_data_container.fadeIn();
    } else {
      kind_credit_card_data_container.hide();
    }
  }

  updateKindDebitCardFields(controllerContainer){
    let _this = this
    let bank_promo_kind_debit_card = $(controllerContainer).find('#bank_promo_kind_debit_card');    
    let kind_debit_card_data_container = $(controllerContainer).find('.js-kind_debit_card_data_container');

    if((bank_promo_kind_debit_card.is(':checked')) || (bank_promo_kind_debit_card.val() == 'true')){
      kind_debit_card_data_container.fadeIn();
    } else {
      kind_debit_card_data_container.hide();
    }
  }

}
