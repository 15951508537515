
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect StockTransferForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;

      $(controllerContainer).on('cocoon:after-insert', '#stock_transfer_items_container', function(e, insertedItem, originalEvent) {
        let stockTransferItem = $(insertedItem).closest('.nested-fields');
        _this.bindStockTransferItem(controllerContainer, stockTransferItem);
        setTimeout(function(){ 
          _this.populateStockTransferItem(controllerContainer, stockTransferItem);
          _this.updateStockTransferItemData(controllerContainer, stockTransferItem);
        }, 200);
      });

      $(controllerContainer).on('cocoon:before-insert', '#stock_transfer_items_container', function(e, insertedItem, originalEvent) {
        

        let productIdField = $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_id');
        let productAmountField = $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_amount_field');
        let productIdValue = productIdField.select2('val');
        let addedProductIds = $("#stock_transfer_items_container .js-stock_transfer_item_product_id:visible").map(function() { return $(this).val(); }).get()

        productIdField.removeClass('is-invalid');
        productAmountField.removeClass('is-invalid');

        if (productIdValue == ''){
          productIdField.addClass('is-invalid');
          productIdField.select2('focus');
          e.preventDefault();
        }
        if (productAmountField.val() == ''){
          productAmountField.addClass('is-invalid');
          productAmountField.trigger('focus');
          e.preventDefault();
        }

        if (jQuery.inArray(productIdValue, addedProductIds) !== -1){
          let input = $('#stock_transfer_items_container .js-stock_transfer_item_product_id option[value="'+productIdValue+'"]:selected')
          let container = input.closest('.nested-fields');
          let stockTransferItemAmountField = container.find('.js-stock_transfer_item_product_amount_field');
          let stockTransferItemAmountValue = parseFloat(stockTransferItemAmountField.val());

          stockTransferItemAmountValue = (isNaN(stockTransferItemAmountValue)) ? 0.0 : stockTransferItemAmountValue;

          let productAmountFieldValue = parseFloat(productAmountField.val());
          productAmountFieldValue = (isNaN(productAmountFieldValue)) ? 0.0 : productAmountFieldValue;

          stockTransferItemAmountField.val((stockTransferItemAmountValue+productAmountFieldValue));
          stockTransferItemAmountField.trigger('input');
          _this.cleanNestedStockTransferItem(controllerContainer, insertedItem);
          e.preventDefault();
        }



      });

      $('#stock_transfer_items_container .nested-fields, #nested_stock_transfer_item_product_data').each(function(){
        _this.bindStockTransferItem(controllerContainer, $(this));
      });

      $(controllerContainer).on('keydown', function(e){
        if(e.keyCode == 13) {
          if ($('.js-stock_transfer_item_product_amount_field').is(':focus')){ 
            $('#js-add_stock_transfer_item_product').trigger('click');
            e.preventDefault();
          }
        }
      });
    }
  }


  // Triggerado por el click para ver observaciones
  toggleOpenObservation(){
    let _this = this;
    let controllerContainer = _this.context.element;

    let cardBody = $(controllerContainer).find('.card-body');
    cardBody.toggleClass('open-observation');
  }

  private


  populateStockTransferItem(controllerContainer, stockTransferItem){
    let _this = this;

    let stockTransferItemProductIdData = $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_id').select2('data')[0];
    let stockTransferItemProductValue = $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_amount_field').val();


    stockTransferItemProductValue = parseFloat(stockTransferItemProductValue);
    stockTransferItemProductValue = (isNaN(stockTransferItemProductValue)) ? 0.0 : stockTransferItemProductValue;

    stockTransferItem.find('.js-stock_transfer_item_product_id').append('<option selected="selected" value="'+stockTransferItemProductIdData.id+'">'+stockTransferItemProductIdData.text+'<option>').trigger('select2:select');
    stockTransferItem.find('.js-stock_transfer_item_product_amount_field').val(stockTransferItemProductValue);  

    _this.cleanNestedStockTransferItem(controllerContainer, stockTransferItem);

    // Focus on product select
    $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_id').select2('focus');

  }

  cleanNestedStockTransferItem(controllerContainer, stockTransferItem){
    $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_id').val('').trigger('select2:select');
    $(controllerContainer).find('#nested_stock_transfer_item_product_data .js-stock_transfer_item_product_amount_field').val('');

    // Remove error alert from list
    $(controllerContainer).find('#section-two .alert.alert-danger').remove();
  }

  bindStockTransferItem(controllerContainer, stockTransferItem){
    let _this = this;
    let stockTransferItemProductIdField = stockTransferItem.find('.js-stock_transfer_item_product_id');
    
    $(stockTransferItemProductIdField).on('select2:select', function() {
      _this.updateStockTransferItemData(controllerContainer, stockTransferItem);
    });
  }

  updateStockTransferItemData(controllerContainer, stockTransferItem){
    let _this = this;
    
    let stockTransferItemSymbolContainer = stockTransferItem.find('.js-stock_transfer_item_symbol_container');
    
    
    let productIdField = stockTransferItem.find('.js-stock_transfer_item_product_id');
    if(productIdField.length != 0){
      let productIdValue = productIdField.select2('val');
      let url = $(productIdField).data('url-two');
      
      if(productIdValue != ''){
        url = url.replace('/the_id', '/'+productIdValue);
        $.getJSON(url, function(data) {
          let symbol = data.measurement_category_symbol;
          stockTransferItemSymbolContainer.html(symbol);
          stockTransferItemSymbolContainer.fadeIn();
        });      
      }
    }
  }
}
