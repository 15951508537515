
import  ApplicationController from "../application_controller.js"


export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect LedgerAccount controller");
    }
  }

}
