import { FloorPlanObject } from "./floor-plan-object.js";
import { FloorPlanObjectTable } from "./floor-plan-object-table.js";
import { FloorPlanObjectWall } from "./floor-plan-object-wall.js";

export class FloorPlanLayout {
  constructor(containerId, aspectRatio, mode) {
    let _this = this;
    _this.container = $(containerId);
    _this.aspectRatio = aspectRatio;
    _this.mode = mode;

    _this.currentShape = null;

    _this.createStage();
    _this.createLayer();
    _this.createTransformer();
    
    
    _this.loadObjects();
    _this.bindLayerEvents();
    _this.bindStageEvents();
  }
    
  createStage () {
    let _this = this;

    let containerWidth = 1000;
    let aspectRatioArray = _this.aspectRatio.split(':');

    let widthParts = parseFloat(aspectRatioArray[0]);
    let heightParts = parseFloat(aspectRatioArray[1]);

    let singlePart = containerWidth / widthParts;
    let containerHeight = singlePart * heightParts;

    _this.initialWidth = containerWidth;
    _this.initialHeight = containerHeight;

    _this.stage = new Konva.Stage({
      container: 'canvas-container',
      width: containerWidth,
      height: containerHeight
    });
  }

  createLayer () {
    let _this = this;

    _this.layer = new Konva.Layer({
      name: 'Layer1'
    });

    _this.stage.add(_this.layer);
  }

  createTransformer () {
    let _this = this;

    _this.tr = new Konva.Transformer({
      anchorSize: 10,
      borderDash: [3, 3],
      padding: 4,
      keepRatio: false,
      ignoreStroke: true,
      rotateEnabled: false
    });
    _this.layer.add(_this.tr);
  }

  loadObjects () {
    let _this = this;

    let dataJson = _this.loadDataJson();
    if(
        (dataJson != undefined) && 
        (dataJson != null) && 
        (dataJson != '')
      ){
      let layerLoaded = Konva.Node.create(dataJson)
      layerLoaded.getChildren().forEach(group => {
        let floorPlanObject;
        switch (group.name()) {
          case 'FloorPlanObjectTable':
            floorPlanObject = new FloorPlanObjectTable(_this, group.toJSON());
            break;
          case 'FloorPlanObjectWall':
            floorPlanObject = new FloorPlanObjectWall(_this, group.toJSON());
            break;
        }
      });
    }
    _this.tr.moveToTop();
  }

  bindLayerEvents() {
    let _this = this;

    //----------------------------- EDIT MODE    
    if(_this.mode == 'edit'){
      $('#label-textarea').on('keydown', function (e) {
        _this.saveLabel(e);
      });

      $('#id-textarea').on('keydown', function (e) {
        _this.saveId(e);
      });

      $('#text-button-label').on('click', function () {
        _this.closeMenuNodes();
        _this.assignLabelEditMode();
      });
      
      $('#text-button-id').on('click', function () {
        _this.closeMenuNodes();
        _this.assignIdEditMode();
      });
      
      $('#clone-button').on('click', function () {
        _this.closeMenuNodes();
        _this.cloneSelection();
        _this.tr.moveToTop();
      });

      $('#drag-button').on('click', function () {
        _this.closeMenuNodes();
        _this.assignDragSelection();
      });

      $('#delete-button').on('click', function () {
        _this.currentShape.destroy();
        _this.closeMenuNodes();
        _this.saveDataJson();
      });

      $('#add-table-button').on('click', function () {
        let floorPlanObject = new FloorPlanObjectTable(_this);
        let x =_this.stage.getPointerPosition().x / _this.stage.scaleX();
        let y =_this.stage.getPointerPosition().y / _this.stage.scaleY();

        floorPlanObject.group.setAttrs({x: x, y: y});
        _this.closeMenuNodes();
        _this.saveDataJson();
        
        _this.currentShape = floorPlanObject.group;
        _this.assignDragSelection();
        _this.tr.moveToTop();
      });

      $('#add-wall-button').on('click', function () {
        let floorPlanObject = new FloorPlanObjectWall(_this);
        let x =_this.stage.getPointerPosition().x / _this.stage.scaleX();
        let y =_this.stage.getPointerPosition().y / _this.stage.scaleY();

        floorPlanObject.group.setAttrs({x: x, y: y});
        _this.closeMenuNodes();
        _this.saveDataJson();
        
        _this.currentShape = floorPlanObject.group;
        _this.assignDragSelection();
        _this.tr.moveToTop();
      });


      _this.stage.on('pointerdown', function (e) {
        if (e.target === _this.stage) {
          _this.clearDragSelection();
          _this.closeMenuNodes();
        }
      });

      _this.stage.on('pointerdblclick', function (e) {
        if (e.target === _this.stage) {
          _this.clearDragSelection();
          _this.openMenuNodeCollection();
        }
      });
    }

    //----------------------------- NORMAL MODE
    if(_this.mode == 'normal'){

    }
  }

  bindStageEvents() {
    let _this = this;

    $(window).off('resize');
    $(window).on('resize', function() {
      _this.scaleStage();
    });
    _this.scaleStage();
  }


  


  //-------------------------------------------------------- private

  changeColor(num, color){
    let _this = this;

    _this.stage.find('.Id').forEach(node => {
      if (node.getAttr('text') == '#'+num){
        let group = node.getParent();
        let shape = group.findOne('.Shape');
        shape.fill(color);
      }
    })
  }

  changeAspectRatio(aspectRatio){
    let _this = this;

    _this.aspectRatio = aspectRatio;

    let containerWidth = 1000;
    let aspectRatioArray = _this.aspectRatio.split(':');

    let widthParts = parseFloat(aspectRatioArray[0]);
    let heightParts = parseFloat(aspectRatioArray[1]);

    let singlePart = containerWidth / widthParts;
    let containerHeight = singlePart * heightParts;

    _this.initialWidth = containerWidth;
    _this.initialHeight = containerHeight;

    _this.scaleStage();
  }


  assignLabelEditMode(){
    let _this = this;

    let textNode = _this.currentShape.findOne('.Label');
    let textarea = $('#label-textarea');

    let textarea_val = textNode.text();
    if (textarea_val == 'Name'){
      textarea_val = '';
    }
    textarea.val(textarea_val);
    
    _this.openLabelNodeMember();
    textarea[0].focus();
  }

  assignIdEditMode(){
    let _this = this;

    let textNode = _this.currentShape.findOne('.Id');
    let textarea = $('#id-textarea');

    let textarea_val = textNode.text().substring(1);
    if (textarea_val == 'Num'){
      textarea_val = '';
    }
    textarea.val(textarea_val);
    
    _this.openIdNodeMember();
    textarea[0].focus();
  }

  saveLabel(e){
    let _this = this;
    let textNode = _this.currentShape.findOne('.Label');
    let textarea = $('#label-textarea');

    if (e.keyCode === 13) {
      let textarea_val = textarea.val();
      if (textarea_val == ''){
        textarea_val = 'Name';
      }
      textNode.text(textarea_val);
      _this.closeMenuNodes();
      _this.saveDataJson();
    }
  }

  saveId(e){
    let _this = this;
    let textNode = _this.currentShape.findOne('.Id');
    let textarea = $('#id-textarea');

    if (e.keyCode === 13) {
      let textarea_val = textarea.val();
      if (textarea_val == ''){
        textarea_val = 'Num';
      }
      textNode.text('#'+textarea.val());
      _this.closeMenuNodes();
      _this.saveDataJson();
    }
  }

  clearDragSelection(){
    let _this = this;

    _this.tr.nodes().forEach(node => {
      switch (node.name()) {
        case 'FloorPlanObjectTable':
          node.draggable(false);
          break;
        case 'FloorPlanObjectWall':
          node.draggable(false);
          break;
      }
    });
    _this.tr.nodes([]);
    _this.saveDataJson();
  }


  assignDragSelection(){
    let _this = this;
    _this.currentShape.draggable(true);
    _this.tr.nodes([_this.currentShape]);
    _this.saveDataJson();
  }

  cloneSelection(){
    let _this = this;
    let clonedNode;
    switch (_this.currentShape.name()) {
      case 'FloorPlanObjectTable':
        clonedNode = new FloorPlanObjectTable(_this, _this.currentShape.toJSON());
        break;
      case 'FloorPlanObjectWall':
        clonedNode = new FloorPlanObjectWall(_this, _this.currentShape.toJSON());
        break;
    }
    _this.layer.add(clonedNode.group);
    clonedNode.group.draggable(true);
    _this.tr.nodes([clonedNode.group]);
    _this.saveDataJson();
  }

  closeMenuNodes(){
    let _this = this;

    let menuNodeCollection = $('#canvas-menu-collection');
    let menuNodeMember = $('#canvas-menu-member');
    let labelNodeMember = $('#canvas-label-member');
    let idNodeMember = $('#canvas-id-member');

    menuNodeCollection.css('display', 'none');
    menuNodeMember.css('display', 'none');
    labelNodeMember.css('display', 'none');
    idNodeMember.css('display', 'none');
  }

  openLabelNodeMember(){
    let _this = this;

    let menuNodeCollection = $('#canvas-menu-collection');
    let menuNodeMember = $('#canvas-menu-member');
    let labelNodeMember = $('#canvas-label-member');
    let idNodeMember = $('#canvas-id-member');

    menuNodeCollection.css('display', 'none');
    menuNodeMember.css('display', 'none');
    idNodeMember.css('display', 'none');
    labelNodeMember.css('display', 'initial');
    labelNodeMember.css('top', _this.stage.getPointerPosition().y + 'px');
    labelNodeMember.css('left',_this.stage.getPointerPosition().x + 'px');
  }

  openIdNodeMember(){
    let _this = this;

    let menuNodeCollection = $('#canvas-menu-collection');
    let menuNodeMember = $('#canvas-menu-member');
    let labelNodeMember = $('#canvas-label-member');
    let idNodeMember = $('#canvas-id-member');

    menuNodeCollection.css('display', 'none');
    menuNodeMember.css('display', 'none');
    labelNodeMember.css('display', 'none');
    idNodeMember.css('display', 'initial');
    idNodeMember.css('top', _this.stage.getPointerPosition().y + 'px');
    idNodeMember.css('left',_this.stage.getPointerPosition().x + 'px');
  }

  openMenuNodeMember(kind){
    let _this = this;

    let menuNodeCollection = $('#canvas-menu-collection');
    let menuNodeMember = $('#canvas-menu-member');
    let labelNodeMember = $('#canvas-label-member');
    let idNodeMember = $('#canvas-id-member');
    let text_button_id = $('#text-button-id');
    let text_button_label = $('#text-button-label');
    
    if(kind == 'table'){
      text_button_id.show();
      text_button_label.show();
    }
    else{
      text_button_id.hide();
      text_button_label.hide();
    }

    menuNodeCollection.css('display', 'none');
    labelNodeMember.css('display', 'none');
    idNodeMember.css('display', 'none');
    menuNodeMember.css('display', 'initial');
    menuNodeMember.css('top', _this.stage.getPointerPosition().y + 'px');
    menuNodeMember.css('left',_this.stage.getPointerPosition().x + 'px');
  }
  
  openMenuNodeCollection(){
    let _this = this;

    let menuNodeCollection = $('#canvas-menu-collection');
    let menuNodeMember = $('#canvas-menu-member');
    let labelNodeMember = $('#canvas-label-member');
    let idNodeMember = $('#canvas-id-member');

    menuNodeMember.css('display', 'none');
    labelNodeMember.css('display', 'none');
    idNodeMember.css('display', 'none');
    menuNodeCollection.css('display', 'initial');    
    menuNodeCollection.css('top', _this.stage.getPointerPosition().y + 'px');
    menuNodeCollection.css('left', _this.stage.getPointerPosition().x + 'px');   
  }

  scaleStage() {
    let _this = this;

    let containerWidth = _this.container.outerWidth();
    let containerInitialWidth = _this.initialWidth;
    let containerInitialHeight = _this.initialHeight;

    var scale = containerWidth / containerInitialWidth;
  
    _this.stage.width(containerInitialWidth * scale);
    _this.stage.height(containerInitialHeight * scale);
    _this.stage.scale({ x: scale, y: scale });
  }

  loadDataJson(){
    return $('#floor_plan_data').html();
  }

  saveDataJson(){
    $('#floor_plan_data').html(this.layer.toJSON());
  }
}