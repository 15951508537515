import ApplicationController from "../application_controller.js";

let time = 0;

export default class extends ApplicationController {
  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect Cashbox controller");
    }
    $("#filter_query").focus();
  }

  handleFilterInput(event) {
    let target = $(event.currentTarget);
    let form = target.closest("form");

    clearTimeout(time);
    time = setTimeout(function () {
      form.find("button").trigger("click");
    }, 500);
  }
}
