import  ApplicationController from "../application_controller.js"
import { SaleCalculation } from "../../lib/sales_form/sale_calculation.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SaleForm controller");

      //debug
      //$('.js-sale_item_tax_perc_field').attr('type', 'text');
      //$('.js-sale_item_tax_field').attr('type', 'text');
      //$('.js-sale_item_original_price_field').attr('type', 'text');

      let _this = this;
      let controllerContainer = _this.context.element;


      let time_to_print = 0;
      clearTimeout(time_to_print);
      time_to_print = setTimeout(function() {
        let open_comanda = ($('#js-printjs-comanda-open_modal').length != 0);
        let open_invoice = (($('#js-printjs-invoice-open_modal').length != 0) && ($('#js-printjs-comanda-open_modal').length == 0));
        
        if(open_comanda) {
          $('#js-printjs-comanda-open_modal').trigger('click');
          $('#js-printjs-comanda-open_modal').attr('id', 'js-printjs-comanda');
        }

        if(open_invoice) {
          $('#js-printjs-invoice-open_modal').trigger('click');
          $('#js-printjs-invoice-open_modal').attr('id', 'js-printjs-invoice');
        }
      }, 500);
      

      $(controllerContainer).on('cocoon:after-insert', '#sale_items_container', function(e, insertedItem, originalEvent) {
        let saleItem = $(insertedItem).closest('.nested-fields');
        _this.bindSaleItem(controllerContainer, saleItem);
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
        _this.checkSaleItemEmpty(controllerContainer);
      });

      $(controllerContainer).on('cocoon:before-insert', '#sale_items_container', function(e, insertedItem, originalEvent) {
        let productIdField = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id');
        let amountField = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_amount_field');
        let productIdValue = productIdField.select2('val');
        let addedProductIds = $("#sale_items_container .js-sale_item_product_id:visible").map(function() { return $(this).val(); }).get()

        productIdField.removeClass('is-invalid');
        amountField.removeClass('is-invalid');

        if (productIdValue == ''){
          productIdField.addClass('is-invalid');
          productIdField.select2('focus');
          e.preventDefault();
          return;
        }
        if (amountField.val() == ''){
          amountField.addClass('is-invalid');
          amountField.trigger('focus');
          e.preventDefault();
          return;
        }

        if (jQuery.inArray(productIdValue, addedProductIds) !== -1){
          let input = $('#sale_items_container .js-sale_item_product_id option[value="'+productIdValue+'"]:selected')
          let container = input.closest('.nested-fields');
          let saleItemAmountField = container.find('.js-sale_item_amount_field');
          
          let saleItemAmountValue = parseFloat(saleItemAmountField.val());
          saleItemAmountValue = (isNaN(saleItemAmountValue)) ? 0.0 : saleItemAmountValue;

          let amountFieldValue = parseFloat(amountField.val());
          amountFieldValue = (isNaN(amountFieldValue)) ? 0.0 : amountFieldValue;

          saleItemAmountField.val((saleItemAmountValue+amountFieldValue));
          saleItemAmountField.trigger('input');
          _this.cleanNestedSaleItem(controllerContainer);
          $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').select2('focus');
          e.preventDefault();
        }
        else{
          _this.populateSaleItem(controllerContainer, insertedItem);
        }

      });

      $('#sale_items_container .nested-fields, #nested_sale_item_data').each(function(){
        _this.bindSaleItem(controllerContainer, $(this));
      });

      $(controllerContainer).on('cocoon:after-remove', '#sale_items_container', function(e, insertedItem, originalEvent) {
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('cocoon:after-insert', '#sale_payments_container', function(e, insertedItem, originalEvent) {
        _this.updateSalePaymentAmountReadonly(controllerContainer);
        _this.updateSalePaymentPaysWithPayChangeReadonly(controllerContainer);
      });

      $(controllerContainer).on('cocoon:after-remove', '#sale_payments_container', function(e, insertedItem, originalEvent) {
        _this.updateSaleTotals(controllerContainer);
        _this.updateSalePaymentPaysWithPayChangeReadonly(controllerContainer);
      });


      $(controllerContainer).on('input', '.js-sale_discount_value_field', function() {
        _this.updateSaleTotals(controllerContainer);
      });

      $(controllerContainer).on('click', '.js-sale_discount_kind_dropdown_option', function(event) {
        event.preventDefault();
        _this.updateSaleDiscountKind(controllerContainer, this);
        _this.updateSaleTotals(controllerContainer);
      });


      _this.updateSaleInvoiceTypeField(controllerContainer);
      _this.updateExternalInvoicedData(controllerContainer);
      _this.updateMultiPayment(controllerContainer);
      _this.updateBookedAmount(controllerContainer);
      _this.updateOnOffSalePromo(controllerContainer);
      _this.updateOnOffPortionInvoice(controllerContainer);
      _this.updateOnOffHaveTaxes(controllerContainer);
      _this.updateHaveTaxes(controllerContainer);

      setTimeout(function() {
        _this.updateSalePromo(controllerContainer);
        _this.updateSalePaymentAmountReadonly(controllerContainer);
        _this.updateSalePaymentPaysWithPayChangeReadonly(controllerContainer);
      }, 200);      
    }
  }

  // Triggerado por el click para order por nombre de producto
  toggleSortProductName(event){
    let wrapper = $('#sale_items_container');
    let sortProductNameASC = $('#sortProductNameASC');
    let sortProductNameDESC = $('#sortProductNameDESC');
    let sortProductBrandASC = $('#sortProductBrandASC');
    let sortProductBrandDESC = $('#sortProductBrandDESC');
    let link = event.currentTarget;

    if(link.dataset.order == 'ASC'){
      wrapper.find('.nested-fields').sort(function (a, b) {
        return b.dataset.productName.toUpperCase().localeCompare(a.dataset.productName.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'DESC'
      sortProductNameASC.hide();
      sortProductBrandASC.hide();
      sortProductBrandDESC.hide();
      sortProductNameDESC.show();
    }
    else{
      wrapper.find('.nested-fields').sort(function (a, b) {
        return a.dataset.productName.toUpperCase().localeCompare(b.dataset.productName.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'ASC'
      sortProductNameDESC.hide();
      sortProductBrandASC.hide();
      sortProductBrandDESC.hide();
      sortProductNameASC.show();
    }
  }

  toggleSortProductBrand(event){
    let wrapper = $('#sale_items_container');
    let sortProductNameASC = $('#sortProductNameASC');
    let sortProductNameDESC = $('#sortProductNameDESC');
    let sortProductBrandASC = $('#sortProductBrandASC');
    let sortProductBrandDESC = $('#sortProductBrandDESC');
    let link = event.currentTarget;

    if(link.dataset.order == 'ASC'){
      wrapper.find('.nested-fields').sort(function (a, b) {
        return b.dataset.productBrand.toUpperCase().localeCompare(a.dataset.productBrand.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'DESC'
      sortProductBrandASC.hide();
      sortProductNameASC.hide();
      sortProductNameDESC.hide();
      sortProductBrandDESC.show();
    }
    else{
      wrapper.find('.nested-fields').sort(function (a, b) {
        return a.dataset.productBrand.toUpperCase().localeCompare(b.dataset.productBrand.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'ASC'
      sortProductBrandDESC.hide();
      sortProductNameASC.hide();
      sortProductNameDESC.hide();      
      sortProductBrandASC.show();      
    }
  }


  // Triggerado por el click para multiples pagos
  toggleMultiPayment(){
    let _this = this;
    let controllerContainer = _this.context.element;    
    _this.setMultiPayment(controllerContainer);
    _this.updateMultiPayment(controllerContainer);
  }


  // Triggerado por el click para reservar stock
  toggleBookedAmount(){
    let _this = this;
    let controllerContainer = _this.context.element;    
    _this.setBookedAmount(controllerContainer);
    _this.updateBookedAmount(controllerContainer);
  }


  // Triggerado por el click para habilitar promos
  toggleSalePromo(){
    let _this = this;
    let controllerContainer = _this.context.element;
    _this.setOnOffSalePromo(controllerContainer);
    _this.updateOnOffSalePromo(controllerContainer);
  }



  // Triggerado por el click para proporcional factura
  togglePortionInvoice(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setOnOffPortionInvoice(controllerContainer);
    _this.updateOnOffPortionInvoice(controllerContainer);
    _this.updateOnOffHaveTaxes(controllerContainer);

    $(controllerContainer).find('.js-sale_have_taxes_field').val('true');
    _this.setHaveTaxes(controllerContainer);
    _this.updateHaveTaxes(controllerContainer);

    _this.updateSaleTotals(controllerContainer);
  }

  // Triggerado por el click para tiene impuestos
  toggleHaveTaxes(){
    let _this = this;
    let controllerContainer = _this.context.element;    
    _this.setHaveTaxes(controllerContainer);
    _this.updateHaveTaxes(controllerContainer);
  }


  // Triggerado por el click para ver observaciones
  toggleOpenObservation(){
    let _this = this;
    let controllerContainer = _this.context.element;

    let cardBody = $(controllerContainer).find('.card-body');
    
    cardBody.toggleClass('open-observation');
  }

  // Triggerado por el cambio de cliente
  changeClient(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateClientData(controllerContainer);
  }


  // Triggerado por el cambio de producto
  changeNestedProductId(){
    let _this = this;
    setTimeout(function () {
      $('#nested_sale_item_data .js-sale_item_amount_field')[0].focus();
    }, 200);
  }


  // Triggerado por el cambio el canal
  changeSaleChannel(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setSaleInvoiceTypeField(controllerContainer);
    _this.updateOnOffHaveTaxes(controllerContainer);

    $(controllerContainer).find('.js-sale_have_taxes_field').val('true');
    _this.setHaveTaxes(controllerContainer);
    _this.updateHaveTaxes(controllerContainer);    

    //Refresh all prices
      $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
        let saleItem = $(this);
        _this.updateSaleItemData(controllerContainer, saleItem);
      });
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer);
  }

  // Triggerado por el tipo de factura
  changeInvoiceType(){
    let _this = this;
    let controllerContainer = _this.context.element;

    //Refresh all prices
      $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
        let saleItem = $(this);
        _this.updateSaleItemData(controllerContainer, saleItem);
      });
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer);
  }

  // Triggerado por el cambio de medio de pago 
  changePaymentMethod(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let select = $(event.currentTarget);
    let container = select.closest('.nested-fields')

    _this.updatePaymentMethodData(controllerContainer, container);
    _this.updateSalePaymentPaysWithPayChangeReadonly(controllerContainer)
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer);
  }

  clickCategoryButton(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let button = $(event.currentTarget);

    _this.loadProducts(controllerContainer, button);
  }

  clickProductButton(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let button = $(event.currentTarget);

    _this.addProduct(controllerContainer, button);
  }


  // Triggerado por el cambio de monto de pago
  changeSaleTaxAmount(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    
    _this.updateSaleTotals(controllerContainer);
  }

  changeExternalInvoiced(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let option = $(event.currentTarget);

    event.preventDefault();
    _this.setExternalInvoiced(controllerContainer, option);
  }

  changePayedCreditNoteId(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let creditNoteField = $(event.currentTarget);

    _this.updateCreditNoteData(controllerContainer, creditNoteField);
  }

  // Triggerado por el cambio de tarjeta
  changeCard(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let select = $(event.currentTarget);
    let container = select.closest('.nested-fields')

    let bankPromoIdField = $(container).find('.js-payment_method_debit_card_credit_card_bank_promo_id_field');
    let feeAmountField = $(container).find('.js-payment_method_credit_card_data_fee_amount_field');
    let cardChargeField = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_field');
    let cardChargeValueField = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_value_field');
    let feeTotalValueField = $(container).find('.js-payment_method_credit_card_data_fee_total_field');

    bankPromoIdField.val('').trigger('change');
    feeAmountField.val('')
    feeAmountField.html('')
    feeAmountField.trigger('change');
    cardChargeField.val(0.0);
    cardChargeValueField.val(0.0);
    feeTotalValueField.val(0.0);

    _this.updateCardAndBankPromoData(controllerContainer, container, 'card');
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer);
  }
  
  // Triggerado por el cambio de promo
  changeBankPromo(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let select = $(event.currentTarget);
    let container = select.closest('.nested-fields')

    let feeAmountField = $(container).find('.js-payment_method_credit_card_data_fee_amount_field');
    let cardChargeField = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_field');
    let cardChargeValueField = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_value_field');
    let feeTotalValueField = $(container).find('.js-payment_method_credit_card_data_fee_total_field');

    feeAmountField.val('')
    feeAmountField.html('')
    feeAmountField.trigger('change');
    cardChargeField.val(0.0);
    cardChargeValueField.val(0.0);
    feeTotalValueField.val(0.0);

    _this.updateCardAndBankPromoData(controllerContainer, container, 'bank_promo');
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer);
  }

  // Triggerado por el cambio de cuota
  changeFeeAmount(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let select = $(event.currentTarget);
    let container = select.closest('.nested-fields')

    let feeAmountField = $(container).find('.js-payment_method_credit_card_data_fee_amount_field');
    let fee_sale_charge = feeAmountField.find("option:selected").data('sale-charge');

    _this.updateCardChargeValue(controllerContainer, container, fee_sale_charge);
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer);
  }


  // Triggerado por el cambio de monto de paga con
  changeSalePaymentPaysWith(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let field_changed = $(event.currentTarget);

    _this.calculateSalePaymentPayChange(controllerContainer, field_changed);
  }


  // Triggerado por el cambio de monto de pago
  changeSalePaymentAmount(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let field_changed = $(event.currentTarget);

    _this.calculateSalePaymentAmount(controllerContainer, field_changed);
  }

  changePortionInvoicePerc(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateSaleTotals(controllerContainer);
  }

  private

  updateSaleDiscountKind(controllerContainer, option){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let saleDiscountKindField = $(controllerContainer).find('.js-sale_discount_kind_field');
    let saleDiscountKindContainer = $(controllerContainer).find('.js-sale_discount_kind_container');

    saleDiscountKindField.val(value);
    saleDiscountKindContainer.html(icon);
  }



  calculateSalePaymentPayChange(controllerContainer, field_changed){
    let _this = this;
    let container = field_changed.closest('.nested-fields');

    let payment_method_amount_field = container.find('.js-payment_method_amount_field');
    let sale_payment_pays_with_field = container.find('.js-sale_payment_pays_with_field');
    let sale_payment_pay_change_field = container.find('.js-sale_payment_pay_change_field');

    let payment_method_amount = parseFloat(payment_method_amount_field.val());
    payment_method_amount = (isNaN(payment_method_amount)) ? 0.0 : payment_method_amount;
    
    let sale_payment_pays_with = parseFloat(sale_payment_pays_with_field.val());
    sale_payment_pays_with = (isNaN(sale_payment_pays_with)) ? 0.0 : sale_payment_pays_with;

    let sale_payment_pay_change = payment_method_amount - sale_payment_pays_with;

    if (sale_payment_pay_change < 0.0){
      sale_payment_pay_change_field.val(_this.round(Math.abs(sale_payment_pay_change)));
    }
    else {
      sale_payment_pay_change_field.val(0.0);
    }
  }

  calculateSalePaymentAmount(controllerContainer, field_changed){
    let _this = this;

    if ($('#sale_payments_container .nested-fields:visible').length == 2){

      let sale_portion_invoice_tax_field = $(controllerContainer).find('.js-sale_portion_invoice_tax_field');

      let sale_portion_invoice_tax = parseFloat(sale_portion_invoice_tax_field.val());
      sale_portion_invoice_tax = (isNaN(sale_portion_invoice_tax)) ? 0.0 : sale_portion_invoice_tax;

      $('#sale_payments_container .nested-fields:visible').each(function(){
        if(
            ($(this).find('.js-payment_method_amount_field').get(0) != field_changed.get(0)) &&
            ($(this).find('.js-payment_method_field').val() != 'credit_note')
          ){
          let payment_method_amount_field = $(this).find('.js-payment_method_amount_field');
          let saleSubtotalField = $(controllerContainer).find('.js-sale_subtotal_field');
          let saleDiscountField = $(controllerContainer).find('.js-sale_discount_field');
          let saleClientTaxesField = $(controllerContainer).find('.js-sale_client_tax_field');
          let saleSaleTaxesTotalField = $(controllerContainer).find('.js-sale_sale_taxes_total_field');

          let subtotal = parseFloat(saleSubtotalField.val());
          subtotal = (isNaN(subtotal)) ? 0.0 : subtotal;
      
          let discount = parseFloat(saleDiscountField.val());
          discount = (isNaN(discount)) ? 0.0 : discount;
      
          let clientTax = parseFloat(saleClientTaxesField.val());
          clientTax = (isNaN(clientTax)) ? 0.0 : clientTax;



          let saleTaxTotal = parseFloat(saleSaleTaxesTotalField.val());
          saleTaxTotal = (isNaN(saleTaxTotal)) ? 0.0 : saleTaxTotal;

          let value_changed = parseFloat(field_changed.val());
          value_changed = (isNaN(value_changed)) ? 0.0 : value_changed;
      
          
          let total_without_card_charge_value = subtotal - discount + clientTax + sale_portion_invoice_tax + saleTaxTotal;
          let calculated_amount = total_without_card_charge_value - value_changed;
          if (calculated_amount < 0.0){
            payment_method_amount_field.val(0.0);
          }
          else{
            payment_method_amount_field.val(_this.round(calculated_amount));
          }
        } 
      })

    }
    _this.updateSalePromo(controllerContainer);
    _this.updateSaleTotals(controllerContainer); 
  }



  setOnOffPortionInvoice(controllerContainer){
    let _this = this;

    let sale_portion_invoice_field = $(controllerContainer).find('.js-sale_portion_invoice_field');
    let sale_portion_invoice_perc_field = $(controllerContainer).find('.js-sale_portion_invoice_perc_field');

    if(sale_portion_invoice_field.val() == 'true'){
      sale_portion_invoice_field.val('false');
      sale_portion_invoice_perc_field.val('0.0');
    }
    else{
      sale_portion_invoice_field.val('true');
    }
  }

  updateOnOffPortionInvoice(controllerContainer){
    let button = $(controllerContainer).find('#js-portion_invoice-button');
    let sale_portion_invoice_field = $(controllerContainer).find('.js-sale_portion_invoice_field');
    let portion_invoice_button = $(controllerContainer).find('#js-portion_invoice-button');  
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let portion_invoice_perc_fields_container = $(controllerContainer).find('.js-portion_invoice_perc_fields-container');
    


    if(saleSaleChannelValue == 'channel_b'){
      portion_invoice_button.fadeIn();
    }
    else if (saleSaleChannelValue == 'channel_a'){
      portion_invoice_button.hide();
    }

    if(sale_portion_invoice_field.val() == 'true'){
      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
      portion_invoice_perc_fields_container.fadeIn();
    }
    else{
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
      portion_invoice_perc_fields_container.hide();
    }
  }


  updateOnOffHaveTaxes(controllerContainer){
    let _this = this;
    let button = $(controllerContainer).find('#js-have_taxes-button');
    let sale_have_taxes_field = $(controllerContainer).find('.js-sale_have_taxes_field');
    let sale_portion_invoice_field = $(controllerContainer).find('.js-sale_portion_invoice_field');
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    

    if(saleSaleChannelValue == 'channel_b'){
      if(sale_portion_invoice_field.val() == 'true'){
        button.fadeIn();    
      }
      else{
        button.hide();
      }
    }
    else if (saleSaleChannelValue == 'channel_a'){
      button.fadeIn();
    }

    if(sale_have_taxes_field.val() == 'true'){
      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }


  setHaveTaxes(controllerContainer){
    let sale_have_taxes_field = $(controllerContainer).find('.js-sale_have_taxes_field');
    let add_sale_tax = $(controllerContainer).find('#js-add_sale_tax');

    if(sale_have_taxes_field.val() == 'true'){
      sale_have_taxes_field.val('false');
  
      $(controllerContainer).find('#sale_taxes_container .nested-fields:visible').each(function(){
        $(this).find('.remove_fields').trigger('click');
      });
    }
    else{
      sale_have_taxes_field.val('true');
      add_sale_tax.trigger('click');
    }
  }

  updateHaveTaxes(controllerContainer){
    let button = $(controllerContainer).find('#js-have_taxes-button');
    let sale_have_taxes_field = $(controllerContainer).find('.js-sale_have_taxes_field');
    let sale_taxes_box_container = $(controllerContainer).find('#sale_taxes_box_container');
    
    if(sale_have_taxes_field.val() == 'true'){
      sale_taxes_box_container.fadeIn();
      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      sale_taxes_box_container.hide();
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }



  setBookedAmount(controllerContainer){
    let sale_booked_amount_field = $(controllerContainer).find('.js-sale_booked_amount_field');
    
    if(sale_booked_amount_field.val() == 'true'){
      sale_booked_amount_field.val('false');
    }
    else{
      sale_booked_amount_field.val('true');
    }
  }

  updateBookedAmount(controllerContainer){
    let button = $(controllerContainer).find('#js-booked_amount-button');
    let sale_booked_amount_field = $(controllerContainer).find('.js-sale_booked_amount_field');
    
    if(sale_booked_amount_field.val() == 'true'){
      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }

  setMultiPayment(controllerContainer){
    let multi_payment_field = $(controllerContainer).find('.js-sale_multi_payment_field');
    
    if(multi_payment_field.val() == 'true'){
      multi_payment_field.val('false');
  
      let first_sale_payment = $('#sale_payments_container .nested-fields:visible').first();
      $(controllerContainer).find('#sale_payments_container .nested-fields:visible').each(function(){
        if($(this).get(0) != first_sale_payment.get(0)){
          $(this).find('.remove_fields').trigger('click');
        }
      });
    }
    else{
      multi_payment_field.val('true');
    }
  }

  updateMultiPayment(controllerContainer){
    let button = $(controllerContainer).find('#js-multi_payment-button');
    let multi_payment_field = $(controllerContainer).find('.js-sale_multi_payment_field');
    let add_button = $(controllerContainer).find('#js-add_sale_payment');
    let sale_payment_amount_containers = $(controllerContainer).find('.js-sale_payment_amount-container');
    
    if(multi_payment_field.val() == 'true'){
      //sale_payment_amount_containers.fadeIn();
      add_button.fadeIn();
      $(controllerContainer).find('#sale_payments_container .nested-fields').each(function(){
        $(this).find('.remove_fields').fadeIn();
      });

      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      //sale_payment_amount_containers.hide();
      add_button.hide();
      $(controllerContainer).find('#sale_payments_container .nested-fields').each(function(){
        $(this).find('.remove_fields').hide();
      });
      
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }

  
  setOnOffSalePromo(controllerContainer){
    let _this = this;
    let sale_promo_field = $(controllerContainer).find('.js-sale_sale_promo_field');
    
    if(sale_promo_field.val() == 'true'){
      if (confirm('¿Deshabilitar promociones?')){
        sale_promo_field.val('false');
        _this.cleanSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
      }
    }
    else{
      if (confirm('¿Habilitar promociones?')){
        sale_promo_field.val('true');
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
      };
    }
  }

  updateOnOffSalePromo(controllerContainer){
    let button = $(controllerContainer).find('#js-sale_promo-button');
    let sale_promo_field = $(controllerContainer).find('.js-sale_sale_promo_field');
    
    if(sale_promo_field.val() == 'true'){
      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }


  // Actualiza de recargo
  updateCardChargeValue(controllerContainer, container, sale_charge){
    let cardChargeField = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_field');
    
    sale_charge = parseFloat(sale_charge);
    sale_charge = (isNaN(sale_charge)) ? 0.0 : sale_charge;

    cardChargeField.val(sale_charge);
  }

  // Actualiza info de la tarjeta o la promo:
  updateCardAndBankPromoData(controllerContainer, container, kind){
    let _this = this;
    let cardIdField = $(container).find('.js-payment_method_debit_card_credit_card_card_id_field');
    let cardId = cardIdField.select2('val');
    let bankPromoIdField = $(container).find('.js-payment_method_debit_card_credit_card_bank_promo_id_field');
    let bankPromoId = bankPromoIdField.select2('val');
    let url;

    let paymentMethodValue = $(container).find('.js-payment_method_field').val();
    let feeAmountField = $(container).find('.js-payment_method_credit_card_data_fee_amount_field');


    if((bankPromoId != '') && (bankPromoId != undefined)){
      url = $(bankPromoIdField).data('url-two');

      url = url.replace('/the_id', '/'+bankPromoId);
      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {

        if(paymentMethodValue == 'debit_card'){
          let debit_card_sale_charge = data.debit_card_sale_charge;
          _this.updateCardChargeValue(controllerContainer, container, debit_card_sale_charge);
          _this.updateSalePromo(controllerContainer);
          _this.updateSaleTotals(controllerContainer);
        }
        else if(paymentMethodValue == 'credit_card'){
          let bank_promo_fees = data.bank_promo_fees;

          let blank_opt = $("<option>").val('').text('Seleccionar');
          feeAmountField.append(blank_opt);
          bank_promo_fees.forEach(function(bank_promo_fee) {
            let fee_sale_charge = bank_promo_fee.sale_charge;
            fee_sale_charge = parseFloat(fee_sale_charge);
            fee_sale_charge = (isNaN(fee_sale_charge)) ? 0.0 : fee_sale_charge;
             
            let opt = $("<option>");
            opt.val(bank_promo_fee.amount)
            opt.text(bank_promo_fee.amount_decorated);
            opt.attr('selected', 'selected');
            opt.data('sale-charge', fee_sale_charge);
            feeAmountField.append(opt);
          });
          feeAmountField.trigger('change');
        }
      });
      $.ajaxSetup({async: true});
    }
    else if((cardId != '') && (cardId != undefined)){
      url = $(cardIdField).data('url-two');

      url = url.replace('/the_id', '/'+cardId);
      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {

        let first_bank_promo = data.first_bank_promo;
        if((first_bank_promo != undefined) && (kind == 'card')){
          let first_bank_promo_opt = $("<option>");
          first_bank_promo_opt.val(first_bank_promo.id)
          first_bank_promo_opt.text(first_bank_promo.name);
          $(bankPromoIdField).append(first_bank_promo_opt).trigger('select2:select');
        }
        else{
          if(paymentMethodValue == 'debit_card'){
            let debit_card_sale_charge = data.debit_card_sale_charge;
            _this.updateCardChargeValue(controllerContainer, container, debit_card_sale_charge);
            bankPromoIdField.val('').trigger('change');
            _this.updateSalePromo(controllerContainer);
            _this.updateSaleTotals(controllerContainer);
          }
          else if(paymentMethodValue == 'credit_card'){
            let card_fees = data.card_fees;
  
            let blank_opt = $("<option>").val('').text('Seleccionar');
            feeAmountField.append(blank_opt);
            card_fees.forEach(function(card_fee) { 
              let fee_sale_charge = card_fee.sale_charge;
              fee_sale_charge = parseFloat(fee_sale_charge);
              fee_sale_charge = (isNaN(fee_sale_charge)) ? 0.0 : fee_sale_charge;
              
              let opt = $("<option>");
              opt.val(card_fee.amount)
              opt.text(card_fee.amount_decorated);
              opt.data('sale-charge', fee_sale_charge);
              if(card_fee.main){
                opt.attr('selected', 'selected');
              }
              feeAmountField.append(opt);
           });
  
           feeAmountField.trigger('select2:select');
          }
        }
      });
      $.ajaxSetup({async: true});
    }
  }

  setExternalInvoiced(controllerContainer, option){
    let _this = this;
    let saleExternalInvoicedField = $(controllerContainer).find('.js-sale_external_invoiced_field');
    
    let value = $(option).attr('href');
    saleExternalInvoicedField.val(value);
    _this.updateExternalInvoicedData(controllerContainer);
  }

  updateExternalInvoicedData(controllerContainer){
    let saleExternalInvoicedValue = $(controllerContainer).find('.js-sale_external_invoiced_field').val();
    let optionSelected = $(controllerContainer).find('.js-sale_external_invoiced_'+saleExternalInvoicedValue);
    let saleExternalInvoicedContainer = $(controllerContainer).find('.js-sale_external_invoiced_container');
    let saleInvoiceNumberField = $(controllerContainer).find('.js-sale_invoice_number_field');
    
    let icon = $(optionSelected).find('.icon').html();
    let text = $(optionSelected).find('.text').html();

    saleExternalInvoicedContainer.html(icon);
    saleInvoiceNumberField.attr('placeholder', text);

    if (saleExternalInvoicedValue == 'true'){
      saleInvoiceNumberField.attr('readonly','readonly');
      saleInvoiceNumberField.val('');
    } else {
      saleInvoiceNumberField.removeAttr('readonly');
    }
  }

  loadProducts(controllerContainer, button){
    let _this = this;
    let url = $(button).data('url');
    let category_id = $(button).data('id');
    let client_id = $('#sale_client_id').val();
    

    let tab_to_load = $('#js-product-container #category-'+category_id+' .product-tab')
    if (!tab_to_load.hasClass('js-products-loaded')){
      tab_to_load.addClass('js-products-loaded');
      $.ajaxSetup({async: false});
      url = url.replace('the_id', client_id);
      $.getScript(url);
      $.ajaxSetup({async: true});
    }
  }

  // Agrego un producto
  addProduct(controllerContainer, button){
    let _this = this;
    let productId = $(button).data('id');
    let productName = $(button).data('name');
    let productSymbol = $(button).data('symbol');
    
    $('#nested_sale_item_data .js-sale_item_symbol_container').html(productSymbol);
    $('#nested_sale_item_data .js-sale_item_amount_field').val(1);
    $('#nested_sale_item_data .js-sale_item_product_id').append('<option selected="selected" value="'+productId+'">'+productName+'<option>').trigger('change.select2');
    
    let saleItem = $(controllerContainer).find('#nested_sale_item_data');

    _this.updateSaleItemData(controllerContainer, saleItem);
    $('#js-add_sale_item').trigger('click');
  }

  // Actualiza info del pago:
  updatePaymentMethodData(controllerContainer, container){
    let _this = this;
    
    let paymentMethodValue = $(container).find('.js-payment_method_field').val();
    let paymentMethodCashData = $(container).find('.payment_method_cash_data');
    let paymentMethodBankTransferData = $(container).find('.payment_method_bank_transfer_data');
    let paymentMethodOtherData = $(container).find('.payment_method_other_data');
    let paymentMethodCheckData = $(container).find('.payment_method_check_data');
    let paymentMethodDebitCardCreditCardData = $(container).find('.payment_method_debit_card_credit_card_data');
    let paymentMethodDebitCardData = $(container).find('.payment_method_debit_card_data');
    let paymentMethodCreditCardData = $(container).find('.payment_method_credit_card_data');
    let paymentMethodCreditNoteData = $(container).find('.payment_method_credit_note_data');


    if (paymentMethodValue == 'cash'){
      paymentMethodCashData.show();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      paymentMethodCreditNoteData.hide();
      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'bank_transfer'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      paymentMethodCreditNoteData.hide();
      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'other'){
      paymentMethodOtherData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodCheckData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      paymentMethodCreditNoteData.hide();      

      
      let defaultCustomPaymentMethodId = $('.js-payment_method_default_custom_payment_method_id').val();
      if((defaultCustomPaymentMethodId != '') && (defaultCustomPaymentMethodId != undefined)){
        container.find('.js-payment_method_other_custom_payment_method_id_field').val(defaultCustomPaymentMethodId).trigger('change');
      }
      else{
        container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      }

      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'debit_card'){
      paymentMethodDebitCardCreditCardData.show();
      paymentMethodDebitCardData.show();
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCreditCardData.hide();
      paymentMethodCreditNoteData.hide();

      let defaultCardId = $('.js-payment_method_default_card_id').val();
      let defaultCardName = $('.js-payment_method_default_card_name').val();
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);        
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      if((defaultCardId != '') && (defaultCardId != undefined)){
        container.find('.js-payment_method_debit_card_credit_card_card_id_field').append('<option selected="selected" value="'+defaultCardId+'">'+defaultCardName+'<option>').trigger('change');
        _this.updateCardAndBankPromoData(controllerContainer, container, 'card');
      }
      else{
        container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      }
      
      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'credit_card'){

      paymentMethodDebitCardCreditCardData.show();
      paymentMethodCreditCardData.show();      
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditNoteData.hide();

      let defaultCardId = $('.js-payment_method_default_card_id').val();
      let defaultCardName = $('.js-payment_method_default_card_name').val();
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);        
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      if((defaultCardId != '') && (defaultCardId != undefined)){
        container.find('.js-payment_method_debit_card_credit_card_card_id_field').append('<option selected="selected" value="'+defaultCardId+'">'+defaultCardName+'<option>').trigger('change');
        _this.updateCardAndBankPromoData(controllerContainer, container, 'card');
      }
      else{
        container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      }
      
      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'check'){
      paymentMethodCheckData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      paymentMethodCreditNoteData.hide();
      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('0');
    }
    else if(paymentMethodValue == 'credit_note'){
      paymentMethodCreditNoteData.show();
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      container.find('.js-payment_method_amount_field').val('0.0');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
    else{
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      paymentMethodCreditNoteData.hide();
      container.find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
      container.find('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      container.find('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      container.find('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      container.find('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      container.find('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      container.find('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      container.find('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      container.find('.js-payment_method_check_destroy_field').val('1');
    }
  }

  // Actualiza info de la nota de credito:
  updateCreditNoteData(controllerContainer, creditNoteField){
    let _this = this;
    let container = creditNoteField.closest('.nested-fields');
    let paymentMethodAmountField = $(container).find('.js-payment_method_amount_field');
    let creditNoteId = creditNoteField.select2('val');

    let url = creditNoteField.data('url-two');
    
    if(creditNoteId != ''){
      url = url.replace('/the_id', '/'+creditNoteId);
      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let amount = data.amount;
  
        paymentMethodAmountField.val(amount);
        _this.calculateSalePaymentAmount(controllerContainer, paymentMethodAmountField);
      });   
      $.ajaxSetup({async: true});
    }
    else{
      paymentMethodAmountField.val(0.0);
      _this.calculateSalePaymentAmount(controllerContainer, paymentMethodAmountField);
    }
  }


  // Actualiza info del cliente:
  updateClientData(controllerContainer){
    let _this = this;
    let clientIdField = $(controllerContainer).find('.js-client_id_field');
    let clientId = clientIdField.select2('val');
    let saleClientTaxProfileField = $(controllerContainer).find('.js-sale_client_tax_profile_field');
    let saleClientDniField = $(controllerContainer).find('.js-sale_client_dni_field');
    let saleClientCuitField = $(controllerContainer).find('.js-sale_client_cuit_field');
    let saleClientAddressField = $(controllerContainer).find('.js-sale_client_address_field');
    let salePortionInvoiceField = $(controllerContainer).find('.js-sale_portion_invoice_field');
    let salePortionInvoicePercField = $(controllerContainer).find('.js-sale_portion_invoice_perc_field');


    let saleDiscountField = $(controllerContainer).find('.js-sale_discount_value_field');
    let saleTaxPercField = $(controllerContainer).find('.js-sale_tax_perc_field');

    let saleSaleChannelField = $(controllerContainer).find('.js-sale_sale_channel_field');

    let productsModalButton = $(controllerContainer).find('#products_modal_button');

    let url = $(clientIdField).data('url-two');
    
    if(clientId != ''){
      url = url.replace('/the_id', '/'+clientId);
      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let taxProfile = data.tax_profile;
        let cuit = data.cuit;
        let dni = data.dni;
        let address = data.address;
        let discountRate = data.discount_rate;
        let taxPerc = data.tax_perc;
        let paymentMethod = data.payment_method;
        let saleChannel = data.sale_channel;
        let portion_invoice = data.portion_invoice;
        let portion_invoice_perc = data.portion_invoice_perc;


        //Seteo perfil impositivo y tipo de cliente
        saleClientTaxProfileField.val(taxProfile);
        saleClientCuitField.val(cuit);
        saleClientDniField.val(dni);
        saleClientAddressField.val(address);
   

        //Seteo medio de pago y canal por defecto del cliente
        saleSaleChannelField.val(saleChannel);
        saleSaleChannelField.trigger('change');


        let first_sale_payment = $('#sale_payments_container .nested-fields:visible').first();
        $(controllerContainer).find('#sale_payments_container .nested-fields:visible').each(function(){
          if($(this).get(0) != first_sale_payment.get(0)){
            $(this).find('.remove_fields').trigger('click');
          }
          $(this).find('.js-payment_method_credit_note_payed_credit_note_id_field').val('').trigger('change');
        });
        let paymentMethodField = $(first_sale_payment).find('.js-payment_method_field');

        paymentMethodField.val(paymentMethod);
        paymentMethodField.trigger('select2:select');

        _this.setSaleInvoiceTypeField(controllerContainer);

        salePortionInvoiceField.val(portion_invoice);
        salePortionInvoicePercField.val(portion_invoice_perc);
        _this.updateOnOffPortionInvoice(controllerContainer);


        //Seteo descuento de cliente
        saleDiscountField.val(discountRate);

        //Seteo impuesto de cliente
        saleTaxPercField.val(taxPerc);


        //Refresh all prices
        $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
          let saleItem = $(this);
          _this.updateSaleItemData(controllerContainer, saleItem);
        });
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
      });   
      $.ajaxSetup({async: true});   
    }
    else{
      productsModalButton.hide();
    }
  }

  setSaleInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let saleClientTaxProfileValue = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleInvoiceTypeField = $(controllerContainer).find('.js-sale_invoice_type_field');
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let saleExternalInvoicedFalse = $(controllerContainer).find('.js-sale_external_invoiced_false');
    let saleExternalInvoicedTrue = $(controllerContainer).find('.js-sale_external_invoiced_true');  
    let salePointDemantecaActiveValue = $(controllerContainer).find('.js-sale_point_demanteca_active_field').val();
    let sale_portion_invoice_field = $(controllerContainer).find('.js-sale_portion_invoice_field');  
    let sale_portion_invoice_perc_field = $(controllerContainer).find('.js-sale_portion_invoice_perc_field');


    _this.updateSaleInvoiceTypeField(controllerContainer);

    if(saleSaleChannelValue == 'channel_b'){

      _this.setExternalInvoiced(controllerContainer, saleExternalInvoicedFalse);
      saleInvoiceTypeField.val('invoice_other');
    }
    else if (saleSaleChannelValue == 'channel_a'){

      if(salePointDemantecaActiveValue == 'true'){
        _this.setExternalInvoiced(controllerContainer, saleExternalInvoicedTrue);
      } else {
        _this.setExternalInvoiced(controllerContainer, saleExternalInvoicedFalse);
      }

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.val('invoice_c');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.val('invoice_c');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.val('invoice_c');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.val('invoice_c');
        }        
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.val('invoice_a');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.val('invoice_b');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.val('invoice_b');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.val('invoice_b');
        }        
      }
    }

    sale_portion_invoice_field.val('false');
    sale_portion_invoice_perc_field.val('0.0');

    _this.updateOnOffPortionInvoice(controllerContainer);


    saleInvoiceTypeField.trigger('select2:select');
  }

  updateSaleInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let saleClientTaxProfileValue = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleInvoiceTypeField = $(controllerContainer).find('.js-sale_invoice_type_field');
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let inputGroupPrepend = $(controllerContainer).find('.js-sale_external_invoiced_container').closest('.input-group-prepend');
    let salePointDemantecaActiveValue = $(controllerContainer).find('.js-sale_point_demanteca_active_field').val();

    if(saleSaleChannelValue == 'channel_b'){ 
      saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
      saleInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
      saleInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
      saleInvoiceTypeField.find("option[value='invoice_other']").removeAttr('disabled');
      inputGroupPrepend.hide();
    }
    else if (saleSaleChannelValue == 'channel_a'){
      saleInvoiceTypeField.find("option[value='invoice_other']").attr('disabled','disabled');
      if(salePointDemantecaActiveValue == 'true'){
        inputGroupPrepend.show();
      } else {
        inputGroupPrepend.hide();
      }

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(saleClientTaxProfileValue == 'enroled_responsable'){
          saleInvoiceTypeField.find("option[value='invoice_a']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
        else if (saleClientTaxProfileValue == 'monotax_responsable'){
          saleInvoiceTypeField.find("option[value='invoice_a']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
        else if (saleClientTaxProfileValue == 'end_consumer'){
          saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
        else if (saleClientTaxProfileValue == 'exempted_vat'){
          saleInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          saleInvoiceTypeField.find("option[value='invoice_b']").removeAttr('disabled');
          saleInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
      }
    }

    saleInvoiceTypeField.trigger('select2:select');
  }


  populateSaleItem(controllerContainer, saleItem){
    let _this = this;
    let saleItemProductIdData = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').select2('data')[0];
    let saleItemTaxPercValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_perc_field').val();
    let saleItemTaxValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_field').val();
    let saleItemSubtotalValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_subtotal_field').val();
    let saleItemPriceField = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_field');
    let saleItemPriceValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_field').val();
    let saleItemOriginalPriceValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_original_price_field').val();
    let saleItemPriceKindValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_kind_field').val();
    let saleItemPriceSymbol = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_kind_container').html();
    let saleItemAmountValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_amount_field').val();
    let saleItemDiscountValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_value_field').val();
    let saleItemDiscountKind = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_field').val();
    let saleItemDiscountSymbol = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_container').html();
    let saleItemTotalValue = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_total_field').val();
    let saleItemPromoText = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_promo_text').html();
    let saleItemSymbol = $(controllerContainer).find('#nested_sale_item_data .js-sale_item_symbol_container').html();

    saleItemPriceValue = parseFloat(saleItemPriceValue);
    saleItemPriceValue = (isNaN(saleItemPriceValue)) ? 0.0 : saleItemPriceValue;

    saleItemOriginalPriceValue = parseFloat(saleItemOriginalPriceValue);
    saleItemOriginalPriceValue = (isNaN(saleItemOriginalPriceValue)) ? 0.0 : saleItemOriginalPriceValue;

    saleItemAmountValue = parseFloat(saleItemAmountValue);
    saleItemAmountValue = (isNaN(saleItemAmountValue)) ? 0.0 : saleItemAmountValue;

    saleItemDiscountValue = parseFloat(saleItemDiscountValue);
    saleItemDiscountValue = (isNaN(saleItemDiscountValue)) ? 0.0 : saleItemDiscountValue;

    saleItem.find('.js-sale_item_product_id').append('<option selected="selected" value="'+saleItemProductIdData.id+'">'+saleItemProductIdData.text+'<option>').trigger('change.select2');
    saleItem.find('.js-sale_item_tax_perc_field').val(saleItemTaxPercValue);
    saleItem.find('.js-sale_item_tax_field').val(saleItemTaxValue);
    saleItem.find('.js-sale_item_subtotal_field').val(saleItemSubtotalValue);
    saleItem.find('.js-sale_item_price_field').val(saleItemPriceValue);
    saleItem.find('.js-sale_item_original_price_field').val(saleItemOriginalPriceValue);
    saleItem.find('.js-sale_item_price_kind_field').val(saleItemPriceKindValue);
    saleItem.find('.js-sale_item_price_kind_container').html(saleItemPriceSymbol);
    saleItem.find('.js-sale_item_amount_field').val(saleItemAmountValue);
    saleItem.find('.js-sale_item_discount_value_field').val(saleItemDiscountValue);
    saleItem.find('.js-sale_item_discount_kind_field').val(saleItemDiscountKind);
    saleItem.find('.js-sale_item_discount_kind_container').html(saleItemDiscountSymbol);
    saleItem.find('.js-sale_item_total_field').val(saleItemTotalValue);
    saleItem.find('.js-sale_item_promo_text').html(saleItemPromoText);
    saleItem.find('.js-sale_item_symbol_container').html(saleItemSymbol);

    if(saleItemPriceField.attr('readonly') == 'readonly'){
      saleItem.find('.js-sale_item_price_field').attr('readonly','readonly');
    }
    else {
      saleItem.find('.js-sale_item_price_field').removeAttr('readonly');
    }

    _this.cleanNestedSaleItem(controllerContainer);

    //if(saleItemTotalValue == 0.0){
    let timeToRefreshSaleItem = 0;
    clearTimeout(timeToRefreshSaleItem);
    timeToRefreshSaleItem = setTimeout(function() {
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSalePromo(controllerContainer);
      _this.updateSaleTotals(controllerContainer);        
    }, 200);
    //}

    // Focus on product select
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').select2('focus');
  }

  cleanNestedSaleItem(controllerContainer){
    let baseDiscountSymbol = "<i class='fas fa-percent'></i>";
    let basePriceSymbol = "<i class='fas fa-dollar-sign'></i>";
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_product_id').val('').trigger('change.select2');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_perc_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_tax_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_subtotal_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_field').attr('readonly','readonly');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_original_price_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_amount_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_value_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_kind_field').val('price_kind_fix');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_price_kind_container').html(basePriceSymbol);
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_field').val('discount_kind_perc');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_discount_kind_container').html(baseDiscountSymbol);
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_total_field').val('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_promo_text').html('');
    $(controllerContainer).find('#nested_sale_item_data .js-sale_item_symbol_container').html('');
    
    // Remove error alert from list
    $(controllerContainer).find('#section-two .alert.alert-danger').remove();
  }

  cleanSaleItem(controllerContainer, saleItem){
    let baseDiscountSymbol = "<i class='fas fa-percent'></i>";
    let basePriceSymbol = "<i class='fas fa-dollar-sign'></i>";
    $(saleItem).find('.js-sale_item_product_id').val('').trigger('change.select2');
    $(saleItem).find('.js-sale_item_tax_perc_field').val('');
    $(saleItem).find('.js-sale_item_tax_field').val('');
    $(saleItem).find('.js-sale_item_subtotal_field').val('');
    $(saleItem).find('.js-sale_item_price_field').val('');
    $(saleItem).find('.js-sale_item_price_field').attr('readonly','readonly');
    $(saleItem).find('.js-sale_item_original_price_field').val('');
    $(saleItem).find('.js-sale_item_amount_field').val('');
    $(saleItem).find('.js-sale_item_discount_value_field').val('');
    $(saleItem).find('.js-sale_item_price_kind_field').val('price_kind_fix');
    $(saleItem).find('.js-sale_item_price_kind_container').html(basePriceSymbol);    
    $(saleItem).find('.js-sale_item_discount_kind_field').val('discount_kind_perc');
    $(saleItem).find('.js-sale_item_discount_kind_container').html(baseDiscountSymbol);
    $(saleItem).find('.js-sale_item_total_field').val('');
    $(saleItem).find('.js-sale_item_promo_text').html('');
    $(saleItem).find('.js-sale_item_price_difference_alert').remove();
  }

  bindSaleItem(controllerContainer, saleItem){
    let _this = this;
    let saleItemProductIdField = saleItem.find('.js-sale_item_product_id');
    let saleItemPriceField = saleItem.find('.js-sale_item_price_field');
    let saleItemOriginalPriceField = saleItem.find('.js-sale_item_original_price_field');
    let saleItemAmountField = saleItem.find('.js-sale_item_amount_field');
    let saleItemDiscountValueField = saleItem.find('.js-sale_item_discount_value_field');
    let saleItemDiscountKindDropdownOption = saleItem.find('.js-sale_item_discount_kind_dropdown_option');
    let saleItemPriceKindDropdownOption = saleItem.find('.js-sale_item_price_kind_dropdown_option');
    let saleItemPriceDifferenceAlert = saleItem.find('.js-sale_item_price_difference_alert');

    $(saleItemProductIdField).on('select2:select', function() {
      _this.updateSaleItemData(controllerContainer, saleItem);
    });

    $(saleItemProductIdField).on('select2:unselect', function() {
      _this.updateSaleItemData(controllerContainer, saleItem);
    });


    let timeToRefreshSaleItem = 0;
    $(saleItemAmountField).on('input', function() {
      clearTimeout(timeToRefreshSaleItem);
      timeToRefreshSaleItem = setTimeout(function() {
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);        
      }, 200);
    });

    $(saleItemPriceDifferenceAlert).on('click', function() {
      let recalculated_price = $(this).data('recalculated-price');
      saleItemPriceField.val(recalculated_price);
      saleItemOriginalPriceField.val(recalculated_price);
      $(this).remove();
      clearTimeout(timeToRefreshSaleItem);
      timeToRefreshSaleItem = setTimeout(function() {
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);        
      }, 200);
    });

    $(saleItemPriceField).on('input', function() {
      clearTimeout(timeToRefreshSaleItem);
      timeToRefreshSaleItem = setTimeout(function() {
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);        
      }, 200);
    });


    $(saleItemDiscountValueField).on('input', function() {
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSaleTotals(controllerContainer);
    });

    $(saleItemDiscountKindDropdownOption).on('click', function(event) {
      event.preventDefault();
      _this.updateSaleItemDiscountKind(this, saleItem);
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSaleTotals(controllerContainer);
    });

    $(saleItemPriceKindDropdownOption).on('click', function(event) {
      event.preventDefault();
      _this.updateSaleItemPriceKind(this, saleItem);
      _this.updateSaleItemTotals(controllerContainer, saleItem);
      _this.updateSaleTotals(controllerContainer);
    });
  }

  updateSaleItemDiscountKind(option, saleItem){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let saleItemDiscountKindField = saleItem.find('.js-sale_item_discount_kind_field');
    let saleItemDiscountKindContainer = saleItem.find('.js-sale_item_discount_kind_container');

    saleItemDiscountKindField.val(value);
    saleItemDiscountKindContainer.html(icon);
  }

  updateSaleItemPriceKind(option, saleItem){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let saleItemPriceKindField = saleItem.find('.js-sale_item_price_kind_field');
    let saleItemPriceKindContainer = saleItem.find('.js-sale_item_price_kind_container');

    saleItemPriceKindField.val(value);
    saleItemPriceKindContainer.html(icon);
  }

  updateSaleItemData(controllerContainer, saleItem){
    let _this = this;
    let clientIdValue = $(controllerContainer).find('.js-client_id_field').select2('val');
    let saleItemSymbolContainer = saleItem.find('.js-sale_item_symbol_container');
    let saleItemPriceField = saleItem.find('.js-sale_item_price_field');
    let saleItemOriginalPriceField = saleItem.find('.js-sale_item_original_price_field');
    let saleItemTaxPercField = saleItem.find('.js-sale_item_tax_perc_field');
    let saleItemPriceKindField = saleItem.find('.js-sale_item_price_kind_field');
    let saleItemPriceKindContainer = saleItem.find('.js-sale_item_price_kind_container');
    let productIdField = saleItem.find('.js-sale_item_product_id');
    let productAmountField = saleItem.find('.js-sale_item_amount_field');
    let saleItemPriceDifferenceAlert = saleItem.find('.js-sale_item_price_difference_alert');

    let saleItemRemoveFieldsButton = saleItem.find('.remove_fields');
    let productIdValue = productIdField.select2('val');
    let productAmountValue = productAmountField.val();
    let url = $(productIdField).data('url-two');
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let saleClientTaxProfileField = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let saleInvoiceTypeValue = $(controllerContainer).find('.js-sale_invoice_type_field').val();

    if((productIdValue != '') && (productIdValue != undefined)){
      url = url.replace('/the_id/show_for_sale', '/'+productIdValue+'/show_for_sale?client_id='+clientIdValue+'&amount='+productAmountValue);
      url = url.replace('/the_id/show_for_buy', '/'+productIdValue+'/show_for_buy?client_id='+clientIdValue+'&amount='+productAmountValue);
      url = url.replace('?from_buy=', '&from_buy=')

      let sale_form_mode = $(controllerContainer).find('.js-sale_form_mode_field').val();
      if(sale_form_mode == 'barcode_reader'){
        if ((productAmountValue == undefined) || (productAmountValue == '')){
          productAmountField.val(1);
        }
      }

      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let price = data.price;
        if(price == undefined){
            if (saleItemRemoveFieldsButton.length != 0){
              saleItemRemoveFieldsButton.trigger('click');
            }
            else{
              _this.cleanNestedSaleItem(controllerContainer);
            }
        }
        else{
          let symbol = data.measurement_category_symbol;
          let price = data.price;
          let price_kind = data.price_kind;
          let product_kind = data.product_kind;
          let taxPerc = data.tax_perc;
          
          saleItemSymbolContainer.html(symbol);
          saleItemSymbolContainer.fadeIn();
          
          price = parseFloat(price);
          price = (isNaN(price)) ? 0.0 : price;
          
          if(product_kind == 'product_kind_service'){
            saleItemPriceField.removeAttr('readonly');
          }
          else {
            saleItemPriceField.attr('readonly','readonly');
          }

          if (
              (
                (price_kind == 'price_kind_fix') &&
                (salePointTaxProfileValue == 'enroled_responsable') &&
                (saleClientTaxProfileField == 'enroled_responsable') &&
                (saleSaleChannelValue == 'channel_a')
              ) ||
              (
                (price_kind == 'price_kind_fix') &&
                (salePointTaxProfileValue == 'enroled_responsable') &&
                (saleClientTaxProfileField == 'monotax_responsable') &&
                (saleInvoiceTypeValue == 'invoice_a') &&
                (saleSaleChannelValue == 'channel_a')
              )
            ){
              
            var tax_perc_value = parseFloat(taxPerc);
            tax_perc_value = (isNaN(tax_perc_value)) ? 0.0 : tax_perc_value;
            
            let price_net = price / (1 + (tax_perc_value / 100.0))
            price_net = _this.round(price_net);

            price = _this.round(price);

            saleItemPriceField.val(price_net);
            saleItemOriginalPriceField.val(price);
          }
          else{
            saleItemPriceField.val(price);
            saleItemOriginalPriceField.val(price);
          }


          saleItemPriceKindField.val(price_kind);
          if(price_kind == 'price_kind_fix'){
            saleItemPriceKindContainer.html("<i class='fas fa-dollar-sign'></i>");
          }
          else {
            saleItemPriceKindContainer.html("<i class='fas fa-percent'></i>");
          }

          saleItemPriceDifferenceAlert.remove();
          saleItemTaxPercField.val(taxPerc);

        }
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
      });
      $.ajaxSetup({async: true});
    }
    else{
      if (saleItemRemoveFieldsButton.length != 0){
        _this.cleanSaleItem(controllerContainer, saleItem);
        _this.updateSaleItemTotals(controllerContainer, saleItem);
        _this.updateSalePromo(controllerContainer);
        _this.updateSaleTotals(controllerContainer);
      }
      else{
        _this.cleanNestedSaleItem(controllerContainer);
      }
    }
  }

  updateSaleItemTotals(controllerContainer, saleItem){
    let _this = this;

    SaleCalculation.calculateSaleItem(_this, controllerContainer, saleItem);
  }

  cleanSalePromo(controllerContainer){
    let _this = this;
    $('#sale_items_container .nested-fields').each(function(){
      let saleItem = $(this);
      saleItem.find('.js-sale_item_discount_value_field').val(0.0);
      saleItem.find('.js-sale_item_discount_kind_field').val('discount_kind_perc');
      saleItem.find('.js-sale_item_discount_kind_container').html("<i class='fas fa-percent'></i>");
      saleItem.find('.js-sale_item_promo_text').html('');
      _this.updateSaleItemTotals(controllerContainer, saleItem);
    });
    $(controllerContainer).find('.js-sale_promo_text').html('');
    $(controllerContainer).find('.js-sale_discount_value_field').val(0.0);
  }

  updateSalePromo(controllerContainer){
    let _this = this;
    let sale_promo_field = $(controllerContainer).find('.js-sale_sale_promo_field');
    
    if(sale_promo_field.val() == 'true'){
      $(controllerContainer).find('.js-sale_item_promo_text').html('');
      $(controllerContainer).find('.js-sale_promo_text').html('');
      
      let products_params = [];
      $('#sale_items_container .nested-fields:visible').each(function(){
        let saleItem = $(this);
        //let sale_item_product_id = saleItem.find('.js-sale_item_product_id').select2('val')
        let sale_item_product_id = saleItem.find('.js-sale_item_product_id').val();
        let sale_item_amount = saleItem.find('.js-sale_item_amount_field').val();
        if((sale_item_product_id != '') && (sale_item_product_id != undefined)){
          products_params.push('products['+sale_item_product_id+']='+sale_item_amount);
        }
      });


      let sale_discount_value_field = $(controllerContainer).find('.js-sale_discount_value_field');
      let sale_promo_text_container = $(controllerContainer).find('.js-sale_promo_text');
      let sale_promos_url = $(controllerContainer).data('sale-promos-url');

      sale_promos_url = sale_promos_url + '?' + products_params.join('&');
      $.ajaxSetup({async: false});
      $.getJSON(sale_promos_url, function(data) {


        data.forEach(function(item) {
          let addedProductIds = $("#sale_items_container .js-sale_item_product_id:visible").map(function() { return $(this).val(); }).get()

          
          let product_id = item.product_id;
          let applies_to = item.applies_to;
          let kind = item.kind;
          let discountValue = item.discount_value;
          let discountKind = item.discount_kind;
          let promoText = item.promo_text;

          if(kind == 'kind_amount_discount'){

            if (
              (applies_to == 'applies_to_products') ||
              (applies_to == 'applies_to_product_categories')
              ){
              if (jQuery.inArray(product_id, addedProductIds) !== -1){
                let input = $('#sale_items_container .js-sale_item_product_id option[value="'+product_id+'"]:selected')
                let saleItem = input.closest('.nested-fields');
    
                let saleItemDiscountValueField = saleItem.find('.js-sale_item_discount_value_field');
                let saleItemDiscountKindField = saleItem.find('.js-sale_item_discount_kind_field');
                let saleItemDiscountKindContainer = saleItem.find('.js-sale_item_discount_kind_container');
                let saleItemPromoTextContainer = saleItem.find('.js-sale_item_promo_text');
    
                discountValue = parseFloat(discountValue);
                discountValue = (isNaN(discountValue)) ? 0.0 : discountValue;
    
    
                saleItemDiscountValueField.val(discountValue);
                saleItemDiscountKindField.val(discountKind);
                if(discountKind == 'discount_kind_fix'){
                  saleItemDiscountKindContainer.html("<i class='fas fa-dollar-sign'></i>");
                }
                else {
                  saleItemDiscountKindContainer.html("<i class='fas fa-percent'></i>");
                }
                saleItemPromoTextContainer.append(promoText);
                _this.updateSaleItemTotals(controllerContainer, saleItem);
              }
            } else if (applies_to == 'applies_to_all'){

              discountValue = parseFloat(discountValue);
              discountValue = (isNaN(discountValue)) ? 0.0 : discountValue;

              sale_discount_value_field.val(discountValue);
              sale_promo_text_container.append(promoText);

            }


          }

        });
      });
      $.ajaxSetup({async: true});
    }

  }

  updateSalePaymentAmountReadonly(controllerContainer){
    if ($('#sale_payments_container .nested-fields:visible').length == 1){
      let container = $('#sale_payments_container .nested-fields:visible');
      let payment_method_amount_field = $(container).find('.js-payment_method_amount_field');
      payment_method_amount_field.attr('readonly','readonly');
    }
    else {
      $('#sale_payments_container .nested-fields:visible').each(function(){
        let container = $(this);
        let payment_method_amount_field = $(container).find('.js-payment_method_amount_field');
        if (container.find('.js-payment_method_field').val() != 'credit_note'){
          payment_method_amount_field.removeAttr('readonly');
        }
        else{
          payment_method_amount_field.attr('readonly','readonly');
        }

      });

    }
  }

  updateSalePaymentPaysWithPayChangeReadonly(controllerContainer){
    let container = $('#sale_payments_container .nested-fields:visible');
    let sale_payment_pays_with_container = $(container).find('.js-sale_payment_pays_with_field').closest('.payment_method_cash_data');
    let sale_payment_pay_change_container = $(container).find('.js-sale_payment_pay_change_field').closest('.payment_method_cash_data');
    let payment_method_value = $(container).find('.js-payment_method_field').val();

    if (($('#sale_payments_container .nested-fields:visible').length == 1) && (payment_method_value == 'cash')){
      sale_payment_pays_with_container.show();
      sale_payment_pay_change_container.show();
    }
    else {
      sale_payment_pays_with_container.hide();
      sale_payment_pay_change_container.hide();
    }
  }




  updateSaleTotals(controllerContainer){
    let _this = this;
    
    SaleCalculation.calculateTotal(_this, controllerContainer);
    _this.updateSaleItemCounter();
  }

  // Toggle mensaje cuando no hay items agregados a la venta
  checkSaleItemEmpty(controllerContainer) {
    let _this = this;

    if ($("#sale_items_container .nested-fields:visible").length == 0) {
      $("#js-sale-item-no-content").show();
    } else {
      $("#js-sale-item-no-content").hide();
    }
  }


  updateSaleItemCounter() {
    let _this = this;
    let counterValue = 0;
    $('#sale_items_container .nested-fields:visible').each(function(){
      let saleItem = $(this);
      let saleItemAmountField = saleItem.find(".js-sale_item_amount_field");
      let saleItemAmountValue = parseFloat(saleItemAmountField.val());
      saleItemAmountValue = isNaN(saleItemAmountValue) ? 0.0 : saleItemAmountValue;
      counterValue += saleItemAmountValue;
    });

    if (counterValue == 0){
      $('#js-sale-item-counter').hide();
    }
    else {
      $('#js-sale-item-counter').show();
    }
    $('#js-sale-item-counter-value').html(counterValue);
  }

}

