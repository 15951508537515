
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SupplierForm controller");
    }
  }

  changeSupplierType(){
    let _this = this;
    let supplier_type = $('.js-supplier_type').val();

    if (supplier_type == 'normal') {
      $('#js-sale_point_id_container').hide();
      $(".js-sale_point").val(null).trigger("change");
    } else {
      $('#js-sale_point_id_container').show();
    }
  }
}
