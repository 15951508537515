import { Controller } from "stimulus";
import { Select2 } from "../../lib/select2.js";
import { Datetimepicker } from "../../lib/datetimepicker.js";
import { Daterangepicker } from "../../lib/daterangepicker.js";
import { ImageCropper } from "../../lib/image-cropper.js";
import { TooltipPopover } from "../../lib/tooltip-popover.js";
import { MultiSelect } from "../../lib/multi-select.js";

export default class extends Controller {
  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect Form controller");

      let _this = this;
      let controllerContainer = this.context.element;

      this.initSelect2();
      this.initTooltipPopover();
      this.initDatetimepicker();
      this.initDaterangepicker();
      this.initImageCropper();
      _this.initMultiSelect();

      $(controllerContainer).on(
        "cocoon:after-insert",
        function (e, insertedItem, originalEvent) {
          //alert('cocoon:after-insert');
          _this.initSelect2();
          _this.initTooltipPopover();
          _this.initDatetimepicker();
          _this.initDaterangepicker();
          _this.initImageCropper();
          _this.initMultiSelect();
        }
      );
      $(controllerContainer).on(
        "cocoon:after-remove",
        function (e, insertedItem, originalEvent) {
          //alert('cocoon:after-remove');
        }
      );
    }
  }

  initMultiSelect() {
    let multiSelect = new MultiSelect();
    multiSelect.initialize();
  }

  initTooltipPopover() {
    let tooltipPopover = new TooltipPopover();
    tooltipPopover.initialize();
  }

  initSelect2() {
    let select2 = new Select2();
    select2.initialize();
  }

  initDatetimepicker() {
    let datetimepicker = new Datetimepicker();
    datetimepicker.initialize();
  }

  initDaterangepicker() {
    let daterangepicker = new Daterangepicker();
    daterangepicker.initialize();
  }

  initImageCropper() {
    let imageCropper = new ImageCropper();
    imageCropper.initialize();
  }
}
