
import { Controller } from 'stimulus';
import { FlashMessages } from '../lib/flash-messages.js';
import { Modals } from '../lib/modals.js';
import { DOMManager } from '../lib/dom-manager.js';
import { MathExt } from '../lib/math-ext.js';

export default class extends Controller {
  static targets = ['list'];
  
  // Success form submit
  formSuccess(event){
    const [data, status, xhr] = event.detail;

    Modals.modalReset();
    Modals.modalHide();
    this.addOrRefreshElement(xhr);
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

  // Fail form submit (validation fail for example)
  formError(event){
    const [data, status, xhr] = event.detail;
    let controllerContainer = this.context.element;

    Modals.modalFill(xhr.responseText);
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.manageTabErrors();
  }

  // Success link element
  linkSuccess(event){
    const [data, status, xhr] = event.detail;
 
    const targetDomId = event.target.dataset.domId;
    DOMManager.removeRow(targetDomId);
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  // Success link element and refresh
  linkRefreshSuccess(event){
    const [data, status, xhr] = event.detail;
 
    const targetDomId = event.target.dataset.domId;
    this.addOrRefreshElement(xhr);
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  // Fail link element
  linkError(event){
    const [data, status, xhr] = event.detail;

    Modals.modalShow();
    Modals.modalSize('modal-lg');
    Modals.modalFill(xhr.responseText);
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  // Success destroy element
  destroySuccess(event){
    const [data, status, xhr] = event.detail;
 
    const targetDomId = event.target.dataset.domId;
    DOMManager.removeRow(targetDomId);
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  // Fail destroy element
  destroyError(event){
    const [data, status, xhr] = event.detail;

    Modals.modalShow();
    Modals.modalSize('modal-lg');
    Modals.modalFill(xhr.responseText);
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  // Success modal open window (new, edit and show)
  modalOpenSuccess(event){ 
    const [data, status, xhr] = event.detail;
    const modalClass = event.target.dataset.modalClass;

    event.stopPropagation();
    Modals.modalShow();
    Modals.modalSize(modalClass);
    Modals.modalFill(xhr.responseText);
    this.enableActions(event);
  }

  // Fail modal open window (no permision for example)
  modalOpenError(event){
    const [data, status, xhr] = event.detail;

    event.stopPropagation();
    if (xhr.responseText != ''){
      Modals.modalShow();
      Modals.modalSize('modal-lg');
      Modals.modalFill(xhr.responseText);
    }
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  // Success modal refresh window
  modalRefreshSuccess(event){ 
    const [data, status, xhr] = event.detail;

    event.stopPropagation();
    Modals.modalFill(xhr.responseText);
    this.enableActions(event);
  }

  // Fail modal refresh window (no permision for example)
  modalRefreshError(event){
    const [data, status, xhr] = event.detail;

    event.stopPropagation();
    if (xhr.responseText != ''){
      Modals.modalSize('modal-lg');
      Modals.modalFill(xhr.responseText);
    }
    FlashMessages.message(this.parseFlashMessage(xhr));
    this.enableActions(event);
  }

  disableButtonAction(event){
    this.disableActions(event);
  }

  toggleFilter(event){
    let buttonContainer = $('#js-float-buttons')
    let button = $(event.currentTarget)
    if(buttonContainer.hasClass('btn-fixed-down')){
      let collapseId = button.attr('href');
      
      if ($(collapseId).hasClass('show')){
        event.stopPropagation();
      }
      $('html, body').animate( {scrollTop : 0}, 800 );
    }
  }

  formatToCurrency(amount){
    let symbol = '';
    if (amount < 0){
      symbol = "-$ " 
    } else {
      symbol = "$ "
    }

    amount = this.round(Math.abs(amount));
    let decimals = 3;
    let dec_point = ',';
    let thousands_sep = '.';
    let strip_insignificant_zeros = true;

    let n = !isFinite(+amount) ? 0 : +amount
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    let sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep
    let dec = (typeof dec_point === 'undefined') ? '.' : dec_point
    let toFixedFix = function (n, prec) {
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        var k = Math.pow(10, prec);
        return Math.round(n * k) / k;
    }
    let s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if(!strip_insignificant_zeros){
      if ((s[1] || '').length < prec) {
          s[1] = s[1] || '';
          s[1] += new Array(prec - s[1].length + 1).join('0');
      }
    }
    return symbol + s.join(dec);

  };

  parseFloatOrZero(amount){
    return MathExt.parseFloatOrZero(amount)
  };
  
  round(amount){
    return MathExt.round(amount, -3)
  };

  private

  disableActions(event){
    const button = $(event.target);
    const container = button.closest('tr');
    container.addClass('remove_actions');
  }

  enableActions(event){
    const button = $(event.target);
    const container = button.closest('tr');
    container.removeClass('remove_actions');
  }

  addOrRefreshElement(xhr){
    let insertMode = xhr.getResponseHeader('X-InsertMode');
    let insertModeSourceId = xhr.getResponseHeader('X-InsertModeSourceId');
    
    if (insertMode == 'add'){
      if(insertModeSourceId == undefined){
        if (this.hasListTarget){
          DOMManager.addRow(this.listTarget, xhr.responseText);
        }
        else{
          location.reload();
        }
      }
      else {
        DOMManager.addOption(insertModeSourceId, xhr.responseText);
      }
    }
    else if (insertMode == 'refresh'){
      DOMManager.refreshRow(xhr.responseText);
    }
  }

  parseFlashMessage(xhr){
    return $.parseJSON(xhr.getResponseHeader('X-Flash'));
  }


  // Before filter form submit
  beforeSendFilterForm(event){
    const [data, settings] = event.detail;
    let _this = this;
  
    window.history.replaceState('', '', event.detail[1].url);
  }

  manageTabErrors(){
    if ($('ul.nav-tabs').length && $('div.tab-content').length) {
      $(".tab-pane").each(function( index ) {
        if ($(this).find('.is-invalid').length > 0) {
          $("[aria-controls='" + $(this).attr('id') + "']").addClass("text-danger border-danger border-bottom-0");
        }
      });
      $(".nav-link.text-danger").first().tab('show');
    }
  }
}
