
export class WasteCalculation {

  //----------------------------------------------------------------
  // Calcula el total de la devolucion
  //----------------------------------------------------------------


  static calculateTotal(context, controllerContainer){
    
    //--------------------------------------------------------
    //----- Calculate subtotal
    let saleSubtotalContainer = $(controllerContainer).find('.js-sale_subtotal_container');
    let saleSubtotalFixField = $(controllerContainer).find('.js-sale_subtotal_fix_field');
    let saleSubtotalPercField = $(controllerContainer).find('.js-sale_subtotal_perc_field');
    let saleSubtotalField = $(controllerContainer).find('.js-sale_subtotal_field');

    let subtotal_fix = 0.0;
    let subtotal_perc = 0.0;


    $('#sale_items_container .nested-fields:visible').each(function(){
      let containerSaleItem = $(this);
      
      let sale_item_price_kind_field = containerSaleItem.find('.js-sale_item_price_kind_field').val();
      let sale_item_tax_field = containerSaleItem.find('.js-sale_item_tax_field');
      let sale_item_total_field = containerSaleItem.find('.js-sale_item_total_field');

      
      let sale_item_tax_amount = context.parseFloatOrZero(sale_item_tax_field.val());
      let sale_item_total_amount = context.parseFloatOrZero(sale_item_total_field.val());

      if(sale_item_price_kind_field == 'price_kind_fix'){
        subtotal_fix += sale_item_total_amount;
        subtotal_fix += sale_item_tax_amount;
      }
    });
  
    subtotal_fix = context.round(subtotal_fix);
    saleSubtotalFixField.val(subtotal_fix);


    $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
      let containerSaleItem = $(this);
      
      let sale_item_price_kind_field = containerSaleItem.find('.js-sale_item_price_kind_field').val();
      let sale_item_tax_field = containerSaleItem.find('.js-sale_item_tax_field')
      
      if(sale_item_price_kind_field == 'price_kind_perc'){
        context.updateSaleItemTotals(controllerContainer, containerSaleItem);

        let sale_item_tax_amount = context.parseFloatOrZero(sale_item_tax_field.val());

        if(containerSaleItem.attr('id') != 'nested_sale_item_data'){

          let sale_item_total_field = containerSaleItem.find('.js-sale_item_total_field');
          let sale_item_total_amount = context.parseFloatOrZero(sale_item_total_field.val());

          subtotal_perc += sale_item_total_amount
          subtotal_perc += sale_item_tax_amount
        }
      }
    });

    subtotal_perc = context.round(subtotal_perc);
    saleSubtotalPercField.val(subtotal_perc);

    let subtotal = subtotal_fix + subtotal_perc;
    subtotal = context.round(subtotal);

    saleSubtotalContainer.html(context.formatToCurrency(subtotal));
    saleSubtotalField.val(subtotal);
    

    //--------------------------------------------------------
    //----- Calculate discount
    let discount_kind = $(controllerContainer).find('.js-sale_discount_kind_field').val();
    let discount_value = $(controllerContainer).find('.js-sale_discount_value_field').val();

    let saleDiscountContainer = $(controllerContainer).find('.js-sale_discount_container');
    let saleDiscountField = $(controllerContainer).find('.js-sale_discount_field');

    discount_value = context.parseFloatOrZero(discount_value);

    let discount = 0.0;
    let discount_perc = 0.0;

    if (discount_kind == 'discount_kind_perc'){
      // Si descuento es porcentual
      discount = subtotal * (discount_value / 100);
      discount_perc = discount_value;
    }
    else{
      // Si descuento es fijo
      discount = discount_value;
      discount_perc = discount_value  / subtotal * 100.0;
    }
    discount = context.round(discount);

    saleDiscountContainer.html(context.formatToCurrency(discount));
    saleDiscountField.val(discount);


    //--------------------------------------------------------
    //----- Calculate net and tax
    let saleTaxContainer = $(controllerContainer).find('.js-sale_tax_container');
    let saleTaxField = $(controllerContainer).find('.js-sale_tax_field');
    let saleNetContainer = $(controllerContainer).find('.js-sale_net_container');
    let saleNetField = $(controllerContainer).find('.js-sale_net_field');


    let net = 0.0;
    $('#sale_items_container .nested-fields:visible .js-sale_item_total_field').each(function(){
      net += context.parseFloatOrZero($(this).val());
    });

    let net_discount = net * (discount_perc / 100);
    net_discount = context.round(net_discount);
    net = net - net_discount;
    net = context.round(net);

    let tax = 0.0;
    $('#sale_items_container .nested-fields:visible .js-sale_item_tax_field').each(function(){
      tax += context.parseFloatOrZero($(this).val());
    });

    let tax_discount = tax * (discount_perc / 100);
    tax = tax - tax_discount;
    tax = context.round(tax);


    saleTaxContainer.html(context.formatToCurrency(tax));
    saleTaxField.val(tax);
    saleNetContainer.html(context.formatToCurrency(net));
    saleNetField.val(net);



    //--------------------------------------------------------
    //----- Calculate client_tax
    let saleTaxPercValue = $(controllerContainer).find('.js-sale_tax_perc_field').val();
    let saleClientTaxesContainer = $(controllerContainer).find('.js-sale_client_tax_container');
    let saleClientTaxesField = $(controllerContainer).find('.js-sale_client_tax_field');

    saleTaxPercValue = context.parseFloatOrZero(saleTaxPercValue);


    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let clientTax = 0.0;

    if(saleSaleChannelValue == 'channel_a'){
      clientTax = net * (saleTaxPercValue / 100);
      clientTax = context.round(clientTax);
    }

    saleClientTaxesContainer.html(context.formatToCurrency(clientTax));
    saleClientTaxesField.val(clientTax);



    //--------------------------------------------------------
    //----- Calculate card_charge
    context.updateSalePaymentAmountReadonly(controllerContainer);
    if ($('#sale_payments_container .nested-fields:visible').length == 1){
      let container = $('#sale_payments_container .nested-fields:visible');
      let payment_method_amount_field = $(container).find('.js-payment_method_amount_field');
      let total_without_card_charge_value = subtotal - discount + clientTax;
      total_without_card_charge_value = context.round(total_without_card_charge_value)
      payment_method_amount_field.val(total_without_card_charge_value);
    }

    //--------------------------------------------------------
    //----- Calculate total
    let saleTotalContainer = $(controllerContainer).find('.js-sale_total_container');
    let saleTotalField = $(controllerContainer).find('.js-sale_total_field');
    let total = subtotal - discount + clientTax;
    total = context.round(total);

    saleTotalContainer.html(context.formatToCurrency(total));
    saleTotalField.val(total);
  }




  //----------------------------------------------------------------
  // Calcula la linea de la devolucion
  //----------------------------------------------------------------

  static calculateSaleItem(context, controllerContainer, saleItem){
    
    //--------------------------------------------------------
    //----- Calculate subtotal
    let price = saleItem.find('.js-sale_item_price_field').val();
    let amount = saleItem.find('.js-sale_item_amount_field').val();
    let tax_perc = saleItem.find('.js-sale_item_tax_perc_field').val();
    let sale_channel = $(controllerContainer).find('.js-sale_sale_channel_field').val();

    let price_kind = saleItem.find('.js-sale_item_price_kind_field').val();
    let subtotal_field = saleItem.find('.js-sale_item_subtotal_field');
    
    price = context.parseFloatOrZero(price);
    price = context.round(price);

    amount = context.parseFloatOrZero(amount);
    amount = context.round(amount);
    
    tax_perc = context.parseFloatOrZero(tax_perc);

    let subtotal = 0.0;

    if(price_kind == 'price_kind_perc'){
      // Si precio es porcentual
      let subtotal_fix = $(controllerContainer).find('.js-sale_subtotal_fix_field').val();
      subtotal_fix = context.parseFloatOrZero(subtotal_fix);
      subtotal_fix = context.round(subtotal_fix);

      
      let price_calc = subtotal_fix * (price / 100)

      if(sale_channel == 'channel_a'){
        price_calc = price_calc / (1 + (tax_perc / 100.0))
      }

      subtotal = price_calc * amount;
    }
    else{
      // Si precio es fijo
      subtotal = price * amount;
    }
    
    subtotal = context.round(subtotal);

    subtotal_field.val(subtotal);
    
    //--------------------------------------------------------
    //----- Calculate discount
    let discount_kind = saleItem.find('.js-sale_item_discount_kind_field').val();
    let discount_value = saleItem.find('.js-sale_item_discount_value_field').val();

    discount_value = context.parseFloatOrZero(discount_value);
    discount_value = context.round(discount_value);

    let discount = 0.0;
    
    if (discount_kind == 'discount_kind_perc'){
      // Si descuento es porcentual
      discount = subtotal * (discount_value / 100)
    }
    else{
      // Si descuento es fijo
      discount = discount_value * amount;
    }
    discount = context.round(discount);


    //--------------------------------------------------------
    //----- Calculate total
    let total_field = saleItem.find('.js-sale_item_total_field');
  
    let total = subtotal - discount;
    total = context.round(total);

    total_field.val(total);


    //--------------------------------------------------------
    //----- Calculate tax
    let tax_field = saleItem.find('.js-sale_item_tax_field');
    let tax_profile = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let client_tax_profile = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleInvoiceTypeValue = $(controllerContainer).find('.js-sale_invoice_type_field').val();

    if(
        (
          (sale_channel == 'channel_a') &&
          (tax_profile == 'enroled_responsable') &&
          (client_tax_profile == 'enroled_responsable')
        ) ||
        (
          (saleInvoiceTypeValue == 'credit_note_a') &&
          (sale_channel == 'channel_a') &&
          (tax_profile == 'enroled_responsable') &&
          (client_tax_profile == 'monotax_responsable')
        )
      ){
      let tax = (total * (tax_perc / 100));
      tax = context.round(tax);
      tax_field.val(tax);
    }
    else{
      tax_field.val(0.0);
    }
  }
}
