
export class SaleCalculation {

  //----------------------------------------------------------------
  // Calcula el total de la venta
  //----------------------------------------------------------------


  static calculateTotal(context, controllerContainer){
    
        
    //--------------------------------------------------------
    //----- Calculate subtotal
    let saleSubtotalContainer = $(controllerContainer).find('.js-sale_subtotal_container');
    let saleSubtotalFixField = $(controllerContainer).find('.js-sale_subtotal_fix_field');
    let saleSubtotalPercField = $(controllerContainer).find('.js-sale_subtotal_perc_field');
    let saleSubtotalField = $(controllerContainer).find('.js-sale_subtotal_field');

    let subtotal_fix = 0.0;
    let subtotal_perc = 0.0;


    $('#sale_items_container .nested-fields:visible').each(function(){
      let containerSaleItem = $(this);
      
      let sale_item_price_kind_field = containerSaleItem.find('.js-sale_item_price_kind_field').val();
      let sale_item_tax_field = containerSaleItem.find('.js-sale_item_tax_field');
      let sale_item_total_field = containerSaleItem.find('.js-sale_item_total_field');

      
      let sale_item_tax_amount = context.parseFloatOrZero(sale_item_tax_field.val());
      let sale_item_total_amount = context.parseFloatOrZero(sale_item_total_field.val());

      if(sale_item_price_kind_field == 'price_kind_fix'){
        subtotal_fix += sale_item_total_amount;
        subtotal_fix += sale_item_tax_amount;
      }
    });

    subtotal_fix = context.round(subtotal_fix);
    saleSubtotalFixField.val(subtotal_fix);


    $('#sale_items_container .nested-fields:visible, #nested_sale_item_data').each(function(){
      let containerSaleItem = $(this);
      
      let sale_item_price_kind_field = containerSaleItem.find('.js-sale_item_price_kind_field').val();
      let sale_item_tax_field = containerSaleItem.find('.js-sale_item_tax_field')
      
      if(sale_item_price_kind_field == 'price_kind_perc'){
        context.updateSaleItemTotals(controllerContainer, containerSaleItem);

        let sale_item_tax_amount = context.parseFloatOrZero(sale_item_tax_field.val());

        if(containerSaleItem.attr('id') != 'nested_sale_item_data'){

          let sale_item_total_field = containerSaleItem.find('.js-sale_item_total_field');
          let sale_item_total_amount = context.parseFloatOrZero(sale_item_total_field.val());

          subtotal_perc += sale_item_total_amount
          subtotal_perc += sale_item_tax_amount
        }
      }
    });

    subtotal_perc = context.round(subtotal_perc);
    saleSubtotalPercField.val(subtotal_perc);

    let subtotal = subtotal_fix + subtotal_perc;
    subtotal = context.round(subtotal);

    saleSubtotalContainer.html(context.formatToCurrency(subtotal));
    saleSubtotalField.val(subtotal);

    //--------------------------------------------------------
    //----- Calculate discount
    let discount_kind = $(controllerContainer).find('.js-sale_discount_kind_field').val();
    let discount_value = $(controllerContainer).find('.js-sale_discount_value_field').val();

    let saleDiscountContainer = $(controllerContainer).find('.js-sale_discount_container');
    let saleDiscountField = $(controllerContainer).find('.js-sale_discount_field');

    discount_value = context.parseFloatOrZero(discount_value);

    let discount = 0.0;
    let discount_perc = 0.0;

    if (discount_kind == 'discount_kind_perc'){
      // Si descuento es porcentual
      discount = subtotal * (discount_value / 100);
      discount_perc = discount_value;
    }
    else{
      // Si descuento es fijo
      discount = discount_value;
      discount_perc = discount_value  / subtotal * 100.0;
    }
    discount = context.round(discount);

    saleDiscountContainer.html(context.formatToCurrency(discount));
    saleDiscountField.val(discount);


    //--------------------------------------------------------
    //----- Calculate net and tax
    let saleTaxContainer = $(controllerContainer).find('.js-sale_tax_container');
    let saleTaxField = $(controllerContainer).find('.js-sale_tax_field');
    let saleNetContainer = $(controllerContainer).find('.js-sale_net_container');
    let saleNetField = $(controllerContainer).find('.js-sale_net_field');


    let net = 0.0;
    $('#sale_items_container .nested-fields:visible .js-sale_item_total_field').each(function(){
      net += context.parseFloatOrZero($(this).val());
    });

    let net_discount = net * (discount_perc / 100);
    net_discount = context.round(net_discount);
    net = net - net_discount;
    net = context.round(net);

    let tax = 0.0;
    $('#sale_items_container .nested-fields:visible .js-sale_item_tax_field').each(function(){
      tax += context.parseFloatOrZero($(this).val());
    });

    let tax_discount = tax * (discount_perc / 100);
    tax = tax - tax_discount;
    tax = context.round(tax);


    saleTaxContainer.html(context.formatToCurrency(tax));
    saleTaxField.val(tax);
    saleNetContainer.html(context.formatToCurrency(net));
    saleNetField.val(net);

    //--------------------------------------------------------
    //----- Calculate client_tax
    let saleTaxPercValue = $(controllerContainer).find('.js-sale_tax_perc_field').val();
    let saleClientTaxesContainer = $(controllerContainer).find('.js-sale_client_tax_container');
    let saleClientTaxesField = $(controllerContainer).find('.js-sale_client_tax_field');

    saleTaxPercValue = context.parseFloatOrZero(saleTaxPercValue);


    let saleSaleChannelValue = $(controllerContainer).find('.js-sale_sale_channel_field').val();
    let clientTax = 0.0;

    if(saleSaleChannelValue == 'channel_a'){
      clientTax = net * (saleTaxPercValue / 100);
      clientTax = context.round(clientTax);
    }

    saleClientTaxesContainer.html(context.formatToCurrency(clientTax));
    saleClientTaxesField.val(clientTax);


    //--------------------------------------------------------
    //----- Calculate portion invoice
    let sale_portion_invoice_perc_value = $(controllerContainer).find('.js-sale_portion_invoice_perc_field').val();
    let sale_portion_invoice_total_field = $(controllerContainer).find('.js-sale_portion_invoice_total_field');
    let sale_portion_invoice_tax_field = $(controllerContainer).find('.js-sale_portion_invoice_tax_field');

    sale_portion_invoice_perc_value = context.parseFloatOrZero(sale_portion_invoice_perc_value);

    let sale_portion_invoice_total = 0.0;
    let sale_portion_invoice_tax = 0.0;

    $('#sale_items_container .nested-fields:visible').each(function(){
      let saleItem = $(this);
      let sale_item_tax_perc_field = saleItem.find('.js-sale_item_tax_perc_field')
      let sale_item_subtotal_field = saleItem.find('.js-sale_item_subtotal_field')
      let sale_item_discount_kind_value = saleItem.find('.js-sale_item_discount_kind_field').val();
      let sale_item_discount_value_field = saleItem.find('.js-sale_item_discount_value_field');
      let sale_item_amount_field = saleItem.find('.js-sale_item_amount_field');
          

      let sale_item_amount_value = context.parseFloatOrZero(sale_item_amount_field.val());
      let sale_item_discount_value_value = context.parseFloatOrZero(sale_item_discount_value_field.val());
      let sale_item_tax_perc = context.parseFloatOrZero(sale_item_tax_perc_field.val());
      let sale_item_subtotal = context.parseFloatOrZero(sale_item_subtotal_field.val());

      let sale_item_discount = 0.0;
      
      if (sale_item_discount_kind_value == 'discount_kind_perc'){
        sale_item_discount = sale_item_subtotal * (sale_item_discount_value_value / 100)
      }
      else{
        sale_item_discount = sale_item_discount_value_value * sale_item_amount_value;
      }
    
      let total_discount = (sale_item_subtotal - sale_item_discount) * (discount_perc / 100)
    
      let aux_total_sale_item_subtotal = (sale_item_subtotal - sale_item_discount - total_discount)
      let aux_total_sale_item_tax = ((sale_item_subtotal - sale_item_discount - total_discount) * (sale_item_tax_perc/100))
    
    
      sale_portion_invoice_total += context.round((aux_total_sale_item_subtotal) * (sale_portion_invoice_perc_value / 100.0));
      sale_portion_invoice_tax += context.round((aux_total_sale_item_tax) * (sale_portion_invoice_perc_value / 100.0));
    });
    
    sale_portion_invoice_total_field.val(context.round(sale_portion_invoice_total));
    sale_portion_invoice_tax_field.val(context.round(sale_portion_invoice_tax));



    //--------------------------------------------------------
    //----- Calculate imp/per
    let saleSaleTaxesTotalContainer = $(controllerContainer).find('.js-sale_sale_taxes_total_container');
    let saleSaleTaxesTotalField = $(controllerContainer).find('.js-sale_sale_taxes_total_field');
    let saleTaxTotal = 0.0;
    
    $('#sale_taxes_box_container .nested-fields:visible').each(function(){
      let saleTax = $(this);
      let saleTaxAmount = saleTax.find('.js-sale_tax_amount_field').val();
      let saleTaxValue;


      if(saleSaleChannelValue == 'channel_a'){
        saleTaxValue = ((saleTaxAmount * net) / 100);
      }
      else if ((saleSaleChannelValue == 'channel_b') && (sale_portion_invoice_perc_value != 0.0)) {
        saleTaxValue = ((((saleTaxAmount * net) / 100) * sale_portion_invoice_perc_value) / 100);
      }

      saleTaxTotal += saleTaxValue
    })

    saleSaleTaxesTotalContainer.html(context.formatToCurrency(saleTaxTotal));
    saleSaleTaxesTotalField.val(saleTaxTotal);


    //--------------------------------------------------------
    //----- Calculate card_charge
    context.updateSalePaymentAmountReadonly(controllerContainer);
    if (
        ($('#sale_payments_container .nested-fields:visible').length == 1) &&
        ($('#sale_payments_container .nested-fields:visible').find('.js-payment_method_field').val() != 'credit_note')
      ){
      let container = $('#sale_payments_container .nested-fields:visible');
      let payment_method_amount_field = $(container).find('.js-payment_method_amount_field');
      let total_without_card_charge_value = subtotal - discount + clientTax + sale_portion_invoice_tax + saleTaxTotal;
      total_without_card_charge_value = context.round(total_without_card_charge_value)
      payment_method_amount_field.val(total_without_card_charge_value);
      context.calculateSalePaymentPayChange(controllerContainer, $(container).find('.js-sale_payment_pays_with_field'));
    }

    let total_card_charge_value = 0.0;
    $('#sale_payments_container .nested-fields:visible').each(function(){
      let container = $(this);
      let sale_charge = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_field').val();
      let cardChargeValueField = $(container).find('.js-payment_method_debit_card_credit_card_card_charge_value_field');
      let fee_amount = $(container).find('.js-payment_method_credit_card_data_fee_amount_field').val();
      let feeTotalValueField = $(container).find('.js-payment_method_credit_card_data_fee_total_field');      
      let payment_method_amount = $(container).find('.js-payment_method_amount_field').val();


      sale_charge = context.parseFloatOrZero(sale_charge);
      fee_amount = context.parseFloatOrZero(fee_amount);
      payment_method_amount = context.parseFloatOrZero(payment_method_amount);


      let card_charge_value = (payment_method_amount * (sale_charge / 100.0));
      card_charge_value = context.round(card_charge_value);

      total_card_charge_value += card_charge_value;
      
      cardChargeValueField.val(card_charge_value);
      
      let paymentMethodValue = $(container).find('.js-payment_method_field').val();
      if(paymentMethodValue == 'debit_card'){
        feeTotalValueField.val(0.0);
      }
      else if(paymentMethodValue == 'credit_card'){
        let payment_total = payment_method_amount + card_charge_value;
        if (fee_amount != 0.0){
          let fee_total = (payment_total / fee_amount);
          fee_total = context.round(fee_total);

          feeTotalValueField.val(fee_total);
        } 
        else {
          feeTotalValueField.val(0.0);
        }
      }
    });

    //--------------------------------------------------------
    //----- Calculate total
    let saleTotalContainer = $(controllerContainer).find('.js-sale_total_container');
    let saleTotalField = $(controllerContainer).find('.js-sale_total_field');
    let total = subtotal - discount + clientTax + total_card_charge_value + sale_portion_invoice_tax + saleTaxTotal;
    total = context.round(total);

    saleTotalContainer.html(context.formatToCurrency(total));
    saleTotalField.val(total);
  }




  //----------------------------------------------------------------
  // Calcula la linea de la venta
  //----------------------------------------------------------------

  static calculateSaleItem(context, controllerContainer, saleItem){
    
    //--------------------------------------------------------
    //----- Calculate subtotal
    let price = saleItem.find('.js-sale_item_price_field').val();
    let amount = saleItem.find('.js-sale_item_amount_field').val();
    let tax_perc = saleItem.find('.js-sale_item_tax_perc_field').val();
    let sale_channel = $(controllerContainer).find('.js-sale_sale_channel_field').val();

    let price_kind = saleItem.find('.js-sale_item_price_kind_field').val();
    let subtotal_field = saleItem.find('.js-sale_item_subtotal_field');
    
    price = context.parseFloatOrZero(price);
    price = context.round(price);
    
    amount = context.parseFloatOrZero(amount);
    amount = context.round(amount);
    
    tax_perc = context.parseFloatOrZero(tax_perc);

    let subtotal = 0.0;

    if(price_kind == 'price_kind_perc'){
      // Si precio es porcentual
      let subtotal_fix = $(controllerContainer).find('.js-sale_subtotal_fix_field').val();
      subtotal_fix = context.parseFloatOrZero(subtotal_fix);
      subtotal_fix = context.round(subtotal_fix);

      
      let price_calc = subtotal_fix * (price / 100)

      if(sale_channel == 'channel_a'){
        price_calc = price_calc / (1 + (tax_perc / 100.0))
      }

      subtotal = price_calc * amount;
    }
    else{
      // Si precio es fijo
      subtotal = price * amount;
    }
    
    subtotal = context.round(subtotal);

    subtotal_field.val(subtotal);


    //--------------------------------------------------------
    //----- Calculate discount
    let discount_kind = saleItem.find('.js-sale_item_discount_kind_field').val();
    let discount_value = saleItem.find('.js-sale_item_discount_value_field').val();
    
    discount_value = context.parseFloatOrZero(discount_value);
    discount_value = context.round(discount_value);

    let discount = 0.0;

    if (discount_kind == 'discount_kind_perc'){
      // Si descuento es porcentual
      discount = subtotal * (discount_value / 100)
    }
    else{
      // Si descuento es fijo
      discount = discount_value * amount;
    }
    discount = context.round(discount);


    //--------------------------------------------------------
    //----- Calculate total
    let total_field = saleItem.find('.js-sale_item_total_field');
  
    let total = subtotal - discount;
    total = context.round(total);

    total_field.val(total);

    
    //--------------------------------------------------------
    //----- Calculate tax
    let tax_field = saleItem.find('.js-sale_item_tax_field');
    let tax_profile = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let client_tax_profile = $(controllerContainer).find('.js-sale_client_tax_profile_field').val();
    let saleInvoiceTypeValue = $(controllerContainer).find('.js-sale_invoice_type_field').val();
    
    if(
        (
          (sale_channel == 'channel_a') &&
          (tax_profile == 'enroled_responsable') &&
          (client_tax_profile == 'enroled_responsable')
        ) ||
        (
          (saleInvoiceTypeValue == 'invoice_a') &&
          (sale_channel == 'channel_a') &&
          (tax_profile == 'enroled_responsable') &&
          (client_tax_profile == 'monotax_responsable')
        )
      ){
      let tax = (total * (tax_perc / 100));
      tax = context.round(tax);
      tax_field.val(tax);
    }
    else{
      tax_field.val(0.0);
    }
  }
}

