
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect CashboxPeriodForm controller")
    }
  }

  // Triggerado por el cambio de estado
  changeStatus(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateStatusFields(controllerContainer);
  }


  private

  // Actualiza info del balance:
  updateStatusFields(controllerContainer){
    let _this = this;
    let statusValue = $(controllerContainer).find('.js-status_field').val();

    let startDateField = $(controllerContainer).find('.js-start_date_field');
    let startAmountField = $(controllerContainer).find('.js-start_amount_field');
    let closeFieldsContainer = $(controllerContainer).find('#js-close-fields-container');
    let endDateField = $(controllerContainer).find('.js-end_date_field');
    let endAmountField = $(controllerContainer).find('.js-end_amount_field');

    if(statusValue == 'open'){
      startDateField.removeAttr('readonly');
      startAmountField.removeAttr('readonly');
      closeFieldsContainer.hide();
      endDateField.val('');
      endAmountField.val('');
    } else if(statusValue == 'close'){
      startDateField.attr('readonly', 'readonly');
      startAmountField.attr('readonly', 'readonly');
      endDateField.val(moment().format('DD/MM/YYYY HH:mm'));
      closeFieldsContainer.fadeIn();
    }
  }
}
