
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect CreditNoteForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;

      _this.updateSaleInvoiceTypeField(controllerContainer);
      _this.updateExternalInvoicedData(controllerContainer);
      _this.updateTaxPercData(controllerContainer);
    }
  }

  changeAmount(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateTotal(controllerContainer);
  }

  changeTotal(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateAmount(controllerContainer);
  }

  changeTaxPerc(){
    let _this = this;
    let controllerContainer = _this.context.element;

    let amount_field = $(controllerContainer).find('.js-credit_note_amount_field');

    if (amount_field.attr('readonly') == undefined){
      _this.updateTotal(controllerContainer);
    }
    else{
      _this.updateAmount(controllerContainer);
    }
  }

  // Triggerado por el cambio de cliente
  changeClient(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateClientData(controllerContainer);
  }


  // Triggerado por el cambio el canal
  changeCreditNoteChannel(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setSaleInvoiceTypeField(controllerContainer);
  }



  // Triggerado por el cambio el tipo de factura
  changeInvoiceType(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setTaxPercData(controllerContainer);
  }


  changeExternalInvoiced(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let option = $(event.currentTarget);

    event.preventDefault();
    _this.setExternalInvoiced(controllerContainer, option);
  }

  private

  updateAmount(controllerContainer){
    let _this = this;
    let amount_field = $(controllerContainer).find('.js-credit_note_amount_field');
    let total_value = $(controllerContainer).find('.js-credit_note_total_field').val();
    let tax_perc_value = $(controllerContainer).find('.js-credit_note_tax_perc_field').val();
    let invoice_type_value = $(controllerContainer).find('.js-credit_note_invoice_type_field').val();
    let amount_value = 0.0;

    tax_perc_value = _this.parseFloatOrZero(tax_perc_value);
    total_value = _this.parseFloatOrZero(total_value);

    if (invoice_type_value == 'credit_note_a'){
      if (tax_perc_value == 0.0){
        amount_value = total_value;
      }
      else{
        amount_value = total_value / (1 + (tax_perc_value / 100.0));
      }
    }
    else {
      amount_value = total_value;
    }

    amount_field.val(_this.round(amount_value));
  }

  updateTotal(controllerContainer){
    let _this = this;
    let amount_value = $(controllerContainer).find('.js-credit_note_amount_field').val();
    let total_field = $(controllerContainer).find('.js-credit_note_total_field');
    let tax_perc_value = $(controllerContainer).find('.js-credit_note_tax_perc_field').val();
    let invoice_type_value = $(controllerContainer).find('.js-credit_note_invoice_type_field').val();
    let total_value = 0.0;

    tax_perc_value = _this.parseFloatOrZero(tax_perc_value);
    amount_value = _this.parseFloatOrZero(amount_value);

    if (invoice_type_value == 'credit_note_a'){
      if (tax_perc_value == 0.0){
        total_value = amount_value;
      }
      else{
        total_value = amount_value * (1+(tax_perc_value / 100));
      }
    }
    else {
      total_value = amount_value;
    }

    total_field.val(_this.round(total_value));
  }

  setTaxPercData(controllerContainer){
    let _this = this;
    let invoice_type_value = $(controllerContainer).find('.js-credit_note_invoice_type_field').val();
    let credit_note_tax_perc_field = $(controllerContainer).find('.js-credit_note_tax_perc_field');

    if(
      (invoice_type_value == 'credit_note_c') ||
      (invoice_type_value == 'credit_note_other')
    ){
      credit_note_tax_perc_field.val('0.0');
    }
    _this.updateTotal(controllerContainer);
    _this.updateTaxPercData(controllerContainer);
  }

  updateTaxPercData(controllerContainer){
    let invoice_type_value = $(controllerContainer).find('.js-credit_note_invoice_type_field').val();
    let credit_note_tax_perc_container = $(controllerContainer).find('.js-credit_note_tax_perc_container');
    let credit_note_total_container = $(controllerContainer).find('.js-credit_note_total_container');
    let amount_field = $(controllerContainer).find('.js-credit_note_amount_field');
    let total_field = $(controllerContainer).find('.js-credit_note_total_field');

    if(
      (invoice_type_value == 'credit_note_a') ||
      (invoice_type_value == 'credit_note_b')
    ){
      credit_note_tax_perc_container.show();
    }
    else{
      credit_note_tax_perc_container.hide();
    }
    
    if (invoice_type_value == 'credit_note_a'){
      amount_field.attr('readonly', 'readonly');
      total_field.removeAttr('readonly');      
      credit_note_total_container.show();
    }
    else{
      amount_field.removeAttr('readonly');      
      total_field.attr('readonly', 'readonly');
      credit_note_total_container.hide();
    }
  }

  setExternalInvoiced(controllerContainer, option){
    let _this = this;
    let creditNoteExternalInvoicedField = $(controllerContainer).find('.js-credit_note_external_invoiced_field');
    
    let value = $(option).attr('href');
    creditNoteExternalInvoicedField.val(value);
    _this.updateExternalInvoicedData(controllerContainer);
  }

  updateExternalInvoicedData(controllerContainer){
    let creditNoteExternalInvoicedValue = $(controllerContainer).find('.js-credit_note_external_invoiced_field').val();
    let optionSelected = $(controllerContainer).find('.js-credit_note_external_invoiced_true'+creditNoteExternalInvoicedValue);
    let creditNoteExternalInvoicedContainer = $(controllerContainer).find('.js-credit_note_external_invoiced_container');
    let creditNoteInvoiceNumberField = $(controllerContainer).find('.js-credit_note_invoice_number_field');
    
    let icon = $(optionSelected).find('.icon').html();
    let text = $(optionSelected).find('.text').html();

    creditNoteExternalInvoicedContainer.html(icon);
    creditNoteInvoiceNumberField.attr('placeholder', text);

    if (creditNoteExternalInvoicedValue == 'true'){
      creditNoteInvoiceNumberField.attr('readonly','readonly');
      creditNoteInvoiceNumberField.val('');
    } else {
      creditNoteInvoiceNumberField.removeAttr('readonly');
    }
  }


  // Actualiza info del cliente:
  updateClientData(controllerContainer){
    let _this = this;
    let clientIdField = $(controllerContainer).find('.js-client_id_field');
    let clientId = clientIdField.select2('val');
    let creditNoteClientTaxProfileField = $(controllerContainer).find('.js-credit_note_client_tax_profile_field');
    let creditNoteClientDniField = $(controllerContainer).find('.js-credit_note_client_dni_field');
    let creditNoteClientCuitField = $(controllerContainer).find('.js-credit_note_client_cuit_field');
    let creditNoteClientAddressField = $(controllerContainer).find('.js-credit_note_client_address_field');
    
    let creditNoteCreditNoteChannelField = $(controllerContainer).find('.js-credit_note_credit_note_channel_field');

    let productsModalButton = $(controllerContainer).find('#products_modal_button');

    let url = $(clientIdField).data('url-two');
    
    if(clientId != ''){
      url = url.replace('/the_id', '/'+clientId);
      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let taxProfile = data.tax_profile;
        let cuit = data.cuit;
        let dni = data.dni;
        let address = data.address;
        let saleChannel = data.sale_channel;


        //Seteo perfil impositivo y tipo de cliente
        creditNoteClientTaxProfileField.val(taxProfile);
        creditNoteClientCuitField.val(cuit);
        creditNoteClientDniField.val(dni);
        creditNoteClientAddressField.val(address);

        //Seteo medio de pago y canal por defecto del cliente
        creditNoteCreditNoteChannelField.val(saleChannel);
        creditNoteCreditNoteChannelField.trigger('change');

        _this.setSaleInvoiceTypeField(controllerContainer);
      });   
      $.ajaxSetup({async: true});   
    }
    else{
      productsModalButton.hide();
    }
  }

  setSaleInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let creditNoteClientTaxProfileValue = $(controllerContainer).find('.js-credit_note_client_tax_profile_field').val();
    let creditNoteInvoiceTypeField = $(controllerContainer).find('.js-credit_note_invoice_type_field');
    let creditNoteCreditNoteChannelValue = $(controllerContainer).find('.js-credit_note_credit_note_channel_field').val();
    let creditNoteExternalInvoicedFalse = $(controllerContainer).find('.js-credit_note_external_invoiced_truefalse');
    let creditNoteExternalInvoicedTrue = $(controllerContainer).find('.js-credit_note_external_invoiced_truetrue');  
    let salePointDemantecaActiveValue = $(controllerContainer).find('.js-sale_point_demanteca_active_field').val();

    _this.updateSaleInvoiceTypeField(controllerContainer);

    if(creditNoteCreditNoteChannelValue == 'channel_b'){

      _this.setExternalInvoiced(controllerContainer, creditNoteExternalInvoicedFalse);
      creditNoteInvoiceTypeField.val('credit_note_other');
    }
    else if (creditNoteCreditNoteChannelValue == 'channel_a'){

      if(salePointDemantecaActiveValue == 'true'){
        _this.setExternalInvoiced(controllerContainer, creditNoteExternalInvoicedTrue);
      } else {
        _this.setExternalInvoiced(controllerContainer, creditNoteExternalInvoicedFalse);
      }

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(creditNoteClientTaxProfileValue == 'enroled_responsable'){
          creditNoteInvoiceTypeField.val('credit_note_c');
        }
        else if (creditNoteClientTaxProfileValue == 'monotax_responsable'){
          creditNoteInvoiceTypeField.val('credit_note_c');
        }
        else if (creditNoteClientTaxProfileValue == 'end_consumer'){
          creditNoteInvoiceTypeField.val('credit_note_c');
        }
        else if (creditNoteClientTaxProfileValue == 'exempted_vat'){
          creditNoteInvoiceTypeField.val('credit_note_c');
        }
        else{
          creditNoteInvoiceTypeField.val('');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(creditNoteClientTaxProfileValue == 'enroled_responsable'){
          creditNoteInvoiceTypeField.val('credit_note_a');
        }
        else if (creditNoteClientTaxProfileValue == 'monotax_responsable'){
          creditNoteInvoiceTypeField.val('credit_note_b');
        }
        else if (creditNoteClientTaxProfileValue == 'end_consumer'){
          creditNoteInvoiceTypeField.val('credit_note_b');
        }
        else if (creditNoteClientTaxProfileValue == 'exempted_vat'){
          creditNoteInvoiceTypeField.val('credit_note_b');
        }
        else{
          creditNoteInvoiceTypeField.val('');
        }
      }
    }
    creditNoteInvoiceTypeField.trigger('select2:select');
  }

  updateSaleInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let creditNoteClientTaxProfileValue = $(controllerContainer).find('.js-credit_note_client_tax_profile_field').val();
    let creditNoteInvoiceTypeField = $(controllerContainer).find('.js-credit_note_invoice_type_field');
    let creditNoteCreditNoteChannelValue = $(controllerContainer).find('.js-credit_note_credit_note_channel_field').val();
    let inputGroupPrepend = $(controllerContainer).find('.js-credit_note_external_invoiced_container').closest('.input-group-prepend');
    let salePointDemantecaActiveValue = $(controllerContainer).find('.js-sale_point_demanteca_active_field').val();

    if(creditNoteCreditNoteChannelValue == 'channel_b'){ 
      creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
      creditNoteInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
      creditNoteInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
      creditNoteInvoiceTypeField.find("option[value='credit_note_other']").removeAttr('disabled');
      inputGroupPrepend.hide();
    }
    else if (creditNoteCreditNoteChannelValue == 'channel_a'){
      creditNoteInvoiceTypeField.find("option[value='credit_note_other']").attr('disabled','disabled');
      if(salePointDemantecaActiveValue == 'true'){
        inputGroupPrepend.show();
      } else {
        inputGroupPrepend.hide();
      }

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(creditNoteClientTaxProfileValue == 'enroled_responsable'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
        else if (creditNoteClientTaxProfileValue == 'monotax_responsable'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
        else if (creditNoteClientTaxProfileValue == 'end_consumer'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
        else if (creditNoteClientTaxProfileValue == 'exempted_vat'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").removeAttr('disabled');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(creditNoteClientTaxProfileValue == 'enroled_responsable'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").removeAttr('disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
        else if (creditNoteClientTaxProfileValue == 'monotax_responsable'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").removeAttr('disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").removeAttr('disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
        else if (creditNoteClientTaxProfileValue == 'end_consumer'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").removeAttr('disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
        else if (creditNoteClientTaxProfileValue == 'exempted_vat'){
          creditNoteInvoiceTypeField.find("option[value='credit_note_a']").attr('disabled','disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_b']").removeAttr('disabled');
          creditNoteInvoiceTypeField.find("option[value='credit_note_c']").attr('disabled','disabled');
        }
      }
    }

    creditNoteInvoiceTypeField.trigger('select2:select');
  }
}
