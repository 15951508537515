import { Modals } from '../lib/modals.js';

export class MultiSelect {
  
  initialize() {
    $('.js-multiple_select').off('click');
    $('.js-multiple_select').on('click', function() {
      let modal_content = $(this).data('content');
      let modal_size = $(this).data('modal-size');

      let container = $(this).closest('.multiple-select-container');
      let select = container.find('select.multiple-select-input');
      let selected_array_values = select.select2('val');
      
      Modals.modalShow();
      Modals.modalSize(modal_size);
      Modals.modalFill(modal_content);
      
      let modal = $('.js-multiple-select-modal');

      selected_array_values.forEach(function(value) { 
        modal.find('.multiple-select-check-' + value).prop('checked', true);
      });


      
      modal.find('.js-sale_point_kind_container').each(function() {
        if ($(this).find('.multiple-select-check').length == $(this).find('.multiple-select-check:checked').length){
          let all_check = $(this).data('all-check');
          modal.find('input[name="'+all_check+'"]').prop('checked', true);
        }
      });

      modal.find('.js-multiple-select-search').on('input', function(){
        let criteria = $(this).val();
        if((criteria == '') || (criteria == undefined)){
          modal.find('.js-multiple-select-item-container').show();
        }
        else{
          modal.find('.js-multiple-select-item-container').hide();
          modal.find(".js-multiple-select-text[data-text*='" + criteria.toLowerCase() + "']").closest('.js-multiple-select-item-container').show(); 
        }
      });

      modal.find('.multiple-select-check').on('change', function() {
        let id = $(this).val();
        let checked = $(this).is(':checked');
  
        let i_value = selected_array_values.indexOf(id);
        if (checked == true){
          if (i_value === -1) {
            selected_array_values.push(id);
            select.val(selected_array_values);
            select.trigger('change');
          }
        }
        if (checked == false){
          if (i_value !== -1) {
            selected_array_values.splice(i_value, 1);
            select.val(selected_array_values);
            select.trigger('change');
          }
        }
      });
      
      modal.find('.multiple-select-check-all').on('change', function() {
        let value = $(this).val();
        let checked = $(this).is(':checked');

        if (checked == true){
          modal.find(value).find('.multiple-select-check').prop('checked', true).trigger('change');
        }
        if (checked == false){
          modal.find(value).find('.multiple-select-check').prop('checked', false).trigger('change');
        }
      });


    });

  }
}

