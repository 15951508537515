import 'moment/dist/locale/es'
import './date_range_picker.js'

export class Daterangepicker {
  
  initialize() {
    $('.js-daterangepicker').each(function(){
      var element = $(this);
      var { start, end } = getRangeStartEnd($(element).find('input').val());

      element.daterangepicker({
        timePicker: true,
        timePicker24Hour: true,
        showISOWeekNumbers: false,
        startDate: start,
        endDate: end,
        ranges: {
            'Hoy': [moment().startOf('day'), moment().endOf('day')],
            'Ayer': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
            'Mañana': [moment().add(1, 'days').startOf('day'), moment().add(1, 'days').endOf('day')],
            'Ultimos 7 días': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Ultimos 30 días': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
            'Mes actual': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
            'Mes anterior': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')]
        },
        "locale": {
          "format": "DD/MM/YYYY HH:mm",
          "separator": " - ",
          "applyLabel": "Aplicar",
          "cancelLabel": "Cancelar",
          "fromLabel": "Desde",
          "toLabel": "Hasta",
          "customRangeLabel": "Custom",
          "weekLabel": "S",
          "daysOfWeek": [
              "Do",
              "Lu",
              "Ma",
              "Mi",
              "Ju",
              "Vi",
              "Sa"
          ],
          "monthNames": [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
          ],
          "firstDay": 1
        },
      }, function(start, end) {
        $(element).find('input').val(getRangeString(start, end));
      });
    });


    function getRangeStartEnd(string){
      var start, end;
 
      if (string == '') {
        start = moment().subtract(1, 'days').startOf('day');
        end = moment().add(1, 'days').endOf('day');

      } else if (string == 'Hoy') {
        start = moment().startOf('day');
        end = moment().endOf('day');

      } else if (string == 'Ayer') {
        start = moment().subtract(1, 'days').startOf('day');
        end = moment().subtract(1, 'days').endOf('day');

      } else if (string == 'Mañana') {
        start = moment().add(1, 'days').startOf('day');
        end = moment().add(1, 'days').endOf('day');

      } else if (string == 'Ultimos 7 días') {
        start = moment().subtract(6, 'days').startOf('day');
        end = moment().endOf('day');

      } else if (string == 'Ultimos 30 días') {
        start = moment().subtract(29, 'days').startOf('day');
        end = moment().endOf('day');
        
      } else if (string == 'Mes actual') {
        start = moment().startOf('month').startOf('day');
        end = moment().endOf('month').endOf('day');

      } else if (string == 'Mes anterior') {
        start = moment().subtract(1, 'month').startOf('month').startOf('day');
        end = moment().subtract(1, 'month').endOf('month').endOf('day');
        
      } else {
        var string_splited = string.split(' - ');
        start = moment(string_splited[0], 'DD/MM/YYYY HH:mm')
        end = moment(string_splited[1], 'DD/MM/YYYY HH:mm')
      }
      return {start, end};
    }

    function getRangeString(start, end){
      if ((start.format('DD/MM/YYYY HH:mm') == moment().startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Hoy'
      } else if ((start.format('DD/MM/YYYY HH:mm') == moment().subtract(1, 'days').startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().subtract(1, 'days').endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Ayer'
      } else if ((start.format('DD/MM/YYYY HH:mm') == moment().add(1, 'days').startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().add(1, 'days').endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Mañana'
      } else if ((start.format('DD/MM/YYYY HH:mm') == moment().subtract(6, 'days').startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Ultimos 7 días'
      } else if ((start.format('DD/MM/YYYY HH:mm') == moment().subtract(29, 'days').startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Ultimos 30 días'
      } else if ((start.format('DD/MM/YYYY HH:mm') == moment().startOf('month').startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().endOf('month').endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Mes actual'
      } else if ((start.format('DD/MM/YYYY HH:mm') == moment().subtract(1, 'month').startOf('month').startOf('day').format('DD/MM/YYYY HH:mm')) && (end.format('DD/MM/YYYY HH:mm') == moment().subtract(1, 'month').endOf('month').endOf('day').format('DD/MM/YYYY HH:mm'))) {
        return 'Mes anterior'
      } else {
        return start.format('DD/MM/YYYY HH:mm') + ' - ' + end.format('DD/MM/YYYY HH:mm')
      }
    }
    


  }
}
