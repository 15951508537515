
import  ApplicationController from "../application_controller.js"

let time = 0;

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect ProductsStock controller")
      $('#filter_query').focus()
    }
  }

  // TO DO: Optimizar para que no se prendan fuego los bosques de california (Elastic Search)
  handleFilterInput(event){
    let target = $(event.currentTarget);
    let form = target.closest('form');

    clearTimeout(time);
    time = setTimeout(function() {
      form.find('button').trigger('click')
    }, 500);
  }
}
