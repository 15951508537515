
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SalePointForm controller")
    }
  }

  changeDemantecaActive(){
    let _this = this;
    let salePointDemantecaActive = $('#sale_point_demanteca_active');

    if (salePointDemantecaActive.is(':checked')) {
      $('#js-demanteca_container').show();
    } else {
      $('#js-demanteca_container').hide();
    }
  }

  changeKind(e){
    if(($('#sale_point_kind').val() == 'subsidiary') || ($('#sale_point_kind').val() == 'factory')){

      if(confirm('Cargar informacion impositiva de la empresa?')){
        let companyTaxProfileValue = $('.js-company_tax_profile_field').val();
        let companyCuitValue = $('.js-company_cuit_field').val();
        let companyBankValue = $('.js-company_bank_field').val();
        let companyCbuValue = $('.js-company_cbu_field').val();
  
        $('#sale_point_tax_profile_monotax_responsable').prop('checked', false);
        $('#sale_point_tax_profile_enroled_responsable').prop('checked', false);
        $('#sale_point_tax_profile_'+companyTaxProfileValue).prop('checked', true);
        $('#sale_point_cuit').val(companyCuitValue);
        $('#sale_point_bank').val(companyBankValue);
        $('#sale_point_cbu').val(companyCbuValue);
      }
    }
  }
}

