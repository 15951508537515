
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect ClientPaymentForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;

      $(controllerContainer).on('input', '#client_payment_amount', function() {
        _this.updatePaymentTotals(controllerContainer);
      });
    }
  }


  // Triggerado por el cambio de medio de pago 
  changePaymentMethod(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePaymentMethodData(controllerContainer);
    _this.updatePaymentTotals(controllerContainer);
  }

  // Triggerado por el cambio de cliente
  changeClient(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateClientData(controllerContainer);
  }

  // Triggerado por el cambio de tipo
  changeKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateBalanceBox(controllerContainer);
  }

  // Triggerado por el el input de un monto de imputacion
  changeImputationAmount(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateAmount(controllerContainer);
  }

  // Triggerado por el cambio de tarjeta
  changeCard(){
    let _this = this;
    let controllerContainer = _this.context.element;
    let bankPromoIdField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_bank_promo_id_field');
    let feeAmountField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_amount_field');
    let cardChargeField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_field');
    let cardChargeValueField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_value_field');
    let totalAmountValueField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_total_amount_field');
    let feeTotalValueField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_total_field');

    bankPromoIdField.val('').trigger('change');
    feeAmountField.val('')
    feeAmountField.html('')
    feeAmountField.trigger('change');
    cardChargeField.val(0.0);
    cardChargeValueField.val(0.0);
    totalAmountValueField.val(0.0);
    feeTotalValueField.val(0.0);

    _this.updateCardAndBankPromoData(controllerContainer, 'card');
    _this.updatePaymentTotals(controllerContainer);
  }

  // Triggerado por el cambio de promo
  changeBankPromo(){
    let _this = this;
    let controllerContainer = _this.context.element;
    let feeAmountField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_amount_field');
    let cardChargeField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_field');
    let cardChargeValueField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_value_field');
    let totalAmountValueField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_total_amount_field');
    let feeTotalValueField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_total_field');

    feeAmountField.val('')
    feeAmountField.html('')
    feeAmountField.trigger('change');
    cardChargeField.val(0.0);
    cardChargeValueField.val(0.0);
    totalAmountValueField.val(0.0);
    feeTotalValueField.val(0.0);

    _this.updateCardAndBankPromoData(controllerContainer, 'bank_promo');
    _this.updatePaymentTotals(controllerContainer);
  }

  // Triggerado por el cambio de cuota
  changeFeeAmount(){
    let _this = this;
    let controllerContainer = _this.context.element;

    let feeAmountField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_amount_field');
    let fee_sale_charge = feeAmountField.find("option:selected").data('sale-charge');

    _this.updateCardChargeValue(controllerContainer, fee_sale_charge);
    _this.updatePaymentTotals(controllerContainer);
  }
  
  private

  // Actualiza de recargo
  updateCardChargeValue(controllerContainer, sale_charge){
    let cardChargeField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_field');
    
    sale_charge = parseFloat(sale_charge);
    sale_charge = (isNaN(sale_charge)) ? 0.0 : sale_charge;

    cardChargeField.val(sale_charge);
  }

  // Actualiza info de la tarjeta o la promo:
  updateCardAndBankPromoData(controllerContainer, kind){
    let _this = this;
    let cardIdField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_id_field');
    let cardId = cardIdField.select2('val');
    let bankPromoIdField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_bank_promo_id_field');
    let bankPromoId = bankPromoIdField.select2('val');
    let url;

    let paymentMethodValue = $(controllerContainer).find('.js-payment_method_field').val();
    let feeAmountField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_amount_field');


    if((bankPromoId != '') && (bankPromoId != undefined)){
      url = $(bankPromoIdField).data('url-two');

      url = url.replace('/the_id', '/'+bankPromoId);
      $.getJSON(url, function(data) {

        if(paymentMethodValue == 'debit_card'){
          let debit_card_sale_charge = data.debit_card_sale_charge;
          _this.updateCardChargeValue(controllerContainer, debit_card_sale_charge);
          _this.updatePaymentTotals(controllerContainer);
        }
        else if(paymentMethodValue == 'credit_card'){
          let bank_promo_fees = data.bank_promo_fees;

          let blank_opt = $("<option>").val('').text('Seleccionar');
          feeAmountField.append(blank_opt);
          bank_promo_fees.forEach(function(bank_promo_fee) {
            let fee_sale_charge = bank_promo_fee.sale_charge;
            fee_sale_charge = parseFloat(fee_sale_charge);
            fee_sale_charge = (isNaN(fee_sale_charge)) ? 0.0 : fee_sale_charge;
             
            let opt = $("<option>");
            opt.val(bank_promo_fee.amount)
            opt.text(bank_promo_fee.amount_decorated);
            opt.data('sale-charge', fee_sale_charge);
            feeAmountField.append(opt);
          });
          feeAmountField.trigger('change');
        }
      });
    }
    else if((cardId != '') && (cardId != undefined)){
      url = $(cardIdField).data('url-two');

      url = url.replace('/the_id', '/'+cardId);
      $.getJSON(url, function(data) {

        let first_bank_promo = data.first_bank_promo;
        if((first_bank_promo != undefined) && (kind == 'card')){
          let first_bank_promo_opt = $("<option>");
          first_bank_promo_opt.val(first_bank_promo.id)
          first_bank_promo_opt.text(first_bank_promo.name);
          $(bankPromoIdField).append(first_bank_promo_opt).trigger('select2:select');
        }
        else{
          if(paymentMethodValue == 'debit_card'){
            let debit_card_sale_charge = data.debit_card_sale_charge;
            _this.updateCardChargeValue(controllerContainer, debit_card_sale_charge);
            bankPromoIdField.val('').trigger('change');
            _this.updatePaymentTotals(controllerContainer);
          }
          else if(paymentMethodValue == 'credit_card'){
            let card_fees = data.card_fees;
  
            let blank_opt = $("<option>").val('').text('Seleccionar');
            feeAmountField.append(blank_opt);
            card_fees.forEach(function(card_fee) { 
              let fee_sale_charge = card_fee.sale_charge;
              fee_sale_charge = parseFloat(fee_sale_charge);
              fee_sale_charge = (isNaN(fee_sale_charge)) ? 0.0 : fee_sale_charge;
              
              let opt = $("<option>");
              opt.val(card_fee.amount)
              opt.text(card_fee.amount_decorated);
              opt.data('sale-charge', fee_sale_charge);
              feeAmountField.append(opt);
           });
  
           feeAmountField.trigger('change');
          }
        }
      });
    }
  }

  // Actualiza info del cliente:
  updateClientData(controllerContainer){
    let _this = this;
    let clientIdField = $(controllerContainer).find('.js-client_id_field');
    let clientId = clientIdField.select2('val');
    let url = $(clientIdField).data('url-two');
    
    let clientBalanceBox = $(controllerContainer).find('#client_balance_box');
    let clientBalanceContainer = $(controllerContainer).find('.js-client_balance_container');
    let globalBalanceBox = $(controllerContainer).find('#js-global-balance');

    if(clientId != ''){
      url = url.replace('/the_id', '/'+clientId);
      $.getJSON(url, function(data) {
        let balance = data.balance_ars;
        balance = parseFloat(balance);
        balance = (isNaN(balance)) ? 0.0 : balance;

        clientBalanceContainer.html(_this.formatToCurrency(balance));

        globalBalanceBox.find('.form-group').hide();
        $('#client_payment_imputations_container .list-group-item').hide();
        $('#client_payment_imputations_container .js-client_payment_imputation_amount').val(0);
        _this.updateAmount(controllerContainer);

        $(data.balance_ars_array).each(function(){
          balance = parseFloat(this[1]);
          balance = (isNaN(balance)) ? 0.0 : balance;
          $(controllerContainer).find('.js-client_balance_container_sale_point_'+this[0].id).html(_this.formatToCurrency(balance))
          $(controllerContainer).find('.js-client_balance_container_sale_point_'+this[0].id).closest('.form-group').show();
          $(controllerContainer).find('#js-client-payment-imputation-container-'+this[0].id).show();        
        });

        clientBalanceBox.fadeIn();
      });
    }
  }

  updatePaymentTotals(controllerContainer){
    let _this = this;

    //----- Calculate card_charge
    let sale_charge = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_field').val();
    let cardChargeValueField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_value_field');
    let totalAmountValueField = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_total_amount_field');
    let feeTotalValueField = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_total_field');
    let fee_amount = $(controllerContainer).find('.js-payment_method_credit_card_data_fee_amount_field').val();
    let amount_value = $(controllerContainer).find('#client_payment_amount').val();

    sale_charge = parseFloat(sale_charge);
    sale_charge = (isNaN(sale_charge)) ? 0.0 : sale_charge;

    fee_amount = parseFloat(fee_amount);
    fee_amount = (isNaN(fee_amount)) ? 0.0 : fee_amount;

    amount_value = parseFloat(amount_value);
    amount_value = (isNaN(amount_value)) ? 0.0 : amount_value;

    let card_charge_value = (amount_value * (sale_charge / 100.0));
    let total_amount = amount_value + card_charge_value;
    
    cardChargeValueField.val(card_charge_value.toFixed(2));
    totalAmountValueField.val(total_amount.toFixed(2))
    
    let paymentMethodValue = $(controllerContainer).find('.js-payment_method_field').val();
    if(paymentMethodValue == 'debit_card'){
      feeTotalValueField.val(0.0);
    }
    else if(paymentMethodValue == 'credit_card'){
      if (fee_amount != 0.0){
        let fee_total = (total_amount / fee_amount);
        feeTotalValueField.val(fee_total.toFixed(2));
      } 
      else {
        feeTotalValueField.val(0.0);
      }
    }
  }



  updateAmount(controllerContainer){
    let _this = this;

    let clientPaymentAmountField = $(controllerContainer).find('#client_payment_amount');

    let totalAmount = 0.0;
    $('#js-client_payment_imputations_container .js-client_payment_imputation_amount').each(function(){
      let amount = parseFloat($(this).val());
      totalAmount += (isNaN(amount)) ? 0.0 : amount;
    });

    clientPaymentAmountField.val(totalAmount.toFixed(2));
    clientPaymentAmountField.trigger('input');
  }



  // Actualiza info del balance:
  updateBalanceBox(controllerContainer){
    let _this = this;
    let kindValue = $(controllerContainer).find('.js-kind_field').val();

    let clientPaymentAmountField = $(controllerContainer).find('#client_payment_amount');
    let localBalanceBox = $(controllerContainer).find('#js-local-balance');
    let globalBalanceBox = $(controllerContainer).find('#js-global-balance');
    let clientPaymentImputationsContainer = $(controllerContainer).find('#js-client_payment_imputations_container');

    if(kindValue == 'local'){
      clientPaymentAmountField.removeAttr('readonly');
      globalBalanceBox.hide();
      clientPaymentImputationsContainer.hide();
      $(controllerContainer).find('.js-client_payment_imputation_amount').val(0);
      clientPaymentAmountField.val(0);
      clientPaymentAmountField.trigger('input');
      localBalanceBox.fadeIn();
    } else if(kindValue == 'global'){
      clientPaymentAmountField.attr('readonly', 'readonly');
      clientPaymentAmountField.val(0);
      clientPaymentAmountField.trigger('input');
      localBalanceBox.hide();
      clientPaymentImputationsContainer.fadeIn();
      globalBalanceBox.fadeIn();
    }
  }


  // Actualiza info del pago:
  updatePaymentMethodData(controllerContainer){
    let _this = this;
    let paymentMethodValue = $(controllerContainer).find('.js-payment_method_field').val();
    let paymentMethodCashData = $(controllerContainer).find('.payment_method_cash_data');
    let paymentMethodBankTransferData = $(controllerContainer).find('.payment_method_bank_transfer_data');
    let paymentMethodOtherData = $(controllerContainer).find('.payment_method_other_data');
    let paymentMethodCheckData = $(controllerContainer).find('.payment_method_check_data');
    let paymentMethodDebitCardCreditCardData = $(controllerContainer).find('.payment_method_debit_card_credit_card_data');
    let paymentMethodDebitCardData = $(controllerContainer).find('.payment_method_debit_card_data');
    let paymentMethodCreditCardData = $(controllerContainer).find('.payment_method_credit_card_data');

    let card_id_container = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_id_field').closest('.payment_method_debit_card_credit_card_data');
    let bank_promo_id_container = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_bank_promo_id_field').closest('.payment_method_debit_card_credit_card_data');
    let card_charge_container = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_card_charge_field').closest('.payment_method_debit_card_credit_card_data');
    let total_amount_container = $(controllerContainer).find('.js-payment_method_debit_card_credit_card_total_amount_field').closest('.payment_method_debit_card_credit_card_data');

    if (paymentMethodValue == 'cash'){
      paymentMethodCashData.show();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'bank_transfer'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();      
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'other'){
      paymentMethodOtherData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodCheckData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();      
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'debit_card'){
      card_id_container.removeClass('col-sm-4').addClass('col-sm-6');
      bank_promo_id_container.removeClass('col-sm-4').addClass('col-sm-6');
      card_charge_container.removeClass('col-sm-4').addClass('col-sm-6');
      total_amount_container.removeClass('col-sm-4').addClass('col-sm-6');

      paymentMethodDebitCardCreditCardData.show();
      paymentMethodDebitCardData.show();
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCreditCardData.hide();      
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'credit_card'){
      card_id_container.removeClass('col-sm-6').addClass('col-sm-4');
      bank_promo_id_container.removeClass('col-sm-6').addClass('col-sm-4');
      card_charge_container.removeClass('col-sm-6').addClass('col-sm-4');
      total_amount_container.removeClass('col-sm-6').addClass('col-sm-4');

      paymentMethodDebitCardCreditCardData.show();
      paymentMethodCreditCardData.show();      
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('1');
    }
    else if(paymentMethodValue == 'check'){
      paymentMethodCheckData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();      
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('0');
    }
    else{
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodDebitCardCreditCardData.hide();
      paymentMethodDebitCardData.hide();
      paymentMethodCreditCardData.hide();      
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_bank_promo_id_field').val('').trigger('change');
      $('.js-payment_method_debit_card_credit_card_card_charge_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_card_charge_value_field').val(0.0);
      $('.js-payment_method_debit_card_credit_card_total_amount_field').val(0.0);
      $('.js-payment_method_credit_card_data_fee_amount_field').val('').html('').trigger('change');
      $('.js-payment_method_credit_card_data_fee_total_field').val(0.0);
      $('.js-payment_method_check_destroy_field').val('1');
    }
  }

}
