
import  ApplicationController from "../application_controller.js"
import { FloorPlan } from "../../lib/floor_plans_form/floor-plan.js";

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect FloorPlanForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;
      let select = $('#floor_plan_aspect_ratio');

      _this.floorPlan =_this.initFloorPlan(select.val());
      $('#canvas-container').data('floor-plan', _this.floorPlan);
    }
  }

  changeColor(num, color){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.floorPlan.changeColor(num, color);
  }

  changeAspectRatio(){
    let _this = this;
    let controllerContainer = _this.context.element;
    let select = $('#floor_plan_aspect_ratio');

    _this.floorPlan.changeAspectRatio(select.val());
  }

  initFloorPlan(aspectRatio) {
    let floorPlan = new FloorPlan();
    floorPlan.initialize(aspectRatio);
    return floorPlan;
  }
}
