
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SalePromoForm controller")

      let _this = this;
      let controllerContainer = this.context.element;

      _this.updateKindFields(controllerContainer);
      _this.updateAppliesToFields(controllerContainer);
      _this.updateDateLimitFields(controllerContainer);
    }
  }

  changeDateLimit(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateDateLimitFields(controllerContainer);
  }

  changeKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateKindFields(controllerContainer);
  }

  changeAppliesTo(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateAppliesToFields(controllerContainer);
  }

  private


  updateDateLimitFields(controllerContainer){
    let _this = this
    let sale_promo_date_limit = $(controllerContainer).find('#sale_promo_date_limit');    
    let date_limit_data_container = $(controllerContainer).find('.js-date_limit_data_container');

    if((sale_promo_date_limit.is(':checked')) || (sale_promo_date_limit.val() == 'true')){
      date_limit_data_container.fadeIn();
    } else {
      date_limit_data_container.hide();
    }
  }

  updateKindFields(controllerContainer){
    let _this = this
    let sale_promo_kind = $(controllerContainer).find('#sale_promo_kind');    
    let kind_amount_discount_data_container = $(controllerContainer).find('.js-kind_amount_discount_data_container');

    if(sale_promo_kind.val() == 'kind_amount_discount'){
      kind_amount_discount_data_container.fadeIn();
    } else {
      kind_amount_discount_data_container.hide();
    }
  }

  updateAppliesToFields(controllerContainer){
    let _this = this

    let applies_to_all_field = $(controllerContainer).find('#sale_promo_applies_to_applies_to_all');
    let applies_to_products_field = $(controllerContainer).find('#sale_promo_applies_to_applies_to_products');
    let applies_to_product_categories_field = $(controllerContainer).find('#sale_promo_applies_to_applies_to_product_categories');

    let sale_promo_applies_to_applies_to_all_data_container = $(controllerContainer).find('.js-sale_promo_applies_to_applies_to_all_data_container');
    let sale_promo_applies_to_applies_to_products_data_container = $(controllerContainer).find('.js-sale_promo_applies_to_applies_to_products_data_container');
    let sale_promo_applies_to_applies_to_product_categories_data_container = $(controllerContainer).find('.js-sale_promo_applies_to_applies_to_product_categories_data_container');


    if((applies_to_all_field.is(':checked')) || (applies_to_all_field.val() == 'true')){
      sale_promo_applies_to_applies_to_all_data_container.fadeIn();
      sale_promo_applies_to_applies_to_products_data_container.hide();
      sale_promo_applies_to_applies_to_product_categories_data_container.hide();
      $('#sale_promo_product_ids').val('').trigger('change.select2')
      $('#sale_promo_product_category_ids').val('').trigger('change.select2')      
    }

    if((applies_to_products_field.is(':checked')) || (applies_to_products_field.val() == 'true')){
      sale_promo_applies_to_applies_to_products_data_container.fadeIn();
      sale_promo_applies_to_applies_to_all_data_container.hide();
      sale_promo_applies_to_applies_to_product_categories_data_container.hide();
      $('#sale_promo_product_category_ids').val('').trigger('change.select2')      
    }

    if((applies_to_product_categories_field.is(':checked')) || (applies_to_product_categories_field.val() == 'true')){
      sale_promo_applies_to_applies_to_product_categories_data_container.fadeIn();
      sale_promo_applies_to_applies_to_all_data_container.hide();
      sale_promo_applies_to_applies_to_products_data_container.hide();
      $('#sale_promo_product_ids').val('').trigger('change.select2')
    }
  }
}
