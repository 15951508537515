
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect ProductFeatureForm controller");

      let _this = this;
      let controllerContainer = this.context.element;

      _this.updateFields(controllerContainer);
    }
  }

  changeKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateFields(controllerContainer);
  }

  private
  

  updateFields(controllerContainer){
    let _this = this

    let productFeatureKindValue = $(controllerContainer).find('#js-product_feature_kind_field').val();
    let optionsContainer = $(controllerContainer).find('#product_feature_jsonb_fields_options').closest('.field-container')
    let stepContainer = $(controllerContainer).find('#product_feature_jsonb_fields_step').closest('.field-container')
    let requiredField = $(controllerContainer).find('#product_feature_jsonb_fields_required')
    let requiredContainer = $(controllerContainer).find('#product_feature_jsonb_fields_required').closest('.field-container')

    
    //string - numeric - options - text - boolean
    switch(productFeatureKindValue) {
      case "numeric":
        optionsContainer.hide();
        stepContainer.show();
        requiredContainer.show();
        break;
      case "options":
        stepContainer.hide();
        optionsContainer.show();
        requiredContainer.show();
        break;
      case "boolean":
        optionsContainer.hide();
        stepContainer.hide();
        requiredContainer.hide();
        requiredField.prop('checked', false);
        break;
      default:
        optionsContainer.hide();
        stepContainer.hide();
        requiredContainer.show();
    }
  }

}