import 'select2'
import 'select2/dist/js/i18n/es'

export class Select2 {
  
  initialize() {
    
    $('select:not(.select2-ajax)').each(function(){
      if($(this).data('select2') == undefined){
        $(this).select2({
          language: "es",
          theme: 'bootstrap4'
        });

        $(this).on('select2:select', function () {
          let event = new Event('change', { bubbles: true })
          this.dispatchEvent(event);
        });

        $(this).on('select2:unselect', function () {
          let event = new Event('change', { bubbles: true })
          this.dispatchEvent(event);
        });
      }
    });

    $('select.select2-ajax').each(function(){
      if($(this).data('select2') == undefined){
        var url = $(this).data('url');
        var _this = $(this);
        var placeholder = $(this).data('placeholder') || 'Seleccionar';
        var allow_clear = $(this).data('allow-clear') || true;

        var minimumInputLength = $(this).data('minimum-input-length') || 0;
        $(this).select2({
          allowClear: allow_clear,
          placeholder: placeholder,
          language: "es",
          theme: 'bootstrap4',
          ajax: {
            url: url,
            dataType: 'json',
            delay: 250,
            data: function (params) {
              var send_params = $(_this).data('send-params');
              if(send_params == undefined){
                return {
                  filter: {
                    query: params.term
                  }
                };
              }
              else{
                //send_params
                let filters = {
                  filter: {
                    query: params.term
                  }
                };
                for (var attrname in send_params) {
                  if ((send_params[attrname].startsWith('#')) || (send_params[attrname].startsWith('.'))){
                    filters['filter'][attrname] = $(send_params[attrname]).val();
                  }
                  else if(send_params[attrname].startsWith('>')) {
                    let aux_param = send_params[attrname].substring(1);
                    aux_param = aux_param.split('>');
                    filters['filter'][attrname] = $(_this).closest(aux_param[0]).find(aux_param[1]).val();
                  }
                  else {
                    filters['filter'][attrname] = send_params[attrname];
                  }
                }
                return filters
              }
            },
            processResults: function (data, params) {
              return {
                results: data
              };
            }
          },
          minimumInputLength: minimumInputLength
        });


        $(this).on('select2:select', function () {
          let event = new Event('change', { bubbles: true })
          this.dispatchEvent(event);
        });

        $(this).on('select2:unselect', function () {
          let event = new Event('change', { bubbles: true })
          this.dispatchEvent(event);
        });
      }
    });

    $('.select2-container').removeAttr("style");
  }
}