import 'jquery.easing'

export class SbAdmin2 {
  initialize() {
    
    // Add active state to sidbar nav links
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $("#layoutSidenav_nav .sidenav a.nav-link").each(function() {
      if (this.href === path) {
        $(this).addClass("active");
      }
    });

    // Toggle the side navigation
    $("#sidebarToggle").on("click", function(e) {
      e.preventDefault();
      $("body").toggleClass("sidenav-toggled");
    });

    // Activate Feather icons
    feather.replace();

    // Activate Bootstrap scrollspy for the sticky nav component
    $("body").scrollspy({
      target: "#stickyNav",
      offset: 82
    });

    // Scrolls to an offset anchor when a sticky nav link is clicked
    $('.nav-sticky a.nav-link[href*="#"]:not([href="#"])').click(function() {
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 81
            },
            200
          );
          return false;
        }
      }
    });

    // Click to collapse responsive sidebar
    $("#layoutSidenav_content").click(function() {
      const BOOTSTRAP_LG_WIDTH = 992;
      if (window.innerWidth >= 992) {
        return;
      }
      if ($("body").hasClass("sidenav-toggled")) {
          $("body").toggleClass("sidenav-toggled");
      }
    });

    // Init sidebar
    let activatedPath = window.location.pathname + window.location.search;
      
    let targetAnchor = $('#accordionSidenav [href="' + activatedPath + '"]');
    let collapseAncestors = targetAnchor.parents('.collapse');

    targetAnchor.addClass('active');

    collapseAncestors.each(function() {
      $(this).addClass('show');
      $('[data-target="#' + this.id +  '"]').removeClass('collapsed');
      
    })


    // Click tr to show item
    $('body').off('click');
    $('body').on('click', '.js-list-container tr td:not(.operations,.no-trigger-view)', function(){
      let trParent = $(this).closest('tr');
      let viewButton = trParent.find('.js-view-button');
      if (viewButton.length != 0){
        $.rails.fire(viewButton.get(0), 'click');
      }
    });


    // Customizing buttons
    function customBtnsMobile() {
      $('#js-filter-button').addClass('btn-white').find('.icon').removeClass('text-white-50').addClass('text-black-50').next('.text').removeClass('text-white').addClass('text-dark')
      $('#js-add-button, .btn-float-mobile').addClass('btn-primary')
    }

    function customBtnsDesktop() {
      $('#js-filter-button').removeClass('btn-white').find('.icon').removeClass('text-black-50').addClass('text-white-50').next('.text').removeClass('text-dark').addClass('text-white')
      $('#js-add-button, .btn-float-mobile').removeClass('btn-primary')
    }


    // Button add item
    $(document).on('scroll', function () {
      let windowWidth = $(window).width();
      if(windowWidth > 768) {
        let scrollDistance = $(this).scrollTop();
        if (scrollDistance > 60) {
          $('#js-float-buttons').addClass('btn-fixed-down')
          customBtnsMobile();
        } else {
          $('#js-float-buttons').removeClass('btn-fixed-down')
          customBtnsDesktop();
        }
      }
    });


    let key_pressed = {};
    $(document).off('keydown');
    $(document).off('keyup');

    $(document).on('keydown', (event) => {
      key_pressed[event.key] = true;
      //console.log('Main -> added -> '+event.key);
    });
    $(document).on('keyup', (event) => {
       delete key_pressed[event.key];
       //console.log('Main -> removed -> '+event.key);
       //console.log('Main -> '+key_pressed)
    });

    // Workaround for select2 inputs open on write 
    $(document).on('keydown', function(event){
        //***************** SI ESTAS EN UN FORM DE VENTA
        if($('#sale_form.access-key-on').length != 0){
          if (key_pressed['b'] && key_pressed['Control']) {
            if ($('#nested_sale_item_data .js-sale_item_product_id').is(':visible')){
              $('#nested_sale_item_data .js-sale_item_product_id')[0].focus();
            }
            event.preventDefault();
          }
          else if (key_pressed['p'] && key_pressed['Control']) {
            if ($('#sale_payments_container .js-payment_method_field:visible').first() != undefined){
              $('#sale_payments_container .js-payment_method_field').first()[0].focus();
            }
            event.preventDefault();
          }
          else if (key_pressed['d'] && key_pressed['Control']) {
            if ($('.js-sale_discount_value_field').is(':visible')){
              $('.js-sale_discount_value_field')[0].focus();
            }
            event.preventDefault();
          }
          else if (key_pressed['m'] && key_pressed['Control']) {
            if ($('.js-sale_multi_payment_field').val() != 'true'){
              $('#js-multi_payment-button').trigger('click');
            }
            $('#js-add_sale_payment').trigger('click');
            $('#sale_payments_container .js-payment_method_field:visible').last()[0].focus();
            event.preventDefault();
          }
          else if (key_pressed['1'] && key_pressed['Control']) {
            if ($('#sale_sale_channel').is(':visible')){
              $('#sale_sale_channel').val('channel_a');
              $('#sale_sale_channel').trigger('select2:select');
            }
            event.preventDefault();
            return false;
          }
          else if (key_pressed['2'] && key_pressed['Control']) {
            if ($('#sale_sale_channel').is(':visible')){
              $('#sale_sale_channel').val('channel_b');
              $('#sale_sale_channel').trigger('select2:select');
            }
            event.preventDefault();
            return false;
          }
          else if (key_pressed['c'] && key_pressed['Control']) {

            $('#submit_button').trigger('click');
            event.preventDefault();
      
          }
          else if (key_pressed['F1']) {
            $('#shortcutsModal').modal('show')
            event.preventDefault();
          }
          else if (key_pressed['Escape']) {
            if(($(document.activeElement).is('input')) || ($(document.activeElement).is('.select2-selection '))){
              document.activeElement.blur();
            }
          }
          else {
          }
        }

        if(($('#sale_form').length != 0) || ($('#table_sale_form').length != 0)){
          if (key_pressed['Enter']) {
            if ($('#sale_nested_amount').is(':focus') || $('#sale_nested_discount_value').is(':focus')){ 
              $('#js-add_sale_item').trigger('click');
              event.preventDefault();
            }
          }
        }

        //***************** SI PASA DENTRO DEL FOCO DE UN SELECT2
        if (
          ($(document.activeElement).hasClass('select2-selection')) && 
          ($(document.activeElement).closest(".select2-container").hasClass('select2-container--focus'))
        ){

          if (
            (event.key != 'F1') && (event.key != 'F2') && (event.key != 'F3') && (event.key != 'F4') && (event.key != 'F5') && (event.key != 'F6') && (event.key != 'F7') && (event.key != 'F8') && (event.key != 'F9') && (event.key != 'F10') && (event.key != 'F11') && (event.key != 'F12') && 
            (event.key != 'Spacebar') && 
            (event.key != 'Enter') && 
            (event.key != 'Control') && 
            (event.key != 'Shift') && 
            (event.key != 'PageUp') && 
            (event.key != 'PageDown') && 
            (event.key != 'CapsLock') && 
            (event.key != 'Backspace') && 
            (event.key != 'Insert') && 
            (event.key != 'ArrowRight') && 
            (event.key != 'ArrowLeft') && 
            (event.key != 'ArrowUp') && 
            (event.key != 'ArrowDown') && 
            (event.key != 'Escape') && 
            (event.key != 'Tab') &&
            (!key_pressed['Control'])
          ){
            $(document.activeElement).closest(".select2-container").siblings('select:enabled').select2('open');
            $('.select2-search__field').val(event.key);
            $('.select2-search__field').trigger('input');
          }
          else if(event.key == 'Enter'){
            $(document.activeElement).closest(".select2-container").siblings('select:enabled').select2('close');
            event.preventDefault();
            event.stopPropagation();
          }
        }
    });


    // Mobile floating buttons
    function floatingButtonsMobile() {
      let windowWidth = $(window).width();
      if(windowWidth < 768) {
        $('#js-float-buttons').addClass('btn-fixed-down')
        $('#js-float-buttons .btn').addClass('btn-sm')
        customBtnsMobile();
      } else {
        $('#js-float-buttons').removeClass('btn-fixed-down')
        $('#js-float-buttons .btn').removeClass('btn-sm')
        customBtnsDesktop();
      }
    }

    // Infinite pagination
    if ($('#infinite-scrolling').length > 0) {
      checkAndFetchPages();

      $(document).on('scroll', function() {
        checkAndFetchPages();
      });
    }

    // Define el foco en el modal
    $('.js-modal').on('shown.bs.modal', function () {
      let input = $(this).find('[autofocus=autofocus]').first();
      if(input.data('select2') != undefined){
        input.select2('focus');
      }
      else{
        input.trigger('focus');
      }
    });

    let input = $(document).find('[autofocus=autofocus]').first();
    if(input.data('select2') != undefined){
      input.select2('focus');
    }
    else{
      input.trigger('focus');
    }

    $(window).resize(function () {
      setContentHeight();
      floatingButtonsMobile();
    });

    setContentHeight();
    floatingButtonsMobile();

    function setContentHeight(){
      let windowHeight = $(window).height();
      $('#content').css('min-height', windowHeight+'px');
    }

    function checkAndFetchPages() {
      let more_posts_url = $('#infinite-scrolling .page-link[rel=next]').attr('href');
      if (more_posts_url && $(window).scrollTop() > $(document).height() - $(window).height() - 60) {
        $('#infinite-scrolling').html('<i class="fas fa-spinner text-primary mb-4 fa-3x fa-pulse"></i>');
        setTimeout(() => {
          $.getScript( more_posts_url, function( data, textStatus, jqxhr ) {
            $('[data-toggle="tooltip"]').tooltip();
            $('[data-toggle="popover"]').popover({trigger: "focus"});
          });
        }, 1000);
      }
    }

  }

}
