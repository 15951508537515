import { FlashMessages } from '../../lib/flash-messages.js';
import  ApplicationController from "../application_controller.js"
import { BuyCalculation } from "../../lib/buys_form/buy_calculation.js"

let time = 0;

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect BuyForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;


      $(controllerContainer).on('cocoon:after-insert', '#buy_taxes_container', function(e, insertedItem, originalEvent) {
        let buyTax = $(insertedItem).closest('.nested-fields');
        _this.updateBuyTotals(controllerContainer);
      });

      $(controllerContainer).on('cocoon:after-remove', '#buy_taxes_container', function(e, insertedItem, originalEvent) {
        _this.updateBuyTotals(controllerContainer);
      });


      $(controllerContainer).on('cocoon:after-insert', '#buy_items_container', function(e, insertedItem, originalEvent) {
        let buyItem = $(insertedItem).closest('.nested-fields');
        _this.bindBuyItem(controllerContainer, buyItem);
        _this.updateBuyTotals(controllerContainer);
        _this.checkBuyItemEmpty();
      });

      $(controllerContainer).on('cocoon:before-insert', '#buy_items_container', function(e, insertedItem, originalEvent) {

        let productIdField = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_id');
        let amountField = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_amount_field');
        let productIdValue = productIdField.select2('val');
        let addedProductIds = $("#buy_items_container .js-buy_item_product_id:visible").map(function() { return $(this).val(); }).get()

        productIdField.removeClass('is-invalid');
        amountField.removeClass('is-invalid');

        if (productIdField.select2('val') == ''){
          productIdField.addClass('is-invalid');
          productIdField.select2('focus');
          e.preventDefault();
        }
        if (amountField.val() == ''){
          amountField.addClass('is-invalid');
          amountField.trigger('focus');
          e.preventDefault();
        }

        if (jQuery.inArray(productIdValue, addedProductIds) !== -1){
          let input = $('#buy_items_container .js-buy_item_product_id option[value="'+productIdValue+'"]:selected')
          let container = input.closest('.nested-fields');
          let buyItemAmountField = container.find('.js-buy_item_product_amount_field');
          
          let buyItemAmountValue = parseFloat(buyItemAmountField.val());
          buyItemAmountValue = (isNaN(buyItemAmountValue)) ? 0.0 : buyItemAmountValue;

          let amountFieldValue = parseFloat(amountField.val());
          amountFieldValue = (isNaN(amountFieldValue)) ? 0.0 : amountFieldValue;

          buyItemAmountField.val((buyItemAmountValue+amountFieldValue));
          buyItemAmountField.trigger('input');
          _this.cleanNestedBuyItem(controllerContainer, insertedItem);
          e.preventDefault();
        }
        else{
          _this.populateBuyItem(controllerContainer, insertedItem);
        }
      });

      $('#js-product-container').on('click', '.page-link', function(e){
        e.preventDefault()
        var page = $(this).html();
        var actualPage = $('.page-item.active .page-link').html()

        if(page == '←'){
          page = parseInt(actualPage) - 1;
        } else if (page == '→'){
          page = parseInt(actualPage) + 1;
        }

        var supplier_id = $('#buy_supplier_id').val();
        let product_category_id = $('.js-product_category_id_field').val();
        var url = $('#buy_supplier_id').data('urlThree');
        var filter_query = $('#filter_query').val();
        url = url.replace('the_id', supplier_id);

        
        if((filter_query != undefined) && (filter_query != '')){
          url += '&filter[query]='+filter_query
        }

        if((product_category_id != undefined) && (product_category_id != '')){
          url += '&filter[product_category_id]='+product_category_id
        }

        var href = url + '&page=' + page

        $.getScript(href);
      });

      $('#buy_items_container .nested-fields, #nested_buy_item_product_data').each(function(){
        _this.bindBuyItem(controllerContainer, $(this));
      });

      $(controllerContainer).on('cocoon:after-remove', '#buy_items_container', function(e, insertedItem, originalEvent) {
        _this.updateBuyTotals(controllerContainer);
        _this.checkBuyItemEmpty();
      });

      $(controllerContainer).on('input', '.js-buy_discount_value_field', function() {
        _this.updateBuyTotals(controllerContainer);
      });

      $(controllerContainer).on('click', '.js-buy_discount_kind_dropdown_option', function(event) {
        event.preventDefault();
        _this.updateBuyDiscountKind(controllerContainer, this);
        _this.updateBuyTotals(controllerContainer);
      });

      $(controllerContainer).on('keydown', function(e){
        if(e.keyCode == 13) {
          if ($('.js-buy_item_product_amount_field').is(':focus') || $('.js-buy_item_product_discount_value_field').is(':focus')){ 
            $('#js-add_buy_item_product').trigger('click');
            e.preventDefault();
          }
        }
      });
      _this.updateBuyInvoiceTypeField(controllerContainer);
      _this.updateHaveTaxes(controllerContainer);

      setTimeout(function() {
        _this.updateNestedProductFields(controllerContainer);
      }, 200);
    }
  }

  // Triggerado por el click para ver observaciones
  setOrderForSupplier(){
    let _this = this;
    let controllerContainer = _this.context.element;


    let supplierIdField = $(controllerContainer).find('.js-supplier_id_field');
    let supplierId = supplierIdField.select2('val');
    let setOrderButton = $(controllerContainer).find('#js-set_order-button');

    let url_products_stocks = $(setOrderButton).data('url-products-stocks');

    $('#buy_items_container .nested-fields:visible').each(function(){
      let buyItemToDestroy = $(this);
      let removeButton = buyItemToDestroy.find('.remove_fields');
      if(removeButton.hasClass('existing')){
        //existing
        removeButton.prev('input').val('1');
        buyItemToDestroy.hide();
      }
      else{
        //dynamic
        buyItemToDestroy.remove();
      }
    });
    

    if(supplierId != ''){
      let product_size = 0.0;
      url_products_stocks = url_products_stocks.replace('the_id', supplierId);
      $.ajaxSetup({async: false});
      $.getJSON(url_products_stocks, function(data) {
        product_size = data.length;
        $(data).each(function(){
          _this.addOrderProduct(controllerContainer, this);
        });      
      });
      $.ajaxSetup({async: true});


      if(product_size == 0.0){
        FlashMessages.message({"alert": "No hay productos con stock faltante para este proveedor" });
      }
      else{
        FlashMessages.message({"notice": "Agregados "+product_size+" productos" });
      }

    }
  }


  // Triggerado por el click para tiene impuestos
  toggleHaveTaxes(){
    let _this = this;
    let controllerContainer = _this.context.element;    
    _this.setHaveTaxes(controllerContainer);
    _this.updateHaveTaxes(controllerContainer);
  }


  // Triggerado por el click para ver observaciones
  toggleOpenObservation(){
    let _this = this;
    let controllerContainer = _this.context.element;

    let cardBody = $(controllerContainer).find('.card-body');
    cardBody.toggleClass('open-observation');
  }

  // Triggerado por el cambio de supplier
  changeSupplier(){
    let _this = this;
    let controllerContainer = _this.context.element;
    _this.updateNestedProductFields(controllerContainer);
    _this.updateSupplierData(controllerContainer);
  }

  handleFilterInput(){
    let _this = this;
    let controllerContainer = _this.context.element;    

    clearTimeout(time);
    time = setTimeout(function() {
      _this.loadProducts(controllerContainer);
    }, 500);
  }

  changeSupplierExpress(){
    let _this = this;
    let controllerContainer = _this.context.element;
    _this.updateNestedProductFields(controllerContainer);
    _this.updateSupplierData(controllerContainer);
    _this.loadProducts(controllerContainer);

    $('.js-product_category_id_field').val('');
    $('.js-product_category_id_field').trigger('change.select2');
  }


  // Triggerado por el cambio de producto
  changeNestedProductId(){
    let _this = this;

    $('#nested_buy_item_product_data .js-buy_item_product_amount_field')[0].focus();
  }


  // Triggerado por el cambio el canal
  changeBuyChannel(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setBuyInvoiceTypeField(controllerContainer);

    //Refresh all prices
    $('#buy_items_container .nested-fields:visible, #nested_buy_item_product_data').each(function(){
      let buyItem = $(this);
      _this.updateBuyItemData(controllerContainer, buyItem);
    });


    _this.updateBuyTotals(controllerContainer);
  }


  // Triggerado por el tipo de factura
  changeInvoiceType(){
    let _this = this;
    let controllerContainer = _this.context.element;
      //Refresh all prices
      $('#buy_items_container .nested-fields:visible, #nested_buy_item_product_data').each(function(){
        let buyItem = $(this);
        _this.updateBuyItemData(controllerContainer, buyItem);
      });

    _this.updateBuyTotals(controllerContainer);
  }


  // Triggerado por el cambio de medio de pago 
  changePaymentMethod(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePaymentMethodData(controllerContainer);
  }



  clickProductButton(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    let button = $(event.currentTarget);

    _this.addProduct(controllerContainer, button);
  }


  // Triggerado por el cambio de monto de pago
  changeBuyTaxAmount(event){
    let _this = this;
    let controllerContainer = _this.context.element;
    
    _this.updateBuyTotals(controllerContainer);
  }


  // Triggerado por el click para order por nombre de producto
  toggleSortProductName(event){
    let wrapper = $('#buy_items_container');
    let sortProductNameASC = $('#sortProductNameASC');
    let sortProductNameDESC = $('#sortProductNameDESC');
    let sortProductBrandASC = $('#sortProductBrandASC');
    let sortProductBrandDESC = $('#sortProductBrandDESC');
    let link = event.currentTarget;

    if(link.dataset.order == 'ASC'){
      wrapper.find('.nested-fields').sort(function (a, b) {
        return b.dataset.productName.toUpperCase().localeCompare(a.dataset.productName.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'DESC'
      sortProductNameASC.hide();
      sortProductBrandASC.hide();
      sortProductBrandDESC.hide();
      sortProductNameDESC.show();
    }
    else{
      wrapper.find('.nested-fields').sort(function (a, b) {
        return a.dataset.productName.toUpperCase().localeCompare(b.dataset.productName.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'ASC'
      sortProductNameDESC.hide();
      sortProductBrandASC.hide();
      sortProductBrandDESC.hide();
      sortProductNameASC.show();
    }
  }

  toggleSortProductBrand(event){
    let wrapper = $('#buy_items_container');
    let sortProductNameASC = $('#sortProductNameASC');
    let sortProductNameDESC = $('#sortProductNameDESC');
    let sortProductBrandASC = $('#sortProductBrandASC');
    let sortProductBrandDESC = $('#sortProductBrandDESC');
    let link = event.currentTarget;

    if(link.dataset.order == 'ASC'){
      wrapper.find('.nested-fields').sort(function (a, b) {
        return b.dataset.productBrand.toUpperCase().localeCompare(a.dataset.productBrand.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'DESC'
      sortProductBrandASC.hide();
      sortProductNameASC.hide();
      sortProductNameDESC.hide();
      sortProductBrandDESC.show();
    }
    else{
      wrapper.find('.nested-fields').sort(function (a, b) {
        return a.dataset.productBrand.toUpperCase().localeCompare(b.dataset.productBrand.toUpperCase());
      }).appendTo(wrapper);
      link.dataset.order = 'ASC'
      sortProductBrandDESC.hide();
      sortProductNameASC.hide();
      sortProductNameDESC.hide();      
      sortProductBrandASC.show();      
    }
  }

  changePayedCheckIds(){
    let _this = this;
    let controllerContainer = _this.context.element;

  }


  private

  updateBuyDiscountKind(controllerContainer, option){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let buyDiscountKindField = $(controllerContainer).find('.js-buy_discount_kind_field');
    let buyDiscountKindContainer = $(controllerContainer).find('.js-buy_discount_kind_container');

    buyDiscountKindField.val(value);
    buyDiscountKindContainer.html(icon);
  }


  setHaveTaxes(controllerContainer){
    let sale_have_taxes_field = $(controllerContainer).find('.js-sale_have_taxes_field');
    let add_buy_tax = $(controllerContainer).find('#js-add_buy_tax');

    if(sale_have_taxes_field.val() == 'true'){
      sale_have_taxes_field.val('false');
  
      $(controllerContainer).find('#buy_taxes_container .nested-fields:visible').each(function(){
        $(this).find('.remove_fields').trigger('click');
      });
    }
    else{
      sale_have_taxes_field.val('true');
      add_buy_tax.trigger('click');
    }
  }

  updateHaveTaxes(controllerContainer){
    let button = $(controllerContainer).find('#js-have_taxes-button');
    let sale_have_taxes_field = $(controllerContainer).find('.js-sale_have_taxes_field');
    let buy_taxes_box_container = $(controllerContainer).find('#buy_taxes_box_container');
    
    if(sale_have_taxes_field.val() == 'true'){
      buy_taxes_box_container.fadeIn();
      button.removeClass('btn-light').removeClass('text-primary');
      button.addClass('btn-primary').addClass('text-white');
    }
    else{
      buy_taxes_box_container.hide();
      button.removeClass('btn-primary').removeClass('text-white');
      button.addClass('btn-light').addClass('text-primary');
    }
  }


  loadProducts(controllerContainer){
    let _this = this;
    let url = $('#buy_supplier_id').data('urlThree')
    let supplier_id = $('#buy_supplier_id').val();
    let product_category_id = $('.js-product_category_id_field').val();

    let filter_query = $('#filter_query').val();

    $.ajaxSetup({async: false});
    url = url.replace('the_id', supplier_id);
    if((filter_query != undefined) && (filter_query != '')){
      url += '&filter[query]='+filter_query
    }
    if((product_category_id != undefined) && (product_category_id != '')){
      url += '&filter[product_category_id]='+product_category_id
    }
    $.getScript(url);
    if((supplier_id != undefined) && (supplier_id != '')){
      $('#load_products_for_buy_container').show();
    } else {
      $('#load_products_for_buy_container').hide();
    }    
    $.ajaxSetup({async: true});
  }

  addOrderProduct(controllerContainer, product){
    let _this = this;
    let productId = product.id;
    let productName = product.name;
    let productAmount = product.amount;
    
    $('#nested_buy_item_product_data .js-buy_item_product_amount_field').val(productAmount);
    $('#nested_buy_item_product_data .js-buy_item_product_id').append('<option selected="selected" value="'+productId+'">'+productName+'<option>').trigger('change.select2');
    
    let buyItem = $(controllerContainer).find('#nested_buy_item_product_data');

    _this.updateBuyItemData(controllerContainer, buyItem);
    $('#js-add_buy_item_product').trigger('click');
  }
  

  // Agrego un producto
  addProduct(controllerContainer, button){
    let _this = this;
    let productId = $(button).data('id');
    let productName = $(button).data('name');
    let productSymbol = $(button).data('symbol');
    
    $('#nested_buy_item_product_data .js-buy_item_product_symbol_container').html(productSymbol);
    $('#nested_buy_item_product_data .js-buy_item_product_amount_field').val(1);
    $('#nested_buy_item_product_data .js-buy_item_product_id').append('<option selected="selected" value="'+productId+'">'+productName+'<option>').trigger('change.select2');
    $('#nested_buy_item_product_data .js-buy_item_product_discount_kind_field').val('discount_kind_perc');

    let buyItem = $(controllerContainer).find('#nested_buy_item_product_data');

    _this.updateBuyItemData(controllerContainer, buyItem);
    $('#js-add_buy_item_product').trigger('click');
  }


  // Actualiza info del pago:
  updatePaymentMethodData(controllerContainer){
    let _this = this;
    let paymentMethodValue = $(controllerContainer).find('.js-payment_method_field').val();
    let paymentMethodCashData = $(controllerContainer).find('.payment_method_cash_data');
    let paymentMethodBankTransferData = $(controllerContainer).find('.payment_method_bank_transfer_data');
    let paymentMethodOtherData = $(controllerContainer).find('.payment_method_other_data');
    let paymentMethodCheckData = $(controllerContainer).find('.payment_method_check_data');
    let paymentMethodThirdCheckData = $(controllerContainer).find('.payment_method_third_check_data');

    if (paymentMethodValue == 'cash'){
      paymentMethodCashData.show();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);      
    }
    else if(paymentMethodValue == 'bank_transfer'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);      
    }
    else if(paymentMethodValue == 'debit_card'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);      
    }
    else if(paymentMethodValue == 'other'){
      paymentMethodOtherData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);      
    }
    else if(paymentMethodValue == 'check'){
      paymentMethodCheckData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('0');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);      
    }
    else if(paymentMethodValue == 'third_check'){
      paymentMethodThirdCheckData.show();
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
    }
    else{
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);      
    }
  }

  
  // Actualiza info del suppliere:
  updateSupplierData(controllerContainer){
    let _this = this;
    let supplierIdField = $(controllerContainer).find('.js-supplier_id_field');
    let supplierId = supplierIdField.select2('val');
    let buySupplierTaxProfileField = $(controllerContainer).find('.js-buy_supplier_tax_profile_field');
        

    let paymentMethodField = $(controllerContainer).find('.js-payment_method_field');
    let buyBuyChannelField = $(controllerContainer).find('.js-buy_buy_channel_field');

    let url = $(supplierIdField).data('url-two');
    
    if(supplierId != ''){
      url = url.replace('/the_id', '/'+supplierId);
      $.getJSON(url, function(data) {
        let taxProfile = data.tax_profile;
        let taxPerc = data.tax_perc;
        let paymentMethod = data.payment_method;
        let buyChannel = data.buy_channel;

        //Seteo perfil impositivo y tipo de supplier
        buySupplierTaxProfileField.val(taxProfile);


        //Seteo medio de pago y canal por defecto del proveedor
        paymentMethodField.val(paymentMethod);
        buyBuyChannelField.val(buyChannel);
        paymentMethodField.trigger('select2:select');
        buyBuyChannelField.trigger('change');

        _this.setBuyInvoiceTypeField(controllerContainer);


        //Refresh all prices
        $('#buy_items_container .nested-fields:visible, #nested_buy_item_product_data').each(function(){
          let buyItem = $(this);
          _this.updateBuyItemData(controllerContainer, buyItem);
        });
        _this.updateBuyTotals(controllerContainer);
      });      
    }
  }
  setBuyInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let buySupplierTaxProfileValue = $(controllerContainer).find('.js-buy_supplier_tax_profile_field').val();
    let buyInvoiceTypeField = $(controllerContainer).find('.js-buy_invoice_type_field');
    let buyBuyChannelValue = $(controllerContainer).find('.js-buy_buy_channel_field').val();

    _this.updateBuyInvoiceTypeField(controllerContainer);


    if(buyBuyChannelValue == 'channel_b'){
      buyInvoiceTypeField.val('invoice_other');
    }
    else if (buyBuyChannelValue == 'channel_a'){

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(buySupplierTaxProfileValue == 'enroled_responsable'){
          buyInvoiceTypeField.val('invoice_b');
        }
        else if (buySupplierTaxProfileValue == 'monotax_responsable'){
          buyInvoiceTypeField.val('invoice_c');
        }
        else if (buySupplierTaxProfileValue == 'end_consumer'){
          buyInvoiceTypeField.val('invoice_c');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(buySupplierTaxProfileValue == 'enroled_responsable'){
          buyInvoiceTypeField.val('invoice_a');
        }
        else if (buySupplierTaxProfileValue == 'monotax_responsable'){
          buyInvoiceTypeField.val('invoice_c');
        }
        else if (buySupplierTaxProfileValue == 'end_consumer'){
          buyInvoiceTypeField.val('invoice_b');
        }
      }
    }

    buyInvoiceTypeField.trigger('select2:select');
  }

  updateNestedProductFields(controllerContainer){
    let _this = this;
    let supplierIdValue = $(controllerContainer).find('.js-supplier_id_field').val();
    let setOrderButton = $(controllerContainer).find('#js-set_order-button');
  
    let nested_buy_item_product_data = $(controllerContainer).find('#nested_buy_item_product_data');
    let buyItemProductIdField = nested_buy_item_product_data.find('.js-buy_item_product_id');

    let buy_item_product_amount_field = nested_buy_item_product_data.find('.js-buy_item_product_amount_field')
    let buy_item_product_discount_value_field = nested_buy_item_product_data.find('.js-buy_item_product_discount_value_field')
    let buy_item_product_discount_kind = nested_buy_item_product_data.find('.js-buy_item_product_discount_kind_container').parent('button')


    if(supplierIdValue != ''){
      setOrderButton.show();

      buyItemProductIdField.removeAttr('disabled');
      buy_item_product_amount_field.removeAttr('disabled');
      buy_item_product_discount_value_field.removeAttr('disabled');
      buy_item_product_discount_kind.removeAttr('disabled');
    }
    else {
      setOrderButton.hide();

      buyItemProductIdField.prop('disabled', true);
      buy_item_product_amount_field.prop('disabled', true);
      buy_item_product_discount_value_field.prop('disabled', true);
      buy_item_product_discount_kind.prop('disabled', true);
      _this.cleanNestedBuyItem(controllerContainer, nested_buy_item_product_data);
    }
  }
  
  updateBuyInvoiceTypeField(controllerContainer){
    let _this = this;
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let buySupplierTaxProfileValue = $(controllerContainer).find('.js-buy_supplier_tax_profile_field').val();
    let buyInvoiceTypeField = $(controllerContainer).find('.js-buy_invoice_type_field');
    let buyBuyChannelValue = $(controllerContainer).find('.js-buy_buy_channel_field').val();

    if(buyBuyChannelValue == 'channel_b'){
      buyInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
      buyInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
      buyInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
      buyInvoiceTypeField.find("option[value='invoice_other']").removeAttr('disabled');
    }
    else if (buyBuyChannelValue == 'channel_a'){
      buyInvoiceTypeField.find("option[value='invoice_other']").attr('disabled','disabled');

      if(salePointTaxProfileValue == 'monotax_responsable'){
        if(buySupplierTaxProfileValue == 'enroled_responsable'){
          buyInvoiceTypeField.find("option[value='invoice_a']").removeAttr('disabled');
          buyInvoiceTypeField.find("option[value='invoice_b']").removeAttr('disabled');
          buyInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
        else if (buySupplierTaxProfileValue == 'monotax_responsable'){
          buyInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
        else if (buySupplierTaxProfileValue == 'end_consumer'){
          buyInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
      }
      else if (salePointTaxProfileValue == 'enroled_responsable'){
        if(buySupplierTaxProfileValue == 'enroled_responsable'){
          buyInvoiceTypeField.find("option[value='invoice_a']").removeAttr('disabled');
          buyInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
        else if (buySupplierTaxProfileValue == 'monotax_responsable'){
          buyInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_b']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_c']").removeAttr('disabled');
        }
        else if (buySupplierTaxProfileValue == 'end_consumer'){
          buyInvoiceTypeField.find("option[value='invoice_a']").attr('disabled','disabled');
          buyInvoiceTypeField.find("option[value='invoice_b']").removeAttr('disabled');
          buyInvoiceTypeField.find("option[value='invoice_c']").attr('disabled','disabled');
        }
      }
    }

    buyInvoiceTypeField.trigger('select2:select');
  }


  populateBuyItem(controllerContainer, buyItem){
    let _this = this;


    let buyItemProductIdData = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_id').select2('data')[0];
    let buyItemTaxPercValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_tax_perc_field').val();
    let buyItemTaxValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_tax_field').val();
    let buyItemSubtotalValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_subtotal_field').val();
    let buyItemPriceValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_price_field').val();
    let buyItemOriginalPriceValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_original_price_field').val();
    let buyItemAmountValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_amount_field').val();
    let buyItemDiscountValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_discount_value_field').val();
    let buyItemDiscountKind = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_discount_kind_field').val();
    let buyItemDiscountSymbol = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_discount_kind_container').html();
    let buyItemTotalValue = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_total_field').val();
    let buyItemSymbol = $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_symbol_container').html();


    buyItemPriceValue = parseFloat(buyItemPriceValue);
    buyItemPriceValue = (isNaN(buyItemPriceValue)) ? 0.0 : buyItemPriceValue;

    buyItemOriginalPriceValue = parseFloat(buyItemOriginalPriceValue);
    buyItemOriginalPriceValue = (isNaN(buyItemOriginalPriceValue)) ? 0.0 : buyItemOriginalPriceValue;

    buyItemAmountValue = parseFloat(buyItemAmountValue);
    buyItemAmountValue = (isNaN(buyItemAmountValue)) ? 0.0 : buyItemAmountValue;

    buyItemDiscountValue = parseFloat(buyItemDiscountValue);
    buyItemDiscountValue = (isNaN(buyItemDiscountValue)) ? 0.0 : buyItemDiscountValue;

    buyItem.find('.js-buy_item_product_id').append('<option selected="selected" value="'+buyItemProductIdData.id+'">'+buyItemProductIdData.text+'<option>').trigger('change.select2');
    buyItem.find('.js-buy_item_product_tax_perc_field').val(buyItemTaxPercValue);
    buyItem.find('.js-buy_item_product_tax_field').val(buyItemTaxValue);
    buyItem.find('.js-buy_item_product_subtotal_field').val(buyItemSubtotalValue);
    buyItem.find('.js-buy_item_product_price_field').val(buyItemPriceValue);
    buyItem.find('.js-buy_item_product_original_price_field').val(buyItemOriginalPriceValue);
    buyItem.find('.js-buy_item_product_amount_field').val(buyItemAmountValue);
    buyItem.find('.js-buy_item_product_discount_value_field').val(buyItemDiscountValue);
    buyItem.find('.js-buy_item_product_discount_kind_field').val(buyItemDiscountKind);
    buyItem.find('.js-buy_item_product_discount_kind_container').html(buyItemDiscountSymbol);
    buyItem.find('.js-buy_item_product_total_field').val(buyItemTotalValue);
    buyItem.find('.js-buy_item_product_symbol_container').html(buyItemSymbol);

    _this.cleanNestedBuyItem(controllerContainer, buyItem)


    let timeToRefreshBuyItem = 0;
    clearTimeout(timeToRefreshBuyItem);
    timeToRefreshBuyItem = setTimeout(function () {
      _this.updateBuyItemTotals(controllerContainer, buyItem);
      _this.updateBuyTotals(controllerContainer);
    }, 200);


    // Focus on product select
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_id').select2('focus');      

  }

  cleanNestedBuyItem(controllerContainer, buyItem){
    let baseDiscountSymbol = "<i class='fas fa-percent'></i>";

    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_id').val('').trigger('change.select2');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_tax_perc_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_tax_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_subtotal_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_total_without_tax_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_price_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_original_price_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_amount_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_discount_value_field').val('');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_discount_kind_field').val('discount_kind_perc');
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_discount_kind_container').html(baseDiscountSymbol);
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_total_field').val('');  
    $(controllerContainer).find('#nested_buy_item_product_data .js-buy_item_product_symbol_container').html('');

    
    // Remove error alert from list
    $(controllerContainer).find('#section-two .alert.alert-danger').remove();
  }

  cleanBuyItem(controllerContainer, buyItem){
    let baseDiscountSymbol = "<i class='fas fa-percent'></i>";

    $(buyItem).find('.js-buy_item_product_id').val('').trigger('change.select2');
    $(buyItem).find('.js-buy_item_product_tax_perc_field').val('');
    $(buyItem).find('.js-buy_item_product_tax_field').val('');
    $(buyItem).find('.js-buy_item_product_subtotal_field').val('');
    $(buyItem).find('.js-buy_item_product_total_without_tax_field').val('');
    $(buyItem).find('.js-buy_item_product_price_field').val('');
    $(buyItem).find('.js-buy_item_product_original_price_field').val('');
    $(buyItem).find('.js-buy_item_product_amount_field').val('');
    $(buyItem).find('.js-buy_item_product_discount_value_field').val('');
    $(buyItem).find('.js-buy_item_product_discount_kind_field').val('discount_kind_perc');
    $(buyItem).find('.js-buy_item_product_discount_kind_container').html(baseDiscountSymbol);
    $(buyItem).find('.js-buy_item_product_total_field').val('');  
    $(buyItem).find('.js-buy_item_product_symbol_container').html('');

  }



  bindBuyItem(controllerContainer, buyItem){
    let _this = this;

    let buyItemProductIdField = buyItem.find('.js-buy_item_product_id');
    let buyItemProductPriceField = buyItem.find('.js-buy_item_product_price_field');
    let buyItemProductAmountField = buyItem.find('.js-buy_item_product_amount_field');
    let buyItemProductDiscountValueField = buyItem.find('.js-buy_item_product_discount_value_field');
    let buyItemProductDiscountKindDropdownOption = buyItem.find('.js-buy_item_product_discount_kind_dropdown_option');
    
    $(buyItemProductIdField).on('select2:select', function() {
      _this.updateBuyItemData(controllerContainer, buyItem);
    });

    $(buyItemProductIdField).on('select2:unselect', function() {
      _this.updateBuyItemData(controllerContainer, buyItem);
    });

    $(buyItemProductPriceField).on('input', function() {
      _this.updateBuyItemTotals(controllerContainer, buyItem);
      _this.updateBuyTotals(controllerContainer);
    });

    $(buyItemProductAmountField).on('input', function() {
      _this.updateBuyItemTotals(controllerContainer, buyItem);
      _this.updateBuyTotals(controllerContainer);
    });

    $(buyItemProductDiscountValueField).on('input', function() {
      _this.updateBuyItemTotals(controllerContainer, buyItem);
      _this.updateBuyTotals(controllerContainer);
    });

    $(buyItemProductDiscountKindDropdownOption).on('click', function(event) {
      event.preventDefault();
      _this.updateBuyItemDiscountKind(this, buyItem);
      _this.updateBuyItemTotals(controllerContainer, buyItem);
      _this.updateBuyTotals(controllerContainer);
    });
  }

  updateBuyItemDiscountKind(option, buyItem){
    let value = $(option).attr('href');
    let icon = $(option).html();

    let buyItemDiscountKindField = buyItem.find('.js-buy_item_product_discount_kind_field');
    let buyItemDiscountKindContainer = buyItem.find('.js-buy_item_product_discount_kind_container');
    buyItemDiscountKindField.val(value);
    buyItemDiscountKindContainer.html(icon);
  }

  updateBuyItemData(controllerContainer, buyItem){
    let _this = this;


    let supplierIdValue = $(controllerContainer).find('.js-supplier_id_field').select2('val');
    let buyItemSymbolContainer = buyItem.find('.js-buy_item_product_symbol_container');
    let buyItemPriceField = buyItem.find('.js-buy_item_product_price_field');
    let buyItemOriginalPriceField = buyItem.find('.js-buy_item_product_original_price_field');
    let buyItemTaxPercField = buyItem.find('.js-buy_item_product_tax_perc_field');
    let buyItemRemoveFieldsButton = buyItem.find('.remove_fields');
    let productIdField = buyItem.find('.js-buy_item_product_id');
    let productIdValue = productIdField.select2('val');
    let url = $(productIdField).data('url-two');
    let salePointTaxProfileValue = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let buySupplierTaxProfileValue = $(controllerContainer).find('.js-buy_supplier_tax_profile_field').val();
    let buyBuyChannelValue = $(controllerContainer).find('.js-buy_buy_channel_field').val();
    let buyInvoiceTypeValue = $(controllerContainer).find('.js-buy_invoice_type_field').val();


    


    if(productIdValue != ''){
      url = url.replace('/the_id/show_for_buy', '/'+productIdValue+'/show_for_buy?supplier_id='+supplierIdValue);

      $.ajaxSetup({async: false});
      $.getJSON(url, function(data) {
        let price = data.price;
        if(price == undefined){
          if (buyItemRemoveFieldsButton.length != 0){
            buyItemRemoveFieldsButton.trigger('click');
          }
          else{
            _this.cleanNestedBuyItem(controllerContainer, buyItem);
          }
        }
        else{
          let symbol = data.purchase_measurement_category_symbol;
          let taxPerc = data.tax_perc;
          
          buyItemSymbolContainer.html(symbol);
          buyItemSymbolContainer.fadeIn();

          price = parseFloat(price);
          price = (isNaN(price)) ? 0.0 : price;

          if (
            (
              (salePointTaxProfileValue == 'enroled_responsable') &&
              (buySupplierTaxProfileValue == 'enroled_responsable') &&
              (buyBuyChannelValue == 'channel_a')
            ) ||
            (
              (salePointTaxProfileValue == 'monotax_responsable') &&
              (buySupplierTaxProfileValue == 'enroled_responsable') &&
              (buyInvoiceTypeValue == 'invoice_a') &&
              (buyBuyChannelValue == 'channel_a')
            )
          ){

              
            var tax_perc_value = parseFloat(taxPerc);
            tax_perc_value = (isNaN(tax_perc_value)) ? 0.0 : tax_perc_value;
            
            let price_net = price / (1 + (tax_perc_value / 100.0))
            price_net = _this.round(price_net);

            price = _this.round(price);

            buyItemPriceField.val(price_net);
            buyItemOriginalPriceField.val(price);
          }
          else{
            buyItemPriceField.val(price);
            buyItemOriginalPriceField.val(price);
          }

          buyItemTaxPercField.val(taxPerc);
        }

        _this.updateBuyItemTotals(controllerContainer, buyItem);
        _this.updateBuyTotals(controllerContainer);
      });
      $.ajaxSetup({async: true}); 
    }
    else{
      if (buyItemRemoveFieldsButton.length != 0){
        _this.cleanBuyItem(controllerContainer, buyItem);
        _this.updateBuyItemTotals(controllerContainer, buyItem);
        _this.updateBuyTotals(controllerContainer);
      }
      else{
        _this.cleanNestedBuyItem(controllerContainer, buyItem);
      }
    }
  }

  updateBuyItemTotals(controllerContainer, buyItem){
    let _this = this;

    BuyCalculation.calculateBuyItem(_this, controllerContainer, buyItem);
  }

  updateBuyTotals(controllerContainer){
    let _this = this;

    BuyCalculation.calculateTotal(_this, controllerContainer);
    _this.updateBuyItemCounter();
  }

  checkBuyItemEmpty() {
    let _this = this;

    if ($("#buy_items_container .nested-fields:visible").length == 0) {
      $("#js-buy-item-no-content").show();
    } else {
      $("#js-buy-item-no-content").hide();
    }
  }

  updateBuyItemCounter() {
    let _this = this;
    let counterValue = 0;
    $('#buy_items_container .nested-fields:visible').each(function(){
      let buyItem = $(this);
      let buyItemAmountField = buyItem.find(".js-buy_item_product_amount_field");
      let buyItemAmountValue = parseFloat(buyItemAmountField.val());
      buyItemAmountValue = isNaN(buyItemAmountValue) ? 0.0 : buyItemAmountValue;
      counterValue += buyItemAmountValue;
    });

    if (counterValue == 0){
      $('#js-buy-item-counter').hide();
    }
    else {
      $('#js-buy-item-counter').show();
    }
    $('#js-buy-item-counter-value').html(counterValue);
  }

}
