
import  ApplicationController from "../application_controller.js"
import { DOMManager } from '../../lib/dom-manager.js';
import { Modals } from '../../lib/modals.js';
import { FlashMessages } from '../../lib/flash-messages.js';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect TableSaleBlueprint controller");

      setTimeout(function() {
        $('#canvas-container').data('open-floor-plan-tables-codes').forEach(function(code) {
          $('#canvas-container').data('floor-plan').changeColor(code, 'green')
        });
      }, 400);
      
    }
  }

  // Success form submit
  formSuccess(event){
    const [data, status, xhr] = event.detail;

    Modals.modalReset();
    Modals.modalHide();
    this.addOrRefreshElement(xhr);
  }

  // Fail form submit (validation fail for example)
  formError(event){
    const [data, status, xhr] = event.detail;
    let controllerContainer = this.context.element;

    Modals.modalFill(xhr.responseText);
  }


  addOrRefreshElement(xhr){
    let insertMode = xhr.getResponseHeader('X-InsertMode');
    let insertModeSourceId = xhr.getResponseHeader('X-InsertModeSourceId');
    

    if (insertMode == 'state_open'){
      $('#canvas-container').data('floor-plan').changeColor(insertModeSourceId, 'green')
    }
    else if (insertMode == 'state_close'){
      $('#canvas-container').data('floor-plan').changeColor(insertModeSourceId, 'grey')
    }
  }


}
