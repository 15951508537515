
export class Invoice {
  static totalLimit() {
    return 344488;
  }

  static credit_note_modal(event, settings, ajax) {

    let saleExternalInvoicedValue = $('.js-credit_note_external_invoiced_field').val();

    let saleClientTaxProfileValue = $('.js-credit_note_client_tax_profile_field').val();
    let saleClientDniValue = $('.js-credit_note_client_dni_field').val();
    let saleClientCuitValue = $('.js-credit_note_client_cuit_field').val();
    let saleClientAddressValue = $('.js-credit_note_client_address_field').val();
    
    let selectedOption = $('.js-client_id_field').select2('data')[0];
    let saleClientCompanyNameValue;
    if (selectedOption.data == undefined){
      saleClientCompanyNameValue = $(selectedOption.element).data('company-name');
    }
    else {
      saleClientCompanyNameValue = selectedOption.data.company_name;
    } 

    let saleTotalValue = $('.js-credit_note_amount_field').val();

    saleTotalValue = parseFloat(saleTotalValue);
    saleTotalValue = (isNaN(saleTotalValue)) ? 0.0 : saleTotalValue;

    if(saleExternalInvoicedValue == 'true'){

      let field_error = false;
      let field_error_msg = '';
      let totalLimit = this.totalLimit();

      
      if(saleClientTaxProfileValue == 'end_consumer'){
        if(saleTotalValue >= totalLimit){
          if(saleClientDniValue == ''){
            field_error = true;
            field_error_msg += 'SE SUPERÓ EL MONTO LÍMITE PARA FACTURAR SIN DATOS DE COMPRADOR\n'
            field_error_msg += '*Falta el DNI del cliente.\n'
          }
          //if(saleClientAddressValue == ''){
          //  field_error = true;
          //  field_error_msg += '*Falta la dirección del cliente.\n'
          //}
        }
      }

      if(saleClientTaxProfileValue == 'exempted_vat'){
        if(saleClientCuitValue == ''){
          field_error = true;
          field_error_msg += '*Falta el CUIT del cliente.\n'
        }
        //if(saleClientAddressValue == ''){
        //  field_error = true;
        //  field_error_msg += '*Falta la dirección del cliente.\n'
        //}
      }

      if(saleClientTaxProfileValue == 'monotax_responsable'){
        if(saleClientCuitValue == ''){
          field_error = true;
          field_error_msg += '*Falta el CUIT del cliente.\n'
        }
        //if(saleClientAddressValue == ''){
        //  field_error = true;
        //  field_error_msg += '*Falta la dirección del cliente.\n'
        //}
      }

      if(saleClientTaxProfileValue == 'enroled_responsable'){
        if(saleClientCuitValue == ''){
          field_error = true;
          field_error_msg += '*Falta el CUIT del cliente.\n'
        }
        //if(saleClientAddressValue == ''){
        //  field_error = true;
        //  field_error_msg += '*Falta la dirección del cliente.\n'
        //}
      }

      if(field_error){
        field_error_msg = 'NO SE PUEDE GENERAR EL COMPROBANTE FISCAL.\n'+field_error_msg
        field_error_msg += 'Complete los datos antes de crear la venta.\n¿DESEA PROSEGUIR DE TODOS MODOS?';
        
        if(confirm(field_error_msg)){
          settings.data = settings.data.replace('submit=', 'submit=without_invoice');
        } else {
          event.preventDefault();
          event.stopPropagation();
        };
      }
      else{

        let field_msg = '';

        if(
            ((saleClientTaxProfileValue == 'end_consumer') && (saleClientDniValue != '')) ||
            (((saleClientTaxProfileValue == 'enroled_responsable') || (saleClientTaxProfileValue == 'monotax_responsable') || (saleClientTaxProfileValue == 'exempted_vat')) && (saleClientCuitValue != ''))
          ){
          field_msg += '¿DESEA GENERAR UN COMPROBANTE FISCAL?\n';  
          field_msg += 'NOMBRE: '+saleClientCompanyNameValue+'\n';
  
          if(saleClientTaxProfileValue == 'end_consumer'){
            field_msg += 'DNI: '+saleClientDniValue+'\n';
          }
  
          if ((saleClientTaxProfileValue == 'enroled_responsable') || (saleClientTaxProfileValue == 'monotax_responsable') || (saleClientTaxProfileValue == 'exempted_vat')){
            field_msg += 'CUIT: '+saleClientCuitValue+'\n';
          }
          //field_msg += 'DIRECCIÓN: '+saleClientAddressValue;
        }
        else{
          field_msg += '¿DESEA GENERAR UN COMPROBANTE FISCAL?\n';  
        }

        if(confirm(field_msg)){
          settings.data = settings.data.replace('submit=', 'submit=with_invoice');
        } else {
          settings.data = settings.data.replace('submit=', 'submit=without_invoice');
        };
      }
    }
    
  }

  static sale_modal(event, settings, ajax) {

    let saleExternalInvoicedValue = $('.js-sale_external_invoiced_field').val();

    let saleClientTaxProfileValue = $('.js-sale_client_tax_profile_field').val();
    let saleClientDniValue = $('.js-sale_client_dni_field').val();
    let saleClientCuitValue = $('.js-sale_client_cuit_field').val();
    let saleClientAddressValue = $('.js-sale_client_address_field').val();

    let selectedOption = $('.js-client_id_field').select2('data')[0];
    let saleClientCompanyNameValue;
    if (selectedOption.data == undefined){
      saleClientCompanyNameValue = $(selectedOption.element).data('company-name');
    }
    else {
      saleClientCompanyNameValue = selectedOption.data.company_name;
    }

    let saleTotalValue = $('.js-sale_total_field').val();

    saleTotalValue = parseFloat(saleTotalValue);
    saleTotalValue = (isNaN(saleTotalValue)) ? 0.0 : saleTotalValue;

    if(saleExternalInvoicedValue == 'true'){

      let field_error = false;
      let field_error_msg = '';
      let totalLimit = this.totalLimit();

      
      if(saleClientTaxProfileValue == 'end_consumer'){
        if(saleTotalValue >= totalLimit){
          if(saleClientDniValue == ''){
            field_error = true;
            field_error_msg += 'SE SUPERÓ EL MONTO LÍMITE PARA FACTURAR SIN DATOS DE COMPRADOR\n'
            field_error_msg += '*Falta el DNI del cliente.\n'
          }
          //if(saleClientAddressValue == ''){
          //  field_error = true;
          //  field_error_msg += '*Falta la dirección del cliente.\n'
          //}
        }
      }

      if(saleClientTaxProfileValue == 'monotax_responsable'){
        if(saleClientCuitValue == ''){
          field_error = true;
          field_error_msg += '*Falta el CUIT del cliente.\n'
        }
        //if(saleClientAddressValue == ''){
        //  field_error = true;
        //  field_error_msg += '*Falta la dirección del cliente.\n'
        //}
      }

      if(saleClientTaxProfileValue == 'enroled_responsable'){
        if(saleClientCuitValue == ''){
          field_error = true;
          field_error_msg += '*Falta el CUIT del cliente.\n'
        }
        //if(saleClientAddressValue == ''){
        //  field_error = true;
        //  field_error_msg += '*Falta la dirección del cliente.\n'
        //}
      }

      if(saleClientTaxProfileValue == 'exempted_vat'){
        if(saleClientCuitValue == ''){
          field_error = true;
          field_error_msg += '*Falta el CUIT del cliente.\n'
        }
        //if(saleClientAddressValue == ''){
        //  field_error = true;
        //  field_error_msg += '*Falta la dirección del cliente.\n'
        //}
      }


      if(field_error){
        field_error_msg = 'NO SE PUEDE GENERAR EL COMPROBANTE FISCAL.\n'+field_error_msg
        field_error_msg += 'Complete los datos antes de crear la venta.\n¿DESEA PROSEGUIR DE TODOS MODOS?';
        
        if(confirm(field_error_msg)){
          if(ajax){
            settings.data = settings.data.replace('submit=', 'submit=without_invoice');
          }
          else{
            $('.local_sale_form').append('<input type="hidden" value="without_invoice" name="submit">');
          }
        } else {
          event.preventDefault();
          event.stopPropagation();
        };
      }
      else{

        let field_msg = '';

        if(
            ((saleClientTaxProfileValue == 'end_consumer') && (saleClientDniValue != '')) ||
            (((saleClientTaxProfileValue == 'enroled_responsable') || (saleClientTaxProfileValue == 'monotax_responsable') || (saleClientTaxProfileValue == 'exempted_vat')) && (saleClientCuitValue != ''))
          ){
          field_msg += '¿DESEA GENERAR UN COMPROBANTE FISCAL?\n';  
          field_msg += 'NOMBRE: '+saleClientCompanyNameValue+'\n';
  
          if(saleClientTaxProfileValue == 'end_consumer'){
            field_msg += 'DNI: '+saleClientDniValue+'\n';
          }
  
          if ((saleClientTaxProfileValue == 'enroled_responsable') || (saleClientTaxProfileValue == 'monotax_responsable') || (saleClientTaxProfileValue == 'exempted_vat')){
            field_msg += 'CUIT: '+saleClientCuitValue+'\n';
          }
          //field_msg += 'DIRECCIÓN: '+saleClientAddressValue;
        }
        else{
          field_msg += '¿DESEA GENERAR UN COMPROBANTE FISCAL?\n';  
        }

        if(confirm(field_msg)){
          if(ajax){
            settings.data = settings.data.replace('submit=', 'submit=with_invoice');
          }
          else{
            $('.local_sale_form').append('<input type="hidden" value="with_invoice" name="submit">');
          }
        } else {
          if(ajax){
            settings.data = settings.data.replace('submit=', 'submit=without_invoice');
          }
          else{
            $('.local_sale_form').append('<input type="hidden" value="without_invoice" name="submit">');
          }
        };
      }
    }
    
  }
}
