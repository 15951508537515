
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect SupplierPaymentForm controller")
    }
  }


  // Triggerado por el cambio de medio de pago 
  changePaymentMethod(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePaymentMethodData(controllerContainer);
  }

  // Triggerado por el cambio de proveedor
  changeSupplier(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateSupplierData(controllerContainer);
  }

  // Triggerado por el cambio de tipo
  changeKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateBalanceBox(controllerContainer);
  }

  // Triggerado por el el input de un monto de imputacion
  changeImputationAmount(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateAmount(controllerContainer);
  }

  changePayedCheckIds(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePayedCheckAmount(controllerContainer);
  }

  private

  // Actualiza info del proveedor:
  updateSupplierData(controllerContainer){
    let _this = this;
    let supplierIdField = $(controllerContainer).find('.js-supplier_id_field');
    let supplierId = supplierIdField.select2('val');
    let url = $(supplierIdField).data('url-two');

    let supplierBalanceBox = $(controllerContainer).find('#supplier_balance_box');
    let supplierBalanceContainer = $(controllerContainer).find('.js-supplier_balance_container');
    let globalBalanceBox = $(controllerContainer).find('#js-global-balance');

    if(supplierId != ''){
      url = url.replace('/the_id', '/'+supplierId);
      $.getJSON(url, function(data) {
        let balance = data.balance_ars;
        balance = parseFloat(balance);
        balance = (isNaN(balance)) ? 0.0 : balance;

        supplierBalanceContainer.html(_this.formatToCurrency(balance));

        globalBalanceBox.find('.form-group').hide();
        $('#supplier_payment_imputations_container .list-group-item').hide();
        $('#supplier_payment_imputations_container .js-supplier_payment_imputation_amount').val(0);
        $('.js-payment_method_payed_check_ids_field').prop('checked', false);

        _this.updateAmount(controllerContainer);

        $(data.balance_ars_array).each(function(){
          balance = parseFloat(this[1]);
          balance = (isNaN(balance)) ? 0.0 : balance;
          $(controllerContainer).find('.js-supplier_balance_container_sale_point_'+this[0].id).html(_this.formatToCurrency(balance))
          $(controllerContainer).find('.js-supplier_balance_container_sale_point_'+this[0].id).closest('.form-group').show();
          $(controllerContainer).find('#js-supplier-payment-imputation-container-'+this[0].id).show();
        });

        supplierBalanceBox.fadeIn();
      });      
    }
  }

  updateAmount(controllerContainer){
    let _this = this;

    let supplierPaymentAmountField = $(controllerContainer).find('#supplier_payment_amount');

    let totalAmount = 0.0;
    $('#js-supplier_payment_imputations_container .js-supplier_payment_imputation_amount').each(function(){
      let amount = parseFloat($(this).val());
      totalAmount += (isNaN(amount)) ? 0.0 : amount;
    });

    supplierPaymentAmountField.val(totalAmount.toFixed(2));
  }

  updatePayedCheckAmount(controllerContainer){
    let _this = this;

    let supplierPaymentAmountField = $(controllerContainer).find('#supplier_payment_amount');

    let totalAmount = 0.0;
    $('.payment_method_third_check_data .js-payment_method_payed_check_ids_field:checked').each(function(){
      let amount = parseFloat($(this).data('amount'));
      totalAmount += (isNaN(amount)) ? 0.0 : amount;
    });

    supplierPaymentAmountField.val(totalAmount.toFixed(2));
  }



  // Actualiza info del balance:
  updateBalanceBox(controllerContainer){
    let _this = this;
    let kindValue = $(controllerContainer).find('.js-kind_field').val();

    let supplierPaymentAmountField = $(controllerContainer).find('#supplier_payment_amount');
    let supplierPaymentPaymentMethodField = $(controllerContainer).find('#supplier_payment_payment_method');
    let localBalanceBox = $(controllerContainer).find('#js-local-balance');
    let globalBalanceBox = $(controllerContainer).find('#js-global-balance');
    let supplierPaymentImputationsContainer = $(controllerContainer).find('#js-supplier_payment_imputations_container');

    if(kindValue == 'local'){
      supplierPaymentAmountField.removeAttr('readonly');
      globalBalanceBox.hide();
      supplierPaymentImputationsContainer.hide();
      $(controllerContainer).find('.js-supplier_payment_imputation_amount').val(0);
      supplierPaymentAmountField.val(0);
      localBalanceBox.fadeIn();
      if (supplierPaymentPaymentMethodField.attr('type') != 'hidden'){
        supplierPaymentPaymentMethodField.find("option[value='third_check']").removeAttr('disabled');
        supplierPaymentPaymentMethodField.trigger('select2:select');
        supplierPaymentPaymentMethodField.val('').trigger('change');
      }
      
    } else if(kindValue == 'global'){
      supplierPaymentAmountField.attr('readonly', 'readonly');
      supplierPaymentAmountField.val(0);
      localBalanceBox.hide();
      supplierPaymentImputationsContainer.fadeIn();
      globalBalanceBox.fadeIn();
      if (supplierPaymentPaymentMethodField.attr('type') != 'hidden'){
        supplierPaymentPaymentMethodField.find("option[value='third_check']").attr('disabled','disabled');
        supplierPaymentPaymentMethodField.trigger('select2:select');
        supplierPaymentPaymentMethodField.val('').trigger('change');
      }
    }
  }


  // Actualiza info del pago:
  updatePaymentMethodData(controllerContainer){
    let _this = this;
    let paymentMethodValue = $(controllerContainer).find('.js-payment_method_field').val();
    let paymentMethodCashData = $(controllerContainer).find('.payment_method_cash_data');
    let paymentMethodBankTransferData = $(controllerContainer).find('.payment_method_bank_transfer_data');
    let paymentMethodOtherData = $(controllerContainer).find('.payment_method_other_data');
    let paymentMethodCheckData = $(controllerContainer).find('.payment_method_check_data');
    let paymentMethodThirdCheckData = $(controllerContainer).find('.payment_method_third_check_data');
    let supplierPaymentAmountField = $(controllerContainer).find('#supplier_payment_amount');

    if (paymentMethodValue == 'cash'){
      paymentMethodCashData.show();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      supplierPaymentAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'bank_transfer'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      supplierPaymentAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'debit_card'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      supplierPaymentAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'other'){
      paymentMethodOtherData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      supplierPaymentAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'check'){
      paymentMethodCheckData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('0');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      supplierPaymentAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'third_check'){
      paymentMethodThirdCheckData.show();
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      supplierPaymentAmountField.attr('readonly', 'readonly');
      supplierPaymentAmountField.val(0);
    }
    else{
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      supplierPaymentAmountField.removeAttr('readonly');
    }
  }
}
