
import  ApplicationController from "../application_controller.js"
import { DOMManager } from '../../lib/dom-manager.js';
import { Modals } from '../../lib/modals.js';
import { FlashMessages } from '../../lib/flash-messages.js';
import { Invoice } from '../../lib/invoice.js';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect CreditNote controller")
    }
  }

  // Before form submit
  beforeSendForm(event){
    const [data, settings] = event.detail;
    let _this = this;    
    if(!$(event.target).is('#js-add-button')){
      let ajax = $(event.target).is('#credit_note_form');
      Invoice.credit_note_modal(event, settings, ajax);
    }
  }

  // Success status element
  statusSuccess(event) {
    const [data, status, xhr] = event.detail;
    const targetDomId = event.target.dataset.domId;
    DOMManager.removeRow(targetDomId, '#FFFAAA');
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

  // Fail status element
  statusError(event) {
    const [data, status, xhr] = event.detail;

    event.stopPropagation();
    if (xhr.responseText != ''){
      Modals.modalShow();
      Modals.modalSize('modal-lg');
      Modals.modalFill(xhr.responseText);
    }
    FlashMessages.message(this.parseFlashMessage(xhr));
  }
}
