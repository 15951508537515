
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect ProfilesForm controller");
    }
  }

  changeRole(){
    if(($('#profile_role').val() != 'master_franchisor') && ($('#profile_role').val() != 'businessman')){
      $('#js-sale_point_ids-container').fadeOut();
      $('#profile_sale_point_ids').val('').trigger('change');
    }
    else{
      $('#js-sale_point_ids-container').fadeIn();
    }

    if($('#profile_role').val() != 'employee'){
      $('#js-employee_role_id-container').fadeOut();
      $('#profile_employee_role_id').val('').trigger('change');
    }
    else{
      $('#js-employee_role_id-container').fadeIn();
    }
  }

}