
export class FlashMessages {
  
  static message(json) {
    if ($('#flash-messages').length == 0){
      $("body").append("<div id='flash-messages'/>");
    }
    
    $.each(json, function (key, value) {
    
      let class_extra = '';
      switch (key) {
        case 'info_persistent_hide':
          key = 'info_persistent' 
          class_extra = 'hide' 
          break;
      }

      value = value.replace('Ã¡', 'á');
      value = value.replace('Ã©', 'é');
      value = value.replace('Ã*', 'í');
      value = value.replace('Ã³', 'ó');
      value = value.replace('Ãº', 'ú');
      value = value.replace('Ã', 'Á');
      value = value.replace('Ã‰', 'É');
      value = value.replace('Ã', 'Í');
      value = value.replace('Ã“', 'Ó');
      value = value.replace('Ãš', 'Ú');
      value = value.replace('Ã±', 'ñ');
      value = value.replace('Ã‘', 'Ñ');

      $('#flash-messages').append("<div class='alert alert-"+key+" "+class_extra+" fade alert-dismissible ml-auto'>"+value+"<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>");
    });
    
    setTimeout(function () {
      $('#flash-messages .alert:not(.alert-info_persistent)')
        .addClass('show')
        .delay(10000)
        .queue(function(){
          $(this).alert('close').dequeue();
        });

      $('#flash-messages .alert.alert-info_persistent')
        .addClass('show')
        .delay(1500000)
        .queue(function(){
          $(this).alert('close').dequeue();
        });
    }, 100);
  }
}
