
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect TableSaleForm controller");

      let _this = this;
      let controllerContainer = _this.context.element;
    }
  }



  // Triggerado por el click para ver observaciones
  setStateClose(){
    let _this = this;
    let controllerContainer = _this.context.element;

    $('#table_sale_form').data('table-sale-state', 'state_close');
  }

}
