import { FlashMessages } from '../lib/flash-messages.js';
import '../vendor/jquery.scannerdetection.js'

export class Scannerdetection {
  
  initialize() {
    $(document).scannerDetection({
      timeBeforeScanTest: 200,  // wait for the next character for upto 200ms
      avgTimeByChar: 60,        // it's not a barcode if a character takes longer than 60ms
      minLength: 4,
      //stopPropagation: true,
      //preventDefault: true,
      //endChar: [],
      onComplete: function(barcode, qty){
        let barcode_values = handleBarcode(barcode);

        //console.log('readed -> TYPE:'+ barcode_values.type+ ' - KIND:'+barcode_values.kind+ ' - CODE:'+barcode_values.code+ ' - AMOUNT:'+barcode_values.amount);

        let client_id = $('#sale_client_id').val();

        if ($('.local_sale_form').length > 0){
          let url = $('#nested_sale_item_data .js-sale_item_product_id').data('url-three');
          url = url.replace("/the_id", "/" + barcode_values.code);
          url = url + '&client_id='+client_id;


          $.ajaxSetup({ async: false });
          $.getJSON(url, function (data) {
            let productId = data.id;
            let productName = data.name;
            let productSymbol = data.measurement_category_symbol;
            let productPrice = data.price;
            let productPriceKind = data.price_kind;
            let productTaxPerc = data.tax_perc;

            $('#nested_sale_item_data .js-sale_item_symbol_container').html(productSymbol);
            $('#nested_sale_item_data .js-sale_item_amount_field').val(barcode_values.amount);
            $('#nested_sale_item_data .js-sale_item_price_field').val(productPrice);
            $('#nested_sale_item_data .js-sale_item_product_id').append('<option selected="selected" value="'+productId+'">'+productName+'<option>').trigger('change.select2');
            $('#nested_sale_item_data .js-sale_item_tax_perc_field').val(productTaxPerc);
            $('#nested_sale_item_data .js-sale_item_price_kind_field').val(productPriceKind);

            if(productPriceKind == 'price_kind_fix'){
              $('#nested_sale_item_data .js-sale_item_price_kind_field').html("<i class='fas fa-dollar-sign'></i>");
            }
            else {
              $('#nested_sale_item_data .js-sale_item_price_kind_field').html("<i class='fas fa-percent'></i>");
            }


            setTimeout(function () {
              $('#js-add_sale_item').trigger('click');
            }, 250);


          }).fail(function() { 
            FlashMessages.message({"alert": "No existe el producto con el CODIGO DE BARRA "+barcode_values.code });
          });

          $.ajaxSetup({ async: true });

        }
      }
    });


    function handleBarcode(barcode){
      let barcode_clean = barcode.replace('\r', '');
      let barcode_values = {};

      let barcode_length = barcode_clean.length;

      if(barcode_length == 13){
        barcode_values.type = 'EAN-13';
        if (barcode_clean.substr(0, 2) == '20'){
          barcode_values.kind = 'variable_weight';
          barcode_values.code = barcode_clean.substr(2, 5);
          barcode_values.amount = barcode_clean.substr(7, 5);

          barcode_values.amount = parseInt(barcode_values.amount);
          if (isNaN(barcode_values.amount)){
            barcode_values.amount = 1;
          }
          else{
            barcode_values.amount = barcode_values.amount / 1000;
          }
        }
        else{
          barcode_values.kind = 'unit';
          barcode_values.code = barcode_clean;
          barcode_values.amount = 1;
        }
        
      } 
      else if(barcode_length == 12){
        barcode_values.type = 'UPC-A';
        if (barcode_clean.substr(0, 1) == '2'){
          barcode_values.kind = 'variable_weight';
          barcode_values.code = barcode_clean.substr(1, 5);
          barcode_values.amount = barcode_clean.substr(6, 5);

          barcode_values.amount = parseInt(barcode_values.amount);
          if (isNaN(barcode_values.amount)){
            barcode_values.amount = 1;
          }
          else{
            barcode_values.amount = barcode_values.amount / 1000;
          }
        }
        else{
          barcode_values.kind = 'unit';
          barcode_values.code = barcode_clean;
          barcode_values.amount = 1;
        }

      }
      else{
        barcode_values['type'] = 'unknown';
        barcode_values['kind'] = 'unit';
        barcode_values['code'] = barcode_clean;
        barcode_values['amount'] = 1;        
      }

      return barcode_values;
    }

  }
}
