import consumer from "./consumer"

let path = window.location.pathname.slice(1);
let current_entity_param = '';
if ((path != '') && (path != undefined)){
  current_entity_param = path.split('/',1)[0];
}

consumer.subscriptions.create({channel: "NotificationChannel", current_entity: current_entity_param}, {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the notification channel "+JSON.parse(this.identifier).current_entity);
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Disconnected from the notification channel "+JSON.parse(this.identifier).current_entity);
  },

  received(data) {
    $('#js-notificationNav').html(data.message);
  }
});
