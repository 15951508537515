
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect MovementsForm controller");
    }
  }


   // Triggerado por el cambio de medio de pago 
   changePaymentMethod(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePaymentMethodData(controllerContainer);
  }


  // Triggerado por el cambio de cuenta 
  changeFromLedgerAccount(){
    let _this = this;
    let controllerContainer = _this.context.element;
    let movementMainAccountCategoryIdField = $(controllerContainer).find('#movement_main_account_category_id');

    if ($(controllerContainer).find('#movement_from_ledger_account_id').val() != ''){
      $(controllerContainer).find('#js-main_account_category_id-container').fadeIn();
    }
    else{
      $(controllerContainer).find('#js-main_account_category_id-container').fadeOut();
    }

    let destinationKindValue = $(controllerContainer).find('.js-destination_kind_field').val();
    let ledgerAccountIdParam = '';

    if(destinationKindValue == 'global'){
      ledgerAccountIdParam = '#js-movement_from_ledger_account_id_global';
    } else if(destinationKindValue == 'local'){
      ledgerAccountIdParam = '#js-movement_from_ledger_account_id_local';
    }

    movementMainAccountCategoryIdField.data('send-params', {"ledger_account_id": ledgerAccountIdParam});
    movementMainAccountCategoryIdField.val('').trigger('change');
  }

  changeToLedgerAccount(){
    let _this = this;
    let controllerContainer = _this.context.element;

    if ($(controllerContainer).find('#movement_to_ledger_account_id').val() != ''){
      $(controllerContainer).find('#js-main_account_category_id-container').fadeIn();
    }
    else{
      $(controllerContainer).find('#js-main_account_category_id-container').fadeOut();
    }
    $(controllerContainer).find('#movement_main_account_category_id').val('').trigger('change');
  }


  changePayedCheckIds(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateAmount(controllerContainer);
  }


  // Triggerado por el cambio de tipo
  changeDestinationKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateFromToContainer(controllerContainer);
  }

  changeThirdCheckPayedCheckIds(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePayedCheckAmount(controllerContainer);
  }

  private


  updateFromToContainer(controllerContainer){
    let _this = this;
    let destinationKindValue = $(controllerContainer).find('.js-destination_kind_field').val();
    

    let movementFromToGlobalBox = $(controllerContainer).find('#js-movement_from_to_global');
    let movementFromToLocalBox = $(controllerContainer).find('#js-movement_from_to_local');

    let movementToLedgerAccountIdGlobalField = $(controllerContainer).find('#js-movement_to_ledger_account_id_global');
    let movementFromLedgerAccountIdGlobalField = $(controllerContainer).find('#js-movement_from_ledger_account_id_global');
    let movementToLedgerAccountIdLocalField = $(controllerContainer).find('#js-movement_to_ledger_account_id_local');
    let movementFromLedgerAccountIdLocalField = $(controllerContainer).find('#js-movement_from_ledger_account_id_local');


    if(destinationKindValue == 'local'){
      movementFromToGlobalBox.hide();
      movementToLedgerAccountIdGlobalField.attr('disabled', true);
      movementFromLedgerAccountIdGlobalField.attr('disabled', true);
      movementToLedgerAccountIdLocalField.removeAttr('disabled');
      movementFromLedgerAccountIdLocalField.removeAttr('disabled');
      movementFromToLocalBox.show();
    } else if(destinationKindValue == 'global'){
      movementFromToLocalBox.hide();
      movementToLedgerAccountIdGlobalField.removeAttr('disabled');
      movementFromLedgerAccountIdGlobalField.removeAttr('disabled');
      movementToLedgerAccountIdLocalField.attr('disabled', true);
      movementFromLedgerAccountIdLocalField.attr('disabled', true);
      movementFromToGlobalBox.show();
    }
  }

  updateAmount(controllerContainer){
    let _this = this;
    let movementAmountField = $(controllerContainer).find('#movement_amount');

    let totalAmount = 0.0;
    $('#js-payed_check_ids_container .js-payed_check_checkbox:checked').each(function(){
      let amount = parseFloat($(this).data('amount'));
      totalAmount += (isNaN(amount)) ? 0.0 : amount;
    });

    movementAmountField.val(totalAmount.toFixed(2));
  }

  updatePayedCheckAmount(controllerContainer){
    let _this = this;
    let movementAmountField = $(controllerContainer).find('#movement_amount');

    let totalAmount = 0.0;
    $('.payment_method_third_check_data .js-payment_method_payed_check_ids_field:checked').each(function(){
      let amount = parseFloat($(this).data('amount'));
      totalAmount += (isNaN(amount)) ? 0.0 : amount;
    });

    movementAmountField.val(totalAmount.toFixed(2));
  }


  // Actualiza info del pago:
  updatePaymentMethodData(controllerContainer){
    let _this = this;
    let paymentMethodValue = $(controllerContainer).find('.js-payment_method_field').val();
    let paymentMethodCashData = $(controllerContainer).find('.payment_method_cash_data');
    let paymentMethodBankTransferData = $(controllerContainer).find('.payment_method_bank_transfer_data');
    let paymentMethodOtherData = $(controllerContainer).find('.payment_method_other_data');
    let paymentMethodCheckData = $(controllerContainer).find('.payment_method_check_data');
    let paymentMethodThirdCheckData = $(controllerContainer).find('.payment_method_third_check_data');
    let movementAmountField = $(controllerContainer).find('#movement_amount');

    if (paymentMethodValue == 'cash'){
      paymentMethodCashData.show();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'bank_transfer'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'debit_card'){
      paymentMethodBankTransferData.show();
      paymentMethodCashData.hide();
      paymentMethodOtherData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'other'){
      paymentMethodOtherData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodCheckData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'check'){
      paymentMethodCheckData.show();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('0');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.removeAttr('readonly');
    }
    else if(paymentMethodValue == 'third_check'){
      paymentMethodThirdCheckData.show();
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.attr('readonly', 'readonly');
    }
    else{
      paymentMethodCheckData.hide();
      paymentMethodCashData.hide();
      paymentMethodBankTransferData.hide();
      paymentMethodOtherData.hide();
      paymentMethodThirdCheckData.hide();
      $('.js-payment_method_bank_transfer_bank_account_id_field').val('').trigger('change');
      $('.js-payment_method_cash_cashbox_id_field').val('').trigger('change');
      $('.js-payment_method_other_custom_payment_method_id_field').val('').trigger('change');
      $('.js-payment_method_check_destroy_field').val('1');
      $('.js-payment_method_payed_check_ids_field').prop('checked', false);
      _this.updatePayedCheckAmount(controllerContainer);
      movementAmountField.removeAttr('readonly');
    }
  }

}
