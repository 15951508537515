
import  ApplicationController from "../application_controller.js"
import { FlashMessages } from '../../lib/flash-messages.js';
import { Modals } from '../../lib/modals.js';

let time = 0;

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect Movement controller");

      let _this = this;
      let controllerContainer = _this.context.element;
      $('#filter_query').focus()
    }
  }

  // Success form submit
  formSuccess(event){
    const [data, status, xhr] = event.detail;
    let _this = this;
    let controllerContainer = this.context.element;

    Modals.modalReset();
    Modals.modalHide();
    _this.addOrRefreshElement(xhr);
    _this.refreshAccountBalance(controllerContainer);
    FlashMessages.message(_this.parseFlashMessage(xhr));
  }

  refreshAccountBalance(controllerContainer){
    let _this = this;
    let accountBalanceContainer = $(controllerContainer).find('#js-account_balance_container');
    
    if (accountBalanceContainer.length != 0){
      let refreshUrl = accountBalanceContainer.data('url');
      $.getJSON(refreshUrl, function(data) {
        let balance = data.balance;
        balance = parseFloat(balance);
        balance = (isNaN(balance)) ? 0.0 : balance;

        accountBalanceContainer.html(_this.formatToCurrency(balance));
      });
    }
  }

  // TO DO: Optimizar para que no se prenda fuego todo
  handleFilterInput(event){
    let target = $(event.currentTarget);
    let form = target.closest('form');

    clearTimeout(time);
    time = setTimeout(function() {
      form.find('button').trigger('click')
    }, 500);
  }

}