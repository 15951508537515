

export class FloorPlanObject {

  constructor(floorPlanLayout, dataJson='') {
    let _this = this;
    
    _this.floorPlanLayout = floorPlanLayout;
    _this.dataJson = dataJson;
  }

  loadOrCreateShape () {
    let _this = this;

    if(
        (_this.dataJson != undefined) && 
        (_this.dataJson != null) && 
        (_this.dataJson != '')
      ){
        _this.group = Konva.Node.create(_this.dataJson);
        _this.group.draggable(false);
        _this.box = _this.group.findOne('.Shape')
        _this.kind = _this.box.getAttr('kind')
        _this.textNode = _this.group.findOne('.Label')
        _this.textNode2 = _this.group.findOne('.Id')
    }
    else {
      _this.createShape();
    }
    
    _this.floorPlanLayout.layer.add(_this.group);
  }


  bindMainEvents () {
    let _this = this;

    //----------------------------- START EDIT MODE
    if(_this.floorPlanLayout.mode == 'edit'){
      _this.group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
      });
      _this.group.on('mouseout', function () {
        document.body.style.cursor = 'default';
      });
      _this.group.on('dragend', function () {
        _this.floorPlanLayout.saveDataJson();
      });
      _this.group.on('transform', function () {
        let newWidth = _this.box.width() * _this.group.scaleX();
        let newHeight = _this.box.height() * _this.group.scaleY();
  
        if ((newWidth < _this.settings.width-1) || (newHeight < _this.settings.height-1)) {
          _this.floorPlanLayout.tr.stopTransform();
          if (newWidth < _this.settings.width-1) {
            _this.group.setAttrs({
              scaleX: 1,
            });
            _this.box.setAttrs({
              width: _this.settings.width
            });
          }
          if (newHeight < _this.settings.height-1) {
            _this.group.setAttrs({
              scaleY: 1,
            });
            _this.box.setAttrs({
              height: _this.settings.height
            });
          }
        }
        else{
          _this.group.setAttrs({
            scaleX: 1,
            scaleY: 1,
          });
          _this.box.setAttrs({
            width: newWidth,
            height: newHeight
          });
        }

      });
  
      _this.group.on('transformend', function () {
        _this.floorPlanLayout.saveDataJson();
      });

      _this.group.on('dragmove', function (e) {
        const box = _this.group.getClientRect()
        const absPos = _this.group.getAbsolutePosition();
  
        // where are shapes inside bounding box of all shapes?
        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;
  
        const newAbsPos = { ...absPos };
        if (box.x < 0) {
          newAbsPos.x = -offsetX;
        }
        if (box.y < 0) {
          newAbsPos.y = -offsetY;
        }
  
        if (box.x + box.width > _this.floorPlanLayout.stage.width()) {
          newAbsPos.x = ((_this.floorPlanLayout.stage.width() - box.width - offsetX));
        }
        if (box.y + box.height > _this.floorPlanLayout.stage.height()) {
          newAbsPos.y = ((_this.floorPlanLayout.stage.height() - box.height - offsetY));
        }
        _this.group.setAbsolutePosition(newAbsPos);

      });
  
      _this.box.on('pointerdown', function (e) {
        _this.floorPlanLayout.currentShape = e.target.getParent();
        if(!_this.floorPlanLayout.currentShape.draggable()){
          _this.floorPlanLayout.clearDragSelection();
          _this.floorPlanLayout.closeMenuNodes();
        }
      });
      
      _this.box.on('pointerdblclick', function (e) {
        _this.floorPlanLayout.currentShape = e.target.getParent();
  
        _this.floorPlanLayout.clearDragSelection();
        _this.floorPlanLayout.openMenuNodeMember(_this.kind);
      });
    }
    //----------------------------- END EDIT MODE

    //----------------------------- START NORMAL MODE
    if(_this.floorPlanLayout.mode == 'normal'){

    }
    //----------------------------- END NORMAL MODE

  }



}
