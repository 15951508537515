
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect ProductForm controller");

      let _this = this;
      let controllerContainer = this.context.element;

      $(controllerContainer).on('cocoon:after-insert', '#images', function(e, insertedItem, originalEvent) {
        setTimeout(function(){ $(insertedItem).find('.image-cropper-file-trigger').trigger('click'); }, 200);
      });

      $('#images .nested-fields').each(function(){
        setTimeout(function(){ $(this).find('.image-cropper-file-trigger').trigger('click'); }, 200);
      });

      $(controllerContainer).on('cocoon:after-insert', '#product_composition_items_container', function(e, insertedItem, originalEvent) {
        _this.changecompoundId(insertedItem);
        _this.bindCompoundCost(insertedItem);
      });

      $(controllerContainer).on('cocoon:after-insert', '#product_costs_container', function(e, insertedItem, originalEvent) {
        _this.addSourceToSupplierAddButton(insertedItem);
        _this.bindProductCost(insertedItem);
        _this.updateCostSection(controllerContainer);
        _this.updateNetContainer(controllerContainer);
        _this.updateProductCostsSalePointContainer(controllerContainer);
      });


      $(controllerContainer).on('cocoon:after-insert', '#product_prices_container', function(e, insertedItem, originalEvent) {
        _this.bindProductPrice(insertedItem);

        _this.updateCompositionSection(controllerContainer);
        _this.updateCostSection(controllerContainer);
        _this.updatePriceSection(controllerContainer);

        _this.updateProductPricesSalePointContainer(controllerContainer);
      });


      $(controllerContainer).on('cocoon:after-remove', '#product_composition_items_container', function(e, insertedItem, originalEvent) {
        _this.calculateTotalProductCompositionItems();
      });      
      
      $(controllerContainer).on('cocoon:after-remove', '#product_costs_container', function(e, insertedItem, originalEvent) {
        _this.calculateTotalProductCost();
      });


      $('#product_composition_items_container .nested-fields').each(function(){
        _this.changecompoundId(this);
        _this.bindCompoundCost(this);
        _this.calculateCompoundPrice(this);        
      });


      $('#product_costs_container .nested-fields').each(function(){
        _this.addSourceToSupplierAddButton(this);
        _this.bindProductCost(this);
      });

      $('#product-prices .nested-fields').each(function(){
        _this.bindProductPrice(this);
      });

      _this.updateStockSection(controllerContainer);

      _this.updateCompositionSection(controllerContainer);
      _this.updateCostSection(controllerContainer);
      _this.updateImageSection(controllerContainer);
      _this.updatePriceSection(controllerContainer);

      _this.updateNetContainer(controllerContainer);
      _this.updateProductPricesSalePointContainer(controllerContainer);
      _this.updateProductCostsSalePointContainer(controllerContainer);
      _this.updateBrandFields(controllerContainer);
    }
  }

  changeProductCategory(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateProductFeaturesData(controllerContainer);
  }



  changeCostTax(){
    let _this = this;
    let controllerContainer = _this.context.element;

    $('#product_costs_container .nested-fields').each(function(){
      _this.calculateProductCostTotal(this);
    });
    
    _this.calculateTotalProductCost();
  }
  
  changeOtherCost(){
    let _this = this;
    let controllerContainer = _this.context.element;

    $('#product_costs_container .nested-fields').each(function(){
      _this.calculateProductCostTotal(this);
    });

    _this.calculateTotalProductCost();
  }

  changeCostTaxDropDown(event){
    let _this = this;
    let controllerContainer = _this.context.element;

    event.preventDefault();
    _this.updateProductCostTaxKind(event.currentTarget);

    $('#product_costs_container .nested-fields').each(function(){
      _this.calculateProductCostTotal(this);
    });

    _this.calculateTotalProductCost();
  }

  changeOtherCostDropDown(event){
    let _this = this;
    let controllerContainer = _this.context.element;

    event.preventDefault();
    _this.updateProductOtherCostKind(event.currentTarget);

    $('#product_costs_container .nested-fields').each(function(){
      _this.calculateProductCostTotal(this);
    });

    _this.calculateTotalProductCost();
  }

  changeTransformationCoefficient(){
    let _this = this;
    let controllerContainer = _this.context.element;

    $('#product_costs_container .nested-fields').each(function(){
      _this.calculateProductCostTotal(this);
    });

    _this.calculateTotalProductCost();
  }

  changeAppliesStock(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateStockSection(controllerContainer);
  }
  
  changeProductKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateBrandFields(controllerContainer);
    _this.setBrandFields(controllerContainer);

    _this.updateStockSection(controllerContainer);
    _this.setStockSection(controllerContainer);

    _this.updateCompositionSection(controllerContainer);
    _this.setCompositionSection(controllerContainer);

    _this.updateCostSection(controllerContainer);
    _this.setCostSection(controllerContainer);

    _this.updateImageSection(controllerContainer);
    
    _this.updatePriceSection(controllerContainer);
    _this.setPriceSection(controllerContainer);
    
    _this.setPriceFields(controllerContainer);
  }

  
  changeFixedMarkup(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePriceSection(controllerContainer);
    _this.setPriceFields(controllerContainer);
  }

    
  changeProductCostFixedMarkup(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateCostSection(controllerContainer);
    _this.setCostFields(controllerContainer);
  }


  changePricePercSaleTotal(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updatePriceSection(controllerContainer);
    _this.setPriceFields(controllerContainer);
  }

  changeAdvancedProductPrices(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setProductPricesSalePointContainer(controllerContainer);
    _this.updateProductPricesSalePointContainer(controllerContainer);
  }


  changeComposeCostPerNet(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateNetContainer(controllerContainer);
  }

  changeAdvancedProductCosts(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setProductCostsSalePointContainer(controllerContainer);
    _this.updateProductCostsSalePointContainer(controllerContainer);
  }



  changeTaxPerc(){
    let _this = this;
    let controllerContainer = _this.context.element;
    let composeCostPerNetField = $(controllerContainer).find('.js-compose_cost_per_net-field');

    $('#product_costs_container .nested-fields').each(function(){
      if((composeCostPerNetField.is(':checked')) || (composeCostPerNetField.val() == 'true')){
        _this.calculateProductCostCost(this);
      }
      else{
        _this.calculateProductCostNet(this);
      }

      _this.calculateProductCostTotal(this);
    });
    
    _this.calculateTotalProductCost();
  }

  changeKind(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateCompositionSection(controllerContainer);
    _this.setCompositionSection(controllerContainer);

    _this.updateCostSection(controllerContainer);
    _this.setCostSection(controllerContainer);
    
    _this.updatePriceSection(controllerContainer);

    _this.setPriceSection(controllerContainer);

    _this.setPriceFields(controllerContainer);
  }

  private

  updateProductFeaturesData(controllerContainer){
    let _this = this;
    let product_category_id_field = $(controllerContainer).find('.js-product_category_id_field');
    let product_category_id = product_category_id_field.select2('val');
    let url = $(product_category_id_field).data('url-two');
    if((product_category_id != '') && (product_category_id != undefined)){
      url = url.replace('/the_id', '/'+product_category_id);
      $.getScript(url);
    } else{
      $('#js-product_features_container').html('');    
    }
  }

  updateBrandFields(controllerContainer){
    let _this = this
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let name_container = $(controllerContainer).find('.js-name_container');
    let brand_container = $(controllerContainer).find('.js-brand_container');
    let product_category_id_container = $(controllerContainer).find('.js-product_category_id_container');


    if(productKindValue == 'product_kind_retail'){
      name_container.removeClass('col-md-6');
      name_container.addClass('col-md-4');
      brand_container.show();
      product_category_id_container.removeClass('col-md-6');
      product_category_id_container.addClass('col-md-4');
    }
    else {
      name_container.removeClass('col-md-4');
      name_container.addClass('col-md-6');
      brand_container.hide();
      product_category_id_container.removeClass('col-md-4');
      product_category_id_container.addClass('col-md-6');
    }
  }

  setBrandFields(controllerContainer){
    let _this = this
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let brand_field = $(controllerContainer).find('#product_brand');

    if(productKindValue != 'product_kind_retail'){
      brand_field.val('');
    }
  }

  updateStockSection(controllerContainer){
    let _this = this
    let productStockNav = $(controllerContainer).find('.js-product-stock-nav-item');
    let appliesStockField = $(controllerContainer).find('#product_applies_stock');    
    let stockValuesContainer = $(controllerContainer).find('.js-stock_values-container');
    let stockBehaviorContainer = $(controllerContainer).find('.js-stock_behavior-container');
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();


    if (productKindValue == 'product_kind_retail') {
      stockBehaviorContainer.hide();
      productStockNav.fadeIn();
      if((appliesStockField.is(':checked')) || (appliesStockField.val() == 'true')){
        stockValuesContainer.fadeIn();
      }
      else{
        stockValuesContainer.hide();
      }
    }
    else if (productKindValue == 'product_kind_manufacturable'){
      productStockNav.fadeIn();
      if((appliesStockField.is(':checked')) || (appliesStockField.val() == 'true')){
        stockBehaviorContainer.fadeIn();
        stockValuesContainer.fadeIn();
      }
      else{
        stockBehaviorContainer.hide();
        stockValuesContainer.hide();
      }
    }
    else if (productKindValue == 'product_kind_service'){
      stockBehaviorContainer.hide();
      stockValuesContainer.hide();
      productStockNav.hide();
    }
  }


  setStockSection(controllerContainer){
    let _this = this
    let appliesStockField = $(controllerContainer).find('#product_applies_stock');    
    let stockBehaviorField = $(controllerContainer).find('.js-stock_behavior-field');
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let productMeasurementCategoryIdField = $(controllerContainer).find('.js-product_measurement_category_id-field');
    let productPurchaseMeasurementCategoryIdField = $(controllerContainer).find('.js-product_purchase_measurement_category_id-field');

    if (productKindValue == 'product_kind_service'){
      appliesStockField.prop('checked', false);
      let unidad_id = '2';
      productMeasurementCategoryIdField.val(unidad_id).trigger('select2:select');
      productPurchaseMeasurementCategoryIdField.val(unidad_id).trigger('select2:select');
    }
    else{
      appliesStockField.prop('checked', true);
      productMeasurementCategoryIdField.val('').trigger('select2:select');
      productPurchaseMeasurementCategoryIdField.val('').trigger('select2:select');      
    }
    stockBehaviorField.val('').trigger('select2:select');
  }


  updateCompositionSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let productCompositionNav = $(controllerContainer).find('.js-product-composition-nav-item');
    let stockBehaviorValue = $(controllerContainer).find('.js-stock_behavior-field').val();
    let fabricableSalePointIdsContainer = $(controllerContainer).find('#js-fabricable_sale_point_ids_container');


    if (productKindValue == 'product_kind_retail') {
      productCompositionNav.hide();
    }
    else if (productKindValue == 'product_kind_manufacturable'){
      productCompositionNav.fadeIn();
      if (stockBehaviorValue == 'fabricable'){
        fabricableSalePointIdsContainer.fadeIn();
      }
      else{
        fabricableSalePointIdsContainer.hide();
      }
    }
    else if (productKindValue == 'product_kind_service'){
      productCompositionNav.hide();
    }
  }

  setCompositionSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();

    if (productKindValue != 'product_kind_manufacturable') {
      $(controllerContainer).find('#products_raw_materials_container .remove_fields').each(function(){
        $(this).trigger('click');
      });
      $(controllerContainer).find('#product_composition_items_container .remove_fields').each(function(){
        $(this).trigger('click');
      });
    }
  }

  updateCostSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let stockBehaviorValue = $(controllerContainer).find('.js-stock_behavior-field').val();
    let productCostNav = $(controllerContainer).find('.js-product-costs-nav-item');
    let productCostFixedMarkupContainer = $(controllerContainer).find('.js-product_cost_fixed_markup-container');


    if (productKindValue == 'product_kind_retail') {
      productCostNav.fadeIn();
      productCostFixedMarkupContainer.hide();
    }
    else if (productKindValue == 'product_kind_manufacturable'){
      if (stockBehaviorValue == 'fabricable'){
        productCostNav.fadeIn();
        productCostFixedMarkupContainer.fadeIn();   
      }
      else{
        productCostNav.hide();
        productCostFixedMarkupContainer.hide();
      }
    }
    else if (productKindValue == 'product_kind_service'){
      productCostNav.fadeIn();
      productCostFixedMarkupContainer.hide(); 
    }

    _this.updateCostFields(controllerContainer);
  }

  setCostSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();

    if (productKindValue != 'product_kind_retail') {
      $(controllerContainer).find('#product_costs_container .remove_fields').each(function(){
        $(this).trigger('click');
      });
    }
  }

  updateImageSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let productImageNav = $(controllerContainer).find('.js-product-images-nav-item');

    if (productKindValue == 'product_kind_service'){
      productImageNav.hide();
    }
    else{
      productImageNav.fadeIn();
    }
  }


  updatePriceSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let stockBehaviorValue = $(controllerContainer).find('.js-stock_behavior-field').val();
    let price_calculation_base_container = $(controllerContainer).find('.js-price_calculation_base-container');
    let fixedMarkupContainer = $(controllerContainer).find('.js-fixed_markup-container');
    let pricePercSaleTotalContainer = $(controllerContainer).find('.js-price_perc_sale_total-container');
    let productPriceMarkupPercContainer = $(controllerContainer).find('.js-product-price-markup_perc-container');
    let productPricePricePercForSaleContainer = $(controllerContainer).find('.js-product-price-price_perc_for_sale-container');
    let productPriceVariableCostPercContainer = $(controllerContainer).find('.js-product-price-variable_cost_perc-container');

    if (productKindValue == 'product_kind_retail') {
      productPriceVariableCostPercContainer.show();
      productPriceMarkupPercContainer.show();
      productPricePricePercForSaleContainer.hide();
      pricePercSaleTotalContainer.hide();
      price_calculation_base_container.show();
      fixedMarkupContainer.fadeIn();   
    }
    else if (productKindValue == 'product_kind_manufacturable'){
      productPriceVariableCostPercContainer.show();
      productPriceMarkupPercContainer.show();
      productPricePricePercForSaleContainer.hide();
      pricePercSaleTotalContainer.hide();
      price_calculation_base_container.show();
      fixedMarkupContainer.fadeIn(); 
    }
    else if (productKindValue == 'product_kind_service'){
      productPriceVariableCostPercContainer.hide();
      productPriceMarkupPercContainer.hide();
      productPricePricePercForSaleContainer.show();
      price_calculation_base_container.hide();
      fixedMarkupContainer.hide(); 
      pricePercSaleTotalContainer.fadeIn();
    }

    _this.updatePriceFields(controllerContainer);
  }

  setPriceSection(controllerContainer){
    let _this = this

    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let stockBehaviorValue = $(controllerContainer).find('.js-stock_behavior-field').val();
    let fixedMarkupField = $('.js-fixed_markup-field');
    let pricePercSaleTotalField = $('.js-price_perc_sale_total-field');
    let base_product_costs = $(controllerContainer).find('.js-price_calculation_base-base_product_costs');
    let base_products_raw_materials = $(controllerContainer).find('.js-price_calculation_base-base_products_raw_materials');

    if (productKindValue == 'product_kind_retail') {
      pricePercSaleTotalField.prop('checked', false);
      base_product_costs.prop('checked', true);
    }
    else if (productKindValue == 'product_kind_manufacturable'){
      pricePercSaleTotalField.prop('checked', false);
      if (stockBehaviorValue != 'fabricable'){
        base_products_raw_materials.prop('checked', true);        
      }
    }
    else if (productKindValue == 'product_kind_service'){
      fixedMarkupField.prop('checked', false);
      base_product_costs.prop('checked', true);
    }

    _this.updatePriceFields(controllerContainer);
  }

  updatePriceFields(controllerContainer){
    let _this = this
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let fixedMarkupField = $(controllerContainer).find('.js-fixed_markup-field');
    let pricePercSaleTotalField = $(controllerContainer).find('.js-price_perc_sale_total-field');
    let product_price_manufacturable_normal = $(controllerContainer).find('.js-product_price_manufacturable_normal');
    let product_price_manufacturable_fixed_markup = $(controllerContainer).find('.js-product_price_manufacturable_fixed_markup');
    let product_price_retail_normal = $(controllerContainer).find('.js-product_price_retail_normal');
    let product_price_retail_fixed_markup = $(controllerContainer).find('.js-product_price_retail_fixed_markup');
    let product_price_service_normal = $(controllerContainer).find('.js-product_price_service_normal');
    let product_price_service_fixed_markup = $(controllerContainer).find('.js-product_price_service_fixed_markup');


    if (productKindValue == 'product_kind_manufacturable') {
      if ((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')) {
        product_price_manufacturable_fixed_markup.show();
        //product_price_manufacturable_fixed_markup.addClass('bg-success');

        $('#product_prices_container .nested-fields .js-product_price_manufacturable_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          let product_price_is_readonly_value = $(this).find('.js-product_price_is_readonly_field').val();
          let amount_field = $(this).find('.js-amount_field')
          let markup_perc_field = $(this).find('.js-markup_perc_field')
          let variable_cost_perc_field = $(this).find('.js-variable_cost_perc_field')
      
          if(product_price_is_readonly_value != 'true'){
            amount_field.prop('readonly', true);
            amount_field.prop('disabled', false);
            markup_perc_field.prop('readonly', false);
            markup_perc_field.prop('disabled', false);
            variable_cost_perc_field.prop('disabled', true);
          }
        });

        product_price_manufacturable_normal.hide();
        //product_price_manufacturable_normal.removeClass('bg-success');                
        product_price_manufacturable_normal.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_normal.hide();
        //product_price_retail_normal.removeClass('bg-success');                
        product_price_retail_normal.find('.js-amount_field').prop('disabled', true);
        product_price_retail_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_fixed_markup.hide();
        //product_price_retail_fixed_markup.removeClass('bg-success');                
        product_price_retail_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_normal.hide();
        //product_price_service_normal.removeClass('bg-success');                
        product_price_service_normal.find('.js-amount_field').prop('disabled', true);
        product_price_service_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_fixed_markup.hide();
        //product_price_service_fixed_markup.removeClass('bg-success');                
        product_price_service_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);
      } else {
        product_price_manufacturable_normal.show();
        //product_price_manufacturable_normal.addClass('bg-success');

        $('#product_prices_container .nested-fields .js-product_price_manufacturable_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          let product_price_is_readonly_value = $(this).find('.js-product_price_is_readonly_field').val();
          let amount_field = $(this).find('.js-amount_field')
          let markup_perc_field = $(this).find('.js-markup_perc_field')
          let variable_cost_perc_field = $(this).find('.js-variable_cost_perc_field')
      
          if(product_price_is_readonly_value != 'true'){
            amount_field.prop('readonly', false);
            amount_field.prop('disabled', false);
            markup_perc_field.prop('readonly', true);
            markup_perc_field.prop('disabled', false);
            variable_cost_perc_field.prop('disabled', true);
          }
        });

        product_price_manufacturable_fixed_markup.hide();
        //product_price_manufacturable_fixed_markup.removeClass('bg-success');                
        product_price_manufacturable_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_normal.hide();
        //product_price_retail_normal.removeClass('bg-success');                
        product_price_retail_normal.find('.js-amount_field').prop('disabled', true);
        product_price_retail_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_fixed_markup.hide();
        //product_price_retail_fixed_markup.removeClass('bg-success');                
        product_price_retail_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_normal.hide();
        //product_price_service_normal.removeClass('bg-success');                
        product_price_service_normal.find('.js-amount_field').prop('disabled', true);
        product_price_service_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_fixed_markup.hide();
        //product_price_service_fixed_markup.removeClass('bg-success');                
        product_price_service_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);
      }
    } else if (productKindValue == 'product_kind_retail') {
      if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
        product_price_retail_fixed_markup.show();
        //product_price_retail_fixed_markup.addClass('bg-success');

        $('#product_prices_container .nested-fields .js-product_price_retail_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          let product_price_is_readonly_value = $(this).find('.js-product_price_is_readonly_field').val();
          let amount_field = $(this).find('.js-amount_field')
          let markup_perc_field = $(this).find('.js-markup_perc_field')
          let variable_cost_perc_field = $(this).find('.js-variable_cost_perc_field')
      
          if(product_price_is_readonly_value != 'true'){
            amount_field.prop('readonly', true);
            amount_field.prop('disabled', false);
            markup_perc_field.prop('readonly', false);
            markup_perc_field.prop('disabled', false);
            variable_cost_perc_field.prop('disabled', true);
          }
        });

        product_price_retail_normal.hide();
        //product_price_retail_normal.removeClass('bg-success');                
        product_price_retail_normal.find('.js-amount_field').prop('disabled', true);
        product_price_retail_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_normal.hide();
        //product_price_manufacturable_normal.removeClass('bg-success');                
        product_price_manufacturable_normal.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_fixed_markup.hide();
        //product_price_manufacturable_fixed_markup.removeClass('bg-success');                
        product_price_manufacturable_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_normal.hide();
        //product_price_service_normal.removeClass('bg-success');                
        product_price_service_normal.find('.js-amount_field').prop('disabled', true);
        product_price_service_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_fixed_markup.hide();
        //product_price_service_fixed_markup.removeClass('bg-success');                
        product_price_service_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);
      }
      else{
        product_price_retail_normal.show();
        //product_price_retail_normal.addClass('bg-success');

        $('#product_prices_container .nested-fields .js-product_price_retail_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          let product_price_is_readonly_value = $(this).find('.js-product_price_is_readonly_field').val();
          let amount_field = $(this).find('.js-amount_field')
          let markup_perc_field = $(this).find('.js-markup_perc_field')
          let variable_cost_perc_field = $(this).find('.js-variable_cost_perc_field')
      
          if(product_price_is_readonly_value != 'true'){
            amount_field.prop('readonly', false);
            amount_field.prop('disabled', false);
            markup_perc_field.prop('readonly', true);
            markup_perc_field.prop('disabled', false);
            variable_cost_perc_field.prop('disabled', true);
          }
        });   

        product_price_retail_fixed_markup.hide();
        //product_price_retail_fixed_markup.removeClass('bg-success');                
        product_price_retail_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_normal.hide();
        //product_price_manufacturable_normal.removeClass('bg-success');                
        product_price_manufacturable_normal.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_fixed_markup.hide();
        //product_price_manufacturable_fixed_markup.removeClass('bg-success');                
        product_price_manufacturable_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_normal.hide();
        //product_price_service_normal.removeClass('bg-success');                
        product_price_service_normal.find('.js-amount_field').prop('disabled', true);
        product_price_service_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_service_fixed_markup.hide();
        //product_price_service_fixed_markup.removeClass('bg-success');                
        product_price_service_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);        
      }
    } else if (productKindValue == 'product_kind_service'){
      if((pricePercSaleTotalField.is(':checked')) || (pricePercSaleTotalField.val() == 'true')){
        product_price_service_fixed_markup.show();
        //product_price_service_fixed_markup.addClass('bg-success');     

        $('#product_prices_container .nested-fields .js-product_price_service_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          let product_price_is_readonly_value = $(this).find('.js-product_price_is_readonly_field').val();
          let amount_field = $(this).find('.js-amount_field')
          let markup_perc_field = $(this).find('.js-markup_perc_field')
          let variable_cost_perc_field = $(this).find('.js-variable_cost_perc_field')
      
          if(product_price_is_readonly_value != 'true'){
            amount_field.prop('readonly', true);
            amount_field.prop('disabled', false);
            markup_perc_field.prop('readonly', false);
            markup_perc_field.prop('disabled', false);
            variable_cost_perc_field.prop('disabled', true);
          }
        });   

        product_price_service_normal.hide();
        //product_price_service_normal.removeClass('bg-success');        
        product_price_service_normal.find('.js-amount_field').prop('disabled', true);
        product_price_service_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_normal.hide();
        //product_price_retail_normal.removeClass('bg-success');        
        product_price_retail_normal.find('.js-amount_field').prop('disabled', true);
        product_price_retail_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_fixed_markup.hide();
        //product_price_retail_fixed_markup.removeClass('bg-success');        
        product_price_retail_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_normal.hide();
        //product_price_manufacturable_normal.removeClass('bg-success');        
        product_price_manufacturable_normal.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_fixed_markup.hide();
        //product_price_manufacturable_fixed_markup.removeClass('bg-success');        
        product_price_manufacturable_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);
      }
      else{
        product_price_service_normal.show();
        //product_price_service_normal.addClass('bg-success');    

        $('#product_prices_container .nested-fields .js-product_price_service_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          let product_price_is_readonly_value = $(this).find('.js-product_price_is_readonly_field').val();
          let amount_field = $(this).find('.js-amount_field')
          let markup_perc_field = $(this).find('.js-markup_perc_field')
          let variable_cost_perc_field = $(this).find('.js-variable_cost_perc_field')
      
          if(product_price_is_readonly_value != 'true'){
            amount_field.prop('readonly', false);
            amount_field.prop('disabled', false);
            markup_perc_field.prop('readonly', true);
            markup_perc_field.prop('disabled', false);
            variable_cost_perc_field.prop('disabled', true);
          }
        });   

        product_price_service_fixed_markup.hide();
        //product_price_service_fixed_markup.removeClass('bg-success');
        product_price_service_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_service_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_normal.hide();
        //product_price_retail_normal.removeClass('bg-success');
        product_price_retail_normal.find('.js-amount_field').prop('disabled', true);
        product_price_retail_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_retail_fixed_markup.hide();
        //product_price_retail_fixed_markup.removeClass('bg-success');
        product_price_retail_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_retail_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_normal.hide();
        //product_price_manufacturable_normal.removeClass('bg-success');
        product_price_manufacturable_normal.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_normal.find('.js-variable_cost_perc_field').prop('disabled', true);

        product_price_manufacturable_fixed_markup.hide();
        //product_price_manufacturable_fixed_markup.removeClass('bg-success');
        product_price_manufacturable_fixed_markup.find('.js-amount_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-markup_perc_field').prop('disabled', true);
        product_price_manufacturable_fixed_markup.find('.js-variable_cost_perc_field').prop('disabled', true);
      }
    }
  }

  setPriceFields(controllerContainer){
    let _this = this
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let fixedMarkupField = $('.js-fixed_markup-field');
    let pricePercSaleTotalField = $('.js-price_perc_sale_total-field');


    if(productKindValue == 'product_kind_manufacturable'){
      if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
        $('#product-prices .nested-fields .js-product_price_manufacturable_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateManufacturableFixedMarkup(container);
        });
      }
      else{
        $('#product-prices .nested-fields .js-product_price_manufacturable_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateManufacturableNormal(container);
        });
      }
    }
    else if(productKindValue == 'product_kind_retail'){
      if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
        $('#product-prices .nested-fields .js-product_price_retail_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateRetailFixedMarkup(container);
        });
      }
      else{
        $('#product-prices .nested-fields .js-product_price_retail_fixed_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateRetailNormal(container);
        });
      }
    }
    else if (productKindValue == 'product_kind_service'){
      if((pricePercSaleTotalField.is(':checked')) || (pricePercSaleTotalField.val() == 'true')){
        $('#product-prices .nested-fields .js-product_price_service_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateServiceFixedMarkup(container);
        });
      }
      else{
        $('#product-prices .nested-fields .js-product_price_service_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateServiceNormal(container);
        });
      }
    }
  }
  
  updateCostFields(controllerContainer){
    let _this = this
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let productCostFixedMarkupField = $(controllerContainer).find('.js-product_cost_fixed_markup-field');


    if (productKindValue == 'product_kind_manufacturable') {
      if ((productCostFixedMarkupField.is(':checked')) || (productCostFixedMarkupField.val() == 'true')) {
        $(controllerContainer).find('.js-product_cost_net_field').prop('readonly', true);
        $(controllerContainer).find('.js-product_cost_cost_field').prop('readonly', true);
        $(controllerContainer).find('.js-product_cost_markup_perc_field').prop('readonly', false);

      } else {
        $(controllerContainer).find('.js-product_cost_net_field').prop('readonly', false);
        $(controllerContainer).find('.js-product_cost_cost_field').prop('readonly', false);
        $(controllerContainer).find('.js-product_cost_markup_perc_field').prop('readonly', true);
      }
    } else {
      $(controllerContainer).find('.js-product_cost_net_field').prop('readonly', false);
      $(controllerContainer).find('.js-product_cost_cost_field').prop('readonly', false);
      $(controllerContainer).find('.js-product_cost_markup_perc_field').prop('readonly', true);
    }
  }

  setCostFields(controllerContainer){
    let _this = this
    let productKindValue = $(controllerContainer).find('.js-product_kind-field').val();
    let productCostFixedMarkupField = $(controllerContainer).find('.js-product_cost_fixed_markup-field');


    if(productKindValue == 'product_kind_manufacturable'){
      if((productCostFixedMarkupField.is(':checked')) || (productCostFixedMarkupField.val() == 'true')){
        $('#product-prices .nested-fields .js-product_price_manufacturable_fixed_markup').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateManufacturableProductCostFixedMarkup(container);
          //calculateManufacturableFixedMarkup
        });
      }
      else{
        $('#product-prices .nested-fields .js-product_price_manufacturable_normal').each(function(){
          let container = $(this).closest('.nested-fields')
          _this.calculateManufacturableProductCostNormal(container);
          //calculateManufacturableNormal
        });
      }
    }
  }



  updateNetContainer(controllerContainer){
    let _this = this
    let composeCostPerNetField = $(controllerContainer).find('.js-compose_cost_per_net-field');
    let netColumns = $(controllerContainer).find('.js-net-column');
    let costColumns = $(controllerContainer).find('.js-cost-column');

    if((composeCostPerNetField.is(':checked')) || (composeCostPerNetField.val() == 'true')){
      netColumns.show();
      costColumns.removeClass('offset-sm-3');
      //costColumns.removeClass('col-md-4');
      //costColumns.addClass('col-md-2');
    }
    else{
      netColumns.hide();
      costColumns.addClass('offset-sm-3');
      //costColumns.removeClass('col-md-2');
      //costColumns.addClass('col-md-4');
    }
  }

  setProductPricesSalePointContainer(controllerContainer){
    let _this = this
    let button = $(controllerContainer).find('#js-button_advanced_product_prices');
    let salePointIdField = $(controllerContainer).find('.js-product_price_sale_point_id_field');

    if(button.data('active') == false){
      button.data('active', true);
    }
    else{
      button.data('active', false);
      salePointIdField.val('').trigger('select2:select');
    }
  }
  
  setProductCostsSalePointContainer(controllerContainer){
    let _this = this
    let button = $(controllerContainer).find('#js-button_advanced_product_costs');
    let salePointIdField = $(controllerContainer).find('.js-product_cost_sale_point_id_field');

    if(button.data('active') == false){
      button.data('active', true);
    }
    else{
      button.data('active', false);
      salePointIdField.val('').trigger('select2:select');
    }
  }

  updateProductPricesSalePointContainer(controllerContainer){
    let _this = this
    let button = $(controllerContainer).find('#js-button_advanced_product_prices');
    let columns = $(controllerContainer).find('.js-product_prices_sale_point-column');
    let otherColumns = $(controllerContainer).find('.js-product_prices_client_category-column');

    if(button.data('active') == true){
      columns.show();
      button.removeClass('btn-light')
      button.removeClass('text-black-50')
      button.addClass('btn-primary')
      button.addClass('text-white')
      otherColumns.removeClass('col-md-4');
      otherColumns.addClass('col-md-2');
    }
    else{
      columns.hide();
      button.removeClass('btn-primary')
      button.removeClass('text-white')
      button.addClass('btn-light')
      button.addClass('text-black-50')
      otherColumns.removeClass('col-md-2');
      otherColumns.addClass('col-md-4');
    }
  }

  updateProductCostsSalePointContainer(controllerContainer){
    let _this = this
    let button = $(controllerContainer).find('#js-button_advanced_product_costs');
    let columns = $(controllerContainer).find('.js-product_costs_sale_point-column');
    let otherColumns = $(controllerContainer).find('.js-product_costs_supplier-column');

    if(button.data('active') == true){
      columns.show();
      button.removeClass('btn-light')
      button.removeClass('text-black-50')
      button.addClass('btn-primary')
      button.addClass('text-white')
      otherColumns.removeClass('col-md-12');
      otherColumns.addClass('col-md-6');
    }
    else{
      columns.hide();
      button.removeClass('btn-primary')
      button.removeClass('text-white')
      button.addClass('btn-light')
      button.addClass('text-black-50')
      otherColumns.removeClass('col-md-6');
      otherColumns.addClass('col-md-12');
    }
  }

  changecompoundId(insertedItem){
    let _this = this
    let container = $(insertedItem).closest('.nested-fields');
    let compound_id = container.find('.js-compound_id');
    let compound_symbol = container.find('.js-compound_symbol');
    let compound_amount = container.find('.js-compound_amount');
    let compound_cost_hidden = container.find('.js-compound_cost_hidden');
    let compound_cost = container.find('.js-compound_cost');

    $(compound_id).on('select2:select', function() {
      let id = $(this).select2('val');
      let url = $(this).data('url-two');
      url = url.replace('/the_id', '/'+id);
      $.getJSON(url, function(data) {
        let symbol = data.purchase_measurement_category_symbol;
        let cost = data.cost_value;
        compound_symbol.html(symbol);
        compound_amount.fadeIn();
        compound_cost_hidden.val(cost);
        compound_cost.fadeIn();
        _this.calculateCompoundPrice(insertedItem);
        _this.calculateTotalProductCompositionItems();
      });
    });
  }


  addSourceToSupplierAddButton(insertedItem){
    let id_to_replace = $(insertedItem).find('.js-supplier_id').attr('id');
    let href_to_replace = $(insertedItem).find('.js-add-button').attr('href');
    if(href_to_replace != undefined){
      href_to_replace = href_to_replace.replace('replace_with_id', '%23'+id_to_replace);
      $(insertedItem).find('.js-add-button').attr('href', href_to_replace);
    }
  }

  bindCompoundCost(insertedItem){
    let _this = this
    let compound_amount_value = $(insertedItem).find('.js-compound_amount_value');

    $(compound_amount_value).on('input', function() {
      _this.calculateCompoundPrice(insertedItem);
      _this.calculateTotalProductCompositionItems();
    });
  }

  bindProductCost(insertedItem){
    let _this = this
    let productCostNetField = $(insertedItem).find('.js-product_cost_net_field');
    let productCostCostField = $(insertedItem).find('.js-product_cost_cost_field');
    let productCostMarkupPercField = $(insertedItem).find('.js-product_cost_markup_perc_field');
    let productCostMainField = $(insertedItem).find('.js-product_cost_main_field');
    let productCostSalePointIdField = $(insertedItem).find('.js-product_cost_sale_point_id_field');
    let productCostfixedMarkupField = $('.js-product_cost_fixed_markup-field');

    $(productCostNetField).on('input', function() {
      _this.calculateManufacturableProductCostNormal(insertedItem);
      _this.calculateProductCostCost(insertedItem);
      _this.calculateProductCostTotal(insertedItem);
      _this.calculateTotalProductCost();
    });

    $(productCostCostField).on('input', function() {
      _this.calculateManufacturableProductCostNormal(insertedItem);
      _this.calculateProductCostNet(insertedItem);
      _this.calculateProductCostTotal(insertedItem);
      _this.calculateTotalProductCost();
    });

    $(productCostMarkupPercField).on('input', function() {
      _this.calculateManufacturableProductCostFixedMarkup(insertedItem);
      _this.calculateProductCostNet(insertedItem);
      _this.calculateProductCostTotal(insertedItem);
      _this.calculateTotalProductCost();      
    });

    $(productCostMainField).on('change', function() {
      _this.calculateProductCostTotal(insertedItem);
      _this.calculateTotalProductCost();
    });

    $(productCostSalePointIdField).on('change', function() {
      _this.cleanSupplierId(insertedItem)
      _this.calculateProductCostTotal(insertedItem);
      _this.calculateTotalProductCost();
    });


  }

  calculateProductCostCost(insertedItem){
    let productTaxPerc = $('#product_tax_perc').val();
    let productCostNetValue = $(insertedItem).find('.js-product_cost_net_field').val();
    let productCostCostField = $(insertedItem).find('.js-product_cost_cost_field');

    productTaxPerc = parseFloat(productTaxPerc);
    productTaxPerc = (isNaN(productTaxPerc)) ? 0.0 : productTaxPerc;

    productCostNetValue = parseFloat(productCostNetValue)
    productCostNetValue = (isNaN(productCostNetValue)) ? 0.0 : productCostNetValue;

    let cost = productCostNetValue * (1 + productTaxPerc / 100.0);

    productCostCostField.val(cost.toFixed(2));
  }

  cleanSupplierId(insertedItem){
    let productCostSupplierIdField = $(insertedItem).find('.js-supplier_id');
    productCostSupplierIdField.val('').trigger('select2:select');
  }

  calculateProductCostNet(insertedItem){
    let productTaxPerc = $('#product_tax_perc').val();
    let productCostCostValue = $(insertedItem).find('.js-product_cost_cost_field').val();
    let productCostNetField = $(insertedItem).find('.js-product_cost_net_field');

    productTaxPerc = parseFloat(productTaxPerc);
    productTaxPerc = (isNaN(productTaxPerc)) ? 0.0 : productTaxPerc;

    productCostCostValue = parseFloat(productCostCostValue)
    productCostCostValue = (isNaN(productCostCostValue)) ? 0.0 : productCostCostValue;

    let net = productCostCostValue / (1 + productTaxPerc / 100.0);

    productCostNetField.val(net.toFixed(2));
  }



  calculateCompoundPrice(insertedItem){
    let container = $(insertedItem)
    let compound_amount_value = container.find('.js-compound_amount_value');
    let compound_cost_value = container.find('.js-compound_cost_value');
    let compound_cost_hidden = container.find('.js-compound_cost_hidden');

    let base_price = parseFloat(compound_cost_hidden.val())
    base_price = (isNaN(base_price)) ? 0.0 : base_price;

    let amount = parseFloat(compound_amount_value.val())
    amount = (isNaN(amount)) ? 0.0 : amount;

    compound_cost_value.val((base_price * amount).toFixed(2));
  }

  calculateTotalProductCompositionItems(){
    let _this = this
    let container = $("#js-product_composition_item_total");
    let field = $("#js-product_composition_item_total_field");
    let subtotal = 0.0;
    let fixedMarkupField = $('.js-fixed_markup-field');
    let productCostFixedMarkupField = $('.js-product_cost_fixed_markup-field');

    $('#product_composition_items_container .nested-fields:visible .js-compound_cost_value').each(function(){
      let amount = parseFloat($(this).val());
      subtotal += (isNaN(amount)) ? 0.0 : amount;
    });

    container.html(_this.formatToCurrency(subtotal))
    field.val(subtotal);


    if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
      $('#product-prices .nested-fields .js-product_price_manufacturable_fixed_markup').each(function(){
        let container = $(this).closest('.nested-fields')
        _this.calculateManufacturableFixedMarkup(container);
      });
    }
    else{
      $('#product-prices .nested-fields .js-product_price_manufacturable_normal').each(function(){
        let container = $(this).closest('.nested-fields')
        _this.calculateManufacturableNormal(container);
      });
    }

    if((productCostFixedMarkupField.is(':checked')) || (productCostFixedMarkupField.val() == 'true')){
      $('#product-costs .nested-fields').each(function(){
        let container = $(this);
        _this.calculateManufacturableProductCostFixedMarkup(container);
        container.find('.js-product_cost_markup_perc_field').trigger('input');
      });
    }
    else{
      $('#product-costs .nested-fields').each(function(){
        let container = $(this);
        _this.calculateManufacturableProductCostNormal(container);
        container.find('.js-product_cost_cost_field').trigger('input');
      });
    }
  }

  updateProductCostTaxKind(option){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let productCostTaxKindField = $('.js-product_cost_tax_kind_field');
    let productCostTaxKindContainer = $('.js-product_cost_tax_kind_container');

    productCostTaxKindField.val(value);
    productCostTaxKindContainer.html(icon);
  }
  updateProductOtherCostKind(option){
    let value = $(option).attr('href');
    let icon = $(option).html();
    let productOtherCostKindField = $('.js-product_other_cost_kind_field');
    let productOtherCostKindContainer = $('.js-product_other_cost_kind_container');

    productOtherCostKindField.val(value);
    productOtherCostKindContainer.html(icon);
  }



  calculateTotalProductCost(){
    let _this = this
    let fixedMarkupField = $('.js-fixed_markup-field');

    if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
      $('#product-prices .nested-fields .js-product_price_retail_fixed_markup').each(function(){
        let container = $(this).closest('.nested-fields')
        _this.calculateRetailFixedMarkup(container);
      });
    }
    else{
      $('#product-prices .nested-fields .js-product_price_retail_normal').each(function(){
        let container = $(this).closest('.nested-fields')
        _this.calculateRetailNormal(container);
      });
    }
  }
  
  
  calculateProductCostTotal(insertedItem){
    let _this = this;
    let container = $(insertedItem).closest('.nested-fields');
    let costContainer = container.find(".js-product_cost_total_cost");
    let costField = container.find(".js-product_cost_total_cost_field");

    let amount = parseFloat(container.find('.js-product_cost_cost_field').val());
    amount = (isNaN(amount)) ? 0.0 : amount;

    let costTax = 0.0;
    let productCostTaxKindField = $('.js-product_cost_tax_kind_field').val();
    
    let productCostTaxValueField = parseFloat($('.js-product_cost_tax_value_field').val());
    productCostTaxValueField = (isNaN(productCostTaxValueField)) ? 0.0 : productCostTaxValueField;
    
    if (productCostTaxKindField == 'cost_tax_kind_perc'){
      costTax = amount * (productCostTaxValueField / 100)
    }
    else{
      costTax = productCostTaxValueField;
    }
    

    let otherCost = 0.0;
    let productOtherCostKindField = $('.js-product_other_cost_kind_field').val();
    
    let productOtherCostValueField = parseFloat($('.js-product_other_cost_value_field').val());
    productOtherCostValueField = (isNaN(productOtherCostValueField)) ? 0.0 : productOtherCostValueField;
    
    if (productOtherCostKindField == 'other_cost_kind_perc'){
      otherCost = amount * (productOtherCostValueField / 100)
    }
    else{
      otherCost = productOtherCostValueField;
    }

    let transformationCoefficientValue = parseFloat($('.js-product_transformation_coefficient_field').val());
    transformationCoefficientValue = (isNaN(transformationCoefficientValue)) ? 0.0 : transformationCoefficientValue;

    let subtotal = (amount + costTax + otherCost) * transformationCoefficientValue;

    costContainer.html(_this.formatToCurrency(subtotal))
    costField.val(subtotal);
  }
  

  bindProductPrice(insertedItem){
    let _this = this
    let amount_manufacturable_normal_field = $(insertedItem).find('.js-product_price_manufacturable_normal .js-amount_field');
    let markup_perc_manufacturable_fixed_markup_field = $(insertedItem).find('.js-product_price_manufacturable_fixed_markup .js-markup_perc_field');
    let amount_retail_normal_field = $(insertedItem).find('.js-product_price_retail_normal .js-amount_field');
    let markup_perc_fixed_markup_field = $(insertedItem).find('.js-product_price_retail_fixed_markup .js-markup_perc_field');

    let price_calculation_base = $(insertedItem).find('.js-price_calculation_base-field');
    let product_price_sale_point_id = $(insertedItem).find('.js-product_price_sale_point_id_field');

    let productKindValue = $('.js-product_kind-field').val();
    let fixedMarkupField = $('.js-fixed_markup-field');


    
    $(amount_manufacturable_normal_field).on('input', function() {
      _this.calculateManufacturableNormal(insertedItem);
    });
    
    $(markup_perc_manufacturable_fixed_markup_field).on('input', function() {
      _this.calculateManufacturableFixedMarkup(insertedItem);
    });

    $(amount_retail_normal_field).on('input', function() {
      _this.calculateRetailNormal(insertedItem);
    });

    $(markup_perc_fixed_markup_field).on('input', function() {
      _this.calculateRetailFixedMarkup(insertedItem);
    });

    $(price_calculation_base).on('change', function() {
      if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
        if(productKindValue == 'product_kind_manufacturable'){
          _this.calculateManufacturableFixedMarkup(insertedItem);
        }
        else {
           _this.calculateRetailFixedMarkup(insertedItem);
        }
      }
      else{
        if(productKindValue == 'product_kind_manufacturable'){
          _this.calculateManufacturableNormal(insertedItem);
        }
        else {
          _this.calculateRetailNormal(insertedItem);
        }
      }
    });
    
    $(product_price_sale_point_id).on('change', function() {
      if((fixedMarkupField.is(':checked')) || (fixedMarkupField.val() == 'true')){
        if(productKindValue == 'product_kind_manufacturable'){
          _this.calculateManufacturableFixedMarkup(insertedItem);
        }
        else {
           _this.calculateRetailFixedMarkup(insertedItem);
        }
      }
      else{
        if(productKindValue == 'product_kind_manufacturable'){
          _this.calculateManufacturableNormal(insertedItem);
        }
        else {
          _this.calculateRetailNormal(insertedItem);
        }
      }
    });
  }

  calculationBase(insertedItem){
    let priceCalculationBaseValue = $(insertedItem).find('.js-price_calculation_base-field:checked').val();
    let calculation_base;
    if(priceCalculationBaseValue == 'base_products_raw_materials'){
      //calculation_base = $("#js-product_raw_material_total_field").val();
      calculation_base = $("#js-product_composition_item_total_field").val();
      //TODO one or other
    }
    else {

      let productPriceSalePointIdValue = $(insertedItem).find('.js-product_price_sale_point_id_field').val();   
      
      $('#product_costs_container .nested-fields .js-product_cost_sale_point_id_field').each(function(){
        let productCostSalePointIdField = $(this);
        let productCostSalePointIdValue = productCostSalePointIdField.val();
        let productCostContainer = productCostSalePointIdField.closest('.nested-fields');
        let productCostMainValue = productCostContainer.find('.js-product_cost_main_field').is(':checked');

        if((productPriceSalePointIdValue == productCostSalePointIdValue) && (productCostMainValue == true)){
          calculation_base = productCostContainer.find('.js-product_cost_total_cost_field').val();
        }
      });
/*
      if((productPriceSalePointIdValue != '') && (calculation_base == undefined)){
        $('#product_costs_container .nested-fields .js-product_cost_sale_point_id_field').each(function(){
          let productCostSalePointIdField = $(this);
          let productCostSalePointIdValue = productCostSalePointIdField.val();
          let productCostContainer = productCostSalePointIdField.closest('.nested-fields');
          let productCostMainValue = productCostContainer.find('.js-product_cost_main_field').is(':checked');
  
          if((productCostSalePointIdValue == '') && (productCostMainValue == true)){
            calculation_base = productCostContainer.find('.js-product_cost_total_cost_field').val();
          }
        });
      }
*/
    }

    return calculation_base
  }


  calculateManufacturableNormal(insertedItem){
    let _this = this;
    let calculation_base = _this.calculationBase(insertedItem);

    let manufacturable_normal = $(insertedItem).find('.js-product_price_manufacturable_normal');
    let manufacturable_fixed_markup = $(insertedItem).find('.js-product_price_manufacturable_fixed_markup');
    let retail_normal = $(insertedItem).find('.js-product_price_retail_normal');
    let retail_fixed_markup = $(insertedItem).find('.js-product_price_retail_fixed_markup');


    let mn_amount_field = manufacturable_normal.find('.js-amount_field');
    let mn_variable_cost_perc_field = manufacturable_normal.find('.js-variable_cost_perc_field');
    let mn_markup_perc_field = manufacturable_normal.find('.js-markup_perc_field');

    let mf_amount_field = manufacturable_fixed_markup.find('.js-amount_field');
    let mf_variable_cost_perc_field = manufacturable_fixed_markup.find('.js-variable_cost_perc_field');
    let mf_markup_perc_field = manufacturable_fixed_markup.find('.js-markup_perc_field');

    let rn_amount_field = retail_normal.find('.js-amount_field');
    let rn_variable_cost_perc_field = retail_normal.find('.js-variable_cost_perc_field');
    let rn_markup_perc_field = retail_normal.find('.js-markup_perc_field');

    let rf_amount_field = retail_fixed_markup.find('.js-amount_field');
    let rf_variable_cost_perc_field = retail_fixed_markup.find('.js-variable_cost_perc_field');
    let rf_markup_perc_field = retail_fixed_markup.find('.js-markup_perc_field');


    let calculation_base_total = parseFloat(calculation_base)
    calculation_base_total = (isNaN(calculation_base_total)) ? 0.0 : calculation_base_total;

    let price = parseFloat(mn_amount_field.val())
    price = (isNaN(price)) ? 0.0 : price;

    let variable_cost_perc = 0.0;
    if(price != 0.0){
      variable_cost_perc = (calculation_base_total / price * 100).toFixed(2);
    }

    if (variable_cost_perc == Infinity){
      mn_variable_cost_perc_field.val((0.0).toFixed(2));
      mf_variable_cost_perc_field.val((0.0).toFixed(2));
      rn_variable_cost_perc_field.val((0.0).toFixed(2));
      rf_variable_cost_perc_field.val((0.0).toFixed(2));
    } else {
      mn_variable_cost_perc_field.val(variable_cost_perc);
      mf_variable_cost_perc_field.val(variable_cost_perc);
      rn_variable_cost_perc_field.val(variable_cost_perc);
      rf_variable_cost_perc_field.val(variable_cost_perc);
    }

    let markup_perc = 0.0;
    if(calculation_base_total != 0.0){
      markup_perc = (((price - calculation_base_total) / calculation_base_total) * 100).toFixed(2);
    }

    if (markup_perc == Infinity){
      mn_markup_perc_field.val((0.0).toFixed(2));
      mf_markup_perc_field.val((0.0).toFixed(2));
      rn_markup_perc_field.val((0.0).toFixed(2));
      rf_markup_perc_field.val((0.0).toFixed(2));
    } else {
      mn_markup_perc_field.val(markup_perc);
      mf_markup_perc_field.val(markup_perc);
      rn_markup_perc_field.val(markup_perc);
      rf_markup_perc_field.val(markup_perc);
    }

    mf_amount_field.val(price);
    rn_amount_field.val(price);
    rf_amount_field.val(price);
  }
  
  calculateManufacturableFixedMarkup(insertedItem){
    let _this = this;
    let calculation_base = _this.calculationBase(insertedItem);


    let manufacturable_normal = $(insertedItem).find('.js-product_price_manufacturable_normal');
    let manufacturable_fixed_markup = $(insertedItem).find('.js-product_price_manufacturable_fixed_markup');
    let retail_normal = $(insertedItem).find('.js-product_price_retail_normal');
    let retail_fixed_markup = $(insertedItem).find('.js-product_price_retail_fixed_markup');

    let mn_amount_field = manufacturable_normal.find('.js-amount_field');
    let mn_variable_cost_perc_field = manufacturable_normal.find('.js-variable_cost_perc_field');
    let mn_markup_perc_field = manufacturable_normal.find('.js-markup_perc_field');

    let mf_amount_field = manufacturable_fixed_markup.find('.js-amount_field');
    let mf_variable_cost_perc_field = manufacturable_fixed_markup.find('.js-variable_cost_perc_field');
    let mf_markup_perc_field = manufacturable_fixed_markup.find('.js-markup_perc_field');

    let rn_amount_field = retail_normal.find('.js-amount_field');
    let rn_variable_cost_perc_field = retail_normal.find('.js-variable_cost_perc_field');
    let rn_markup_perc_field = retail_normal.find('.js-markup_perc_field');

    let rf_amount_field = retail_fixed_markup.find('.js-amount_field');
    let rf_variable_cost_perc_field = retail_fixed_markup.find('.js-variable_cost_perc_field');
    let rf_markup_perc_field = retail_fixed_markup.find('.js-markup_perc_field');

    let calculation_base_total = parseFloat(calculation_base)
    calculation_base_total = (isNaN(calculation_base_total)) ? 0.0 : calculation_base_total;

    let markup_perc = parseFloat(mf_markup_perc_field.val())
    markup_perc = (isNaN(markup_perc)) ? 0.0 : markup_perc;

    let price = 0.0;
    if(calculation_base_total != 0.0){
      price = ((markup_perc / 100) * calculation_base_total + calculation_base_total).toFixed(2);
    }

    if (price == Infinity){
      mf_amount_field.val((0.0).toFixed(2));
      rf_amount_field.val((0.0).toFixed(2));
      mn_amount_field.val((0.0).toFixed(2));
      rn_amount_field.val((0.0).toFixed(2));
    } else {
      mf_amount_field.val(price);
      rf_amount_field.val(price);
      mn_amount_field.val(price);
      rn_amount_field.val(price);
    }


    let variable_cost_perc = 0.0;
    if(price != 0.0){
      variable_cost_perc = (calculation_base_total / price * 100).toFixed(2);
    }

    if (variable_cost_perc == Infinity){
      mf_variable_cost_perc_field.val((0.0).toFixed(2));
      rf_variable_cost_perc_field.val((0.0).toFixed(2));
      mn_variable_cost_perc_field.val((0.0).toFixed(2));
      rn_variable_cost_perc_field.val((0.0).toFixed(2));
    } else {
      mf_variable_cost_perc_field.val(variable_cost_perc);
      rf_variable_cost_perc_field.val(variable_cost_perc);
      mn_variable_cost_perc_field.val(variable_cost_perc);
      rn_variable_cost_perc_field.val(variable_cost_perc);
    }

    mn_markup_perc_field.val(markup_perc);
    rf_markup_perc_field.val(markup_perc);
    rn_markup_perc_field.val(markup_perc);
  }


  calculateRetailNormal(insertedItem){
    let _this = this;
    let calculation_base = _this.calculationBase(insertedItem);


    let manufacturable_normal = $(insertedItem).find('.js-product_price_manufacturable_normal');
    let manufacturable_fixed_markup = $(insertedItem).find('.js-product_price_manufacturable_fixed_markup');
    let retail_normal = $(insertedItem).find('.js-product_price_retail_normal');
    let retail_fixed_markup = $(insertedItem).find('.js-product_price_retail_fixed_markup');

    let mn_amount_field = manufacturable_normal.find('.js-amount_field');
    let mn_variable_cost_perc_field = manufacturable_normal.find('.js-variable_cost_perc_field');
    let mn_markup_perc_field = manufacturable_normal.find('.js-markup_perc_field');

    let mf_amount_field = manufacturable_fixed_markup.find('.js-amount_field');
    let mf_variable_cost_perc_field = manufacturable_fixed_markup.find('.js-variable_cost_perc_field');
    let mf_markup_perc_field = manufacturable_fixed_markup.find('.js-markup_perc_field');

    let rn_amount_field = retail_normal.find('.js-amount_field');
    let rn_variable_cost_perc_field = retail_normal.find('.js-variable_cost_perc_field');
    let rn_markup_perc_field = retail_normal.find('.js-markup_perc_field');

    let rf_amount_field = retail_fixed_markup.find('.js-amount_field');
    let rf_variable_cost_perc_field = retail_fixed_markup.find('.js-variable_cost_perc_field');
    let rf_markup_perc_field = retail_fixed_markup.find('.js-markup_perc_field');


    let calculation_base_total = parseFloat(calculation_base)
    calculation_base_total = (isNaN(calculation_base_total)) ? 0.0 : calculation_base_total;

    let price = parseFloat(rn_amount_field.val())
    price = (isNaN(price)) ? 0.0 : price;


    let markup_perc = 0.0;
    if(calculation_base_total != 0.0){
      markup_perc = (((price - calculation_base_total) / calculation_base_total) * 100).toFixed(2);
    }

    if (markup_perc == Infinity){
      rn_markup_perc_field.val((0.0).toFixed(2));
      mn_markup_perc_field.val((0.0).toFixed(2));
      mf_markup_perc_field.val((0.0).toFixed(2));
      rf_markup_perc_field.val((0.0).toFixed(2));
    } else {
      rn_markup_perc_field.val(markup_perc);
      mn_markup_perc_field.val(markup_perc);
      mf_markup_perc_field.val(markup_perc);
      rf_markup_perc_field.val(markup_perc);
    }

    let variable_cost_perc = 0.0;
    if(price != 0.0){
      variable_cost_perc = (calculation_base_total / price * 100).toFixed(2);
    }

    if (variable_cost_perc == Infinity){
      rn_variable_cost_perc_field.val((0.0).toFixed(2));
      mn_variable_cost_perc_field.val((0.0).toFixed(2));
      mf_variable_cost_perc_field.val((0.0).toFixed(2));
      rf_variable_cost_perc_field.val((0.0).toFixed(2));
    } else {
      rn_variable_cost_perc_field.val(variable_cost_perc);
      mn_variable_cost_perc_field.val(variable_cost_perc);
      mf_variable_cost_perc_field.val(variable_cost_perc);
      rf_variable_cost_perc_field.val(variable_cost_perc);
    }

    mn_amount_field.val(price);
    mf_amount_field.val(price);
    rf_amount_field.val(price);
  }


  calculateRetailFixedMarkup(insertedItem){
    let _this = this;
    let calculation_base = _this.calculationBase(insertedItem);


    let manufacturable_normal = $(insertedItem).find('.js-product_price_manufacturable_normal');
    let manufacturable_fixed_markup = $(insertedItem).find('.js-product_price_manufacturable_fixed_markup');
    let retail_normal = $(insertedItem).find('.js-product_price_retail_normal');
    let retail_fixed_markup = $(insertedItem).find('.js-product_price_retail_fixed_markup');

    let mn_amount_field = manufacturable_normal.find('.js-amount_field');
    let mn_variable_cost_perc_field = manufacturable_normal.find('.js-variable_cost_perc_field');
    let mn_markup_perc_field = manufacturable_normal.find('.js-markup_perc_field');

    let mf_amount_field = manufacturable_fixed_markup.find('.js-amount_field');
    let mf_variable_cost_perc_field = manufacturable_fixed_markup.find('.js-variable_cost_perc_field');
    let mf_markup_perc_field = manufacturable_fixed_markup.find('.js-markup_perc_field');

    let rn_amount_field = retail_normal.find('.js-amount_field');
    let rn_variable_cost_perc_field = retail_normal.find('.js-variable_cost_perc_field');
    let rn_markup_perc_field = retail_normal.find('.js-markup_perc_field');

    let rf_amount_field = retail_fixed_markup.find('.js-amount_field');
    let rf_variable_cost_perc_field = retail_fixed_markup.find('.js-variable_cost_perc_field');
    let rf_markup_perc_field = retail_fixed_markup.find('.js-markup_perc_field');

    let calculation_base_total = parseFloat(calculation_base)
    calculation_base_total = (isNaN(calculation_base_total)) ? 0.0 : calculation_base_total;

    let markup_perc = parseFloat(rf_markup_perc_field.val())
    markup_perc = (isNaN(markup_perc)) ? 0.0 : markup_perc;

    let price = 0.0;
    if(calculation_base_total != 0.0){
      price = ((markup_perc / 100) * calculation_base_total + calculation_base_total).toFixed(2);
    }

    if (price == Infinity){
      rf_amount_field.val((0.0).toFixed(2));
      mn_amount_field.val((0.0).toFixed(2));
      mf_amount_field.val((0.0).toFixed(2));
      rn_amount_field.val((0.0).toFixed(2));
    } else {
      rf_amount_field.val(price);
      mn_amount_field.val(price);
      mf_amount_field.val(price);
      rn_amount_field.val(price);
    }


    let variable_cost_perc = 0.0;
    if(price != 0.0){
      variable_cost_perc = (calculation_base_total / price * 100).toFixed(2);
    }

    if (variable_cost_perc == Infinity){
      rf_variable_cost_perc_field.val((0.0).toFixed(2));
      mn_variable_cost_perc_field.val((0.0).toFixed(2));
      mf_variable_cost_perc_field.val((0.0).toFixed(2));
      rn_variable_cost_perc_field.val((0.0).toFixed(2));
    } else {
      rf_variable_cost_perc_field.val(variable_cost_perc);
      mn_variable_cost_perc_field.val(variable_cost_perc);
      mf_variable_cost_perc_field.val(variable_cost_perc);
      rn_variable_cost_perc_field.val(variable_cost_perc);
    }

    mn_markup_perc_field.val(markup_perc);
    mf_markup_perc_field.val(markup_perc);
    rn_markup_perc_field.val(markup_perc);
  }


  calculateServiceNormal(insertedItem){
    let _this = this;

    let service_normal = $(insertedItem).find('.js-product_price_service_normal');
    let service_fixed_markup = $(insertedItem).find('.js-product_price_service_fixed_markup');

    let sn_amount_field = service_normal.find('.js-amount_field');
    let sn_markup_perc_field = service_normal.find('.js-markup_perc_field');

    let sf_amount_field = service_fixed_markup.find('.js-amount_field');
    let sf_markup_perc_field = service_fixed_markup.find('.js-markup_perc_field');

    sf_amount_field.val(0.0);
    sf_markup_perc_field.val(0.0);

    sn_markup_perc_field.val(0.0);
  }


  calculateServiceFixedMarkup(insertedItem){
    let _this = this;

    let service_normal = $(insertedItem).find('.js-product_price_service_normal');
    let service_fixed_markup = $(insertedItem).find('.js-product_price_service_fixed_markup');

    let sn_amount_field = service_normal.find('.js-amount_field');
    let sn_markup_perc_field = service_normal.find('.js-markup_perc_field');

    let sf_amount_field = service_fixed_markup.find('.js-amount_field');
    let sf_markup_perc_field = service_fixed_markup.find('.js-markup_perc_field');

    sn_amount_field.val(0.0);
    sn_markup_perc_field.val(0.0);

    sf_amount_field.val(0.0);
  }


  calculateManufacturableProductCostFixedMarkup(insertedItem){
    let _this = this;
    let calculation_base = $("#js-product_composition_item_total_field").val();

    let product_cost_cost_field = $(insertedItem).find('.js-product_cost_cost_field');
    let product_cost_markup_perc_field = $(insertedItem).find('.js-product_cost_markup_perc_field');

    let calculation_base_total = parseFloat(calculation_base)
    calculation_base_total = (isNaN(calculation_base_total)) ? 0.0 : calculation_base_total;

    let markup_perc = parseFloat(product_cost_markup_perc_field.val())
    markup_perc = (isNaN(markup_perc)) ? 0.0 : markup_perc;

    let cost = 0.0;
    if(calculation_base_total != 0.0){
      cost = ((markup_perc / 100) * calculation_base_total + calculation_base_total).toFixed(2);
    }

    if (cost == Infinity){
      product_cost_cost_field.val((0.0).toFixed(2));
    } else {
      product_cost_cost_field.val(cost);
    }
  }



  calculateManufacturableProductCostNormal(insertedItem){
    let _this = this;
    let calculation_base = $("#js-product_composition_item_total_field").val();

    let product_cost_cost_field = $(insertedItem).find('.js-product_cost_cost_field');
    let product_cost_markup_perc_field = $(insertedItem).find('.js-product_cost_markup_perc_field');


    let calculation_base_total = parseFloat(calculation_base)
    calculation_base_total = (isNaN(calculation_base_total)) ? 0.0 : calculation_base_total;

    let cost = parseFloat(product_cost_cost_field.val())
    cost = (isNaN(cost)) ? 0.0 : cost;

    let markup_perc = 0.0;
    if(calculation_base_total != 0.0){
      markup_perc = (((cost - calculation_base_total) / calculation_base_total) * 100).toFixed(2);
    }

    if (markup_perc == Infinity){
      product_cost_markup_perc_field.val((0.0).toFixed(2));
    } else {
      product_cost_markup_perc_field.val(markup_perc);
    }
  }
  
}
