import 'jquery.animate/jquery.animate'

export class DOMManager {
  static addOption(container, newElement){
    $(container).append(newElement).trigger('select2:select');
  }

  static addRow(container, newElement, color="#CCFFCC"){
    var newElement = $(newElement);
    newElement.css('opacity', '0');
    $(container).prepend(newElement);
    newElement.animate(
      {
        opacity: 1,
        backgroundColor: color
      },
      500,
      function(){
        $(this).animate({ backgroundColor: "initial" }, 1500, function() {
          $(this).removeAttr('style');
        });
      }
    );
  }
  static refreshRow(newElement, color="#FFFAAA"){
    var newElement = $(newElement);
    var elementId = newElement.attr('id');
    var elementIdExtra = '.'+newElement.attr('id')+'_extra';
    $(elementIdExtra).remove();
    var elements = $('[id="'+elementId+'"]');
    $(elements).replaceWith(newElement);
    newElement.animate(
      {
        backgroundColor: color
      }, 
      500, 
      function(){
        $(this).animate({ backgroundColor: "transparent" }, 1500);
      }
    );
  }

  static removeRow(elementId, color="#FFCCCC"){
    var element = $('#'+elementId);
    var elementIdExtra = '.'+elementId+'_extra';
    $(elementIdExtra).remove();
    element.animate(
      {
        backgroundColor: color
      }, 
      500,
      function(){
        $(this).fadeOut(function(){ 
          $(this).remove(); 
        });
      }
    )
  }
}