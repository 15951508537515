
import { FloorPlanObject } from "./floor-plan-object.js";

export class FloorPlanObjectWall extends FloorPlanObject {
  constructor(floorPlanLayout, dataJson='') {
    super(floorPlanLayout, dataJson);
    let _this = this;
    
    _this.settings = {
      width: 5,
      height: 5,
      fontSize: 11,
    }
    _this.kind = 'wall';
  
    _this.loadOrCreateShape();
    _this.bindMainEvents();
    _this.bindEvents();    
  }

  bindEvents () {
    let _this = this;

    //----------------------------- START EDIT MODE
    if(_this.floorPlanLayout.mode == 'edit'){
      
    }
    //----------------------------- END EDIT MODE

    //----------------------------- START NORMAL MODE
    if(_this.floorPlanLayout.mode == 'normal'){

    }
    //----------------------------- END NORMAL MODE
  }


  createShape(){
    let _this = this;

    _this.box = new Konva.Rect({
      x: 0,
      y: 0,
      stroke: '#000000',
      strokeWidth: 1,
      fill: '#000000', 
      width: 200,
      height: _this.settings.height,          
      strokeScaleEnabled: false,
      name: 'Shape',
      kind: _this.kind
    });

    _this.group = new Konva.Group({
      x: 0,
      y: 0,
      draggable: false,
      name: 'FloorPlanObjectWall'
    });

    _this.group.add(_this.box);
  }
}
