import Chart from "./chart.js";
//import PluginDataLabels from './chartjs-plugin-datalabels';
export class ChartComponent {
  initialize() {
    $(".js-pie-charte").each(function () {
      var ctx = $(this);

      var labels = eval(ctx.data("labels"));
      var colors = eval(ctx.data("colors"));
      var percs = eval(ctx.data("percs"));
      var values = eval(ctx.data("values"));

      var config = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: percs,
              backgroundColor: colors,
              hoverOffset: 10,
              value: values,
            },
          ],
          labels: labels,
        },
        options: {
          tooltips: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#858796",
            borderColor: "#dddfeb",
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            caretPadding: 10,
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.labels[tooltipItem.index];
                var value =
                  data.datasets[tooltipItem.datasetIndex].value[
                    tooltipItem.index
                  ];
                return label + ": " + value;
              },
            },
          },
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          legend: false,
          cutoutPercentage: 80,
        },
      };

      window.charts = [];
      window.charts.push(new Chart(ctx[0].getContext("2d"), config));
    });
  }
}
