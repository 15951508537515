import '../vendor/image-cropper.js'

export class ImageCropper {
  
  initialize() {
    $('.image-cropper-container').each(function(){
      if(!$(this).hasClass('active')){
        $(this).image_cropper();
      }
    });
  }
}
