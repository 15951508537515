export class TooltipPopover {
  
  initialize() {
    // Enable Bootstrap tooltips via data-attributes globally
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip-error"]').each(function(){
      if (($(this).is('select')) && ($(this).data('select2') != undefined)){
        $(this).data('toggle');
        $(this).attr('title');
        let parent = $(this).parent();
        parent.data('toggle', $(this).data('toggle'));
        parent.attr('title', $(this).attr('title'));
        $(this).removeAttr('data-toggle');
        $(this).removeAttr('title');
        parent.tooltip({template: '<div class="tooltip tooltip-error" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'});
      }
      else {
        $(this).tooltip({template: '<div class="tooltip tooltip-error" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'});
      }
    })

    // Enable Bootstrap popovers via data-attributes globally
    $('[data-toggle="popover"]').popover({trigger: "focus"});
  }
}

