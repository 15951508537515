
export class BuyCalculation {

  //----------------------------------------------------------------
  // Calcula el total de la compra
  //----------------------------------------------------------------


  static calculateTotal(context, controllerContainer){

    //--------------------------------------------------------
    //----- Calculate subtotal
    let buySubtotalContainer = $(controllerContainer).find('.js-buy_subtotal_container');
    let buySubtotalField = $(controllerContainer).find('.js-buy_subtotal_field');
    
    let subtotal = 0.0;
    $('#buy_items_container .nested-fields:visible .js-buy_item_product_total_field').each(function(){
      subtotal += context.parseFloatOrZero($(this).val());
    });


    let buyInvoiceTypeValue = $(controllerContainer).find('.js-buy_invoice_type_field').val();
    if (buyInvoiceTypeValue == 'invoice_a'){
      $('#buy_items_container .nested-fields:visible .js-buy_item_product_tax_field').each(function(){
        subtotal += context.parseFloatOrZero($(this).val());
      });
    }
    
    subtotal = context.round(subtotal);
    buySubtotalContainer.html(context.formatToCurrency(subtotal));
    buySubtotalField.val(subtotal);
    
    //--------------------------------------------------------
    //----- Calculate discount
    let discount_kind = $(controllerContainer).find('.js-buy_discount_kind_field').val();
    let discount_value = $(controllerContainer).find('.js-buy_discount_value_field').val();

    let buyDiscountContainer = $(controllerContainer).find('.js-buy_discount_container');
    let buyDiscountField = $(controllerContainer).find('.js-buy_discount_field');

    discount_value = context.parseFloatOrZero(discount_value);

    let discount = 0.0;
    let discount_perc = 0.0;

    if (discount_kind == 'discount_kind_perc'){
      // Si descuento es porcentual
      discount = subtotal * (discount_value / 100);
      discount_perc = discount_value;
    }
    else{
      // Si descuento es fijo
      discount = discount_value;
      discount_perc = discount_value  / subtotal * 100.0;
    }
    discount = context.round(discount);

    buyDiscountContainer.html(context.formatToCurrency(discount));
    buyDiscountField.val(discount);


    //--------------------------------------------------------
    //----- Calculate net and tax
    let buyTaxContainer = $(controllerContainer).find('.js-buy_tax_container');
    let buyTaxField = $(controllerContainer).find('.js-buy_tax_field');
    let buyNetContainer = $(controllerContainer).find('.js-buy_net_container');
    let buyNetField = $(controllerContainer).find('.js-buy_net_field');
    

    let net = 0.0;
    $('#buy_items_container .nested-fields:visible .js-buy_item_product_total_field').each(function(){
      net += context.parseFloatOrZero($(this).val());
    });

    let net_discount = net * (discount_perc / 100);
    net_discount = context.round(net_discount);
    net = net - net_discount;
    net = context.round(net);


    let tax = 0.0;
    $('#buy_items_container .nested-fields:visible .js-buy_item_product_tax_field').each(function(){
      tax += context.parseFloatOrZero($(this).val());
    });

    let tax_discount = tax * (discount_perc / 100);
    tax = tax - tax_discount;
    tax = context.round(tax);


    buyTaxContainer.html(context.formatToCurrency(tax));
    buyTaxField.val(tax);
    buyNetContainer.html(context.formatToCurrency(net));
    buyNetField.val(net);



    //--------------------------------------------------------
    //----- Calculate buy_taxes
    let buy_buy_taxes_total_container = $(controllerContainer).find('.js-buy_buy_taxes_total_container');
    let buy_buy_taxes_total_field = $(controllerContainer).find('.js-buy_buy_taxes_total_field');



    let buy_taxes_total = 0.0;
    $('#buy_taxes_container .nested-fields:visible .js-buy_tax_amount_field').each(function(){
      buy_taxes_total += context.parseFloatOrZero($(this).val());
    });

    buy_buy_taxes_total_container.html(context.formatToCurrency(buy_taxes_total));
    buy_buy_taxes_total_field.val(buy_taxes_total);

    //--------------------------------------------------------
    //----- Calculate total
    let buyTotalContainer = $(controllerContainer).find('.js-buy_total_container');
    let buyTotalField = $(controllerContainer).find('.js-buy_total_field');
    let total = subtotal - discount + buy_taxes_total;
    total = context.round(total);

    buyTotalContainer.html(context.formatToCurrency(total));
    buyTotalField.val(total);
  }




  //----------------------------------------------------------------
  // Calcula la linea de la compra
  //----------------------------------------------------------------

  static calculateBuyItem(context, controllerContainer, buyItem){

    //--------------------------------------------------------
    //----- Calculate subtotal
    let buyItemPriceValue = buyItem.find('.js-buy_item_product_price_field').val();
    let buyItemAmountValue = buyItem.find('.js-buy_item_product_amount_field').val();
    let buyItemSubtotalField = buyItem.find('.js-buy_item_product_subtotal_field');

    buyItemPriceValue = context.parseFloatOrZero(buyItemPriceValue);
    buyItemPriceValue = context.round(buyItemPriceValue);

    buyItemAmountValue = context.parseFloatOrZero(buyItemAmountValue);
    buyItemAmountValue = context.round(buyItemAmountValue);

    let subtotal = buyItemPriceValue * buyItemAmountValue;

    subtotal = context.round(subtotal);

    buyItemSubtotalField.val(subtotal);

    //--------------------------------------------------------
    //----- Calculate discount
    let buyItemDiscountKindValue = buyItem.find('.js-buy_item_product_discount_kind_field').val();
    let buyItemDiscountValueValue = buyItem.find('.js-buy_item_product_discount_value_field').val();

    buyItemDiscountValueValue = context.parseFloatOrZero(buyItemDiscountValueValue);
    buyItemDiscountValueValue = context.round(buyItemDiscountValueValue);

    let discount = 0.0;

    if (buyItemDiscountKindValue == 'discount_kind_perc'){
      discount = subtotal * (buyItemDiscountValueValue / 100)
    }
    else{
      discount = buyItemDiscountValueValue * buyItemAmountValue;
    }

    discount = context.round(discount);

    //--------------------------------------------------------
    //----- Calculate total
    let productTotalField = buyItem.find('.js-buy_item_product_total_field');

    let total = subtotal - discount;
    total = context.round(total);

    productTotalField.val(total);

    //--------------------------------------------------------
    //----- Calculate tax
    let buyItemTaxPercValue = buyItem.find('.js-buy_item_product_tax_perc_field').val();
    let buyItemTaxField = buyItem.find('.js-buy_item_product_tax_field');
    let tax_profile = $(controllerContainer).find('.js-sale_point_tax_profile_field').val();
    let supplier_tax_profile = $(controllerContainer).find('.js-buy_supplier_tax_profile_field').val();
    let buyBuyChannelValue = $(controllerContainer).find('.js-buy_buy_channel_field').val();
    let buyInvoiceTypeValue = $(controllerContainer).find('.js-buy_invoice_type_field').val();

    buyItemTaxPercValue = context.parseFloatOrZero(buyItemTaxPercValue);

    if(
      (
        (buyBuyChannelValue == 'channel_a') &&
        (tax_profile == 'enroled_responsable') &&
        (supplier_tax_profile == 'enroled_responsable')
      ) ||
      (
        (buyInvoiceTypeValue == 'invoice_a') &&
        (buyBuyChannelValue == 'channel_a') &&
        (tax_profile == 'monotax_responsable') &&
        (supplier_tax_profile == 'enroled_responsable')
      )
    ){

      let tax = (total * (buyItemTaxPercValue / 100));
      tax = context.round(tax);
      buyItemTaxField.val(tax);
    }
    else{
      buyItemTaxField.val(0.0);
    }
  }
}
