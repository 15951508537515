import "moment/dist/locale/es";
import "tempusdominus-bootstrap-4";

export class Datetimepicker {
  initialize() {
    $(".js-datetimepicker").datetimepicker({
      locale: "es",
      icons: {
        time: "fa fa-clock",
      },
    });

    $(".js-datepicker").datetimepicker({
      locale: "es",
      format: "L",
    });

    $(".js-timepicker").datetimepicker({
      locale: "es",
      format: "LT",
    });

    $(".js-periodpicker").datetimepicker({
      locale: "es",
      viewMode: "years",
      format: "YYYY-MM",
    });
  }
}

// Use `debug: true` to inspect dropdown-menu
