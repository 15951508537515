
import  ApplicationController from "../application_controller.js"
import consumer from '../../channels/consumer';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect DataReportsForm controller")

      let _this = this;
      let controllerContainer = _this.context.element;

      let dataReportId = $('#data_report_id').val();

      _this.channel = consumer.subscriptions.create({channel: "DataReportChannel", data_report_id: dataReportId}, {
        connected() {
          // Called when the subscription is ready for use on the server
          console.log("Connected to the DataReportChannel "+dataReportId);
        },
      
        disconnected() {
          // Called when the subscription has been terminated by the server
          console.log("Disconnected from the DataReportChannel "+dataReportId);
        },
      
        received(data) {
          $('#js-data_report_container').html(data.message);
        }
      });
    }
  }

}
