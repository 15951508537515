
export class Modals {
  static nextModal(){
    if ($('.js-modal.active').length == 0) {
      $('#js-appmodal').addClass('active');
    }
    else if ($('#js-appmodal').hasClass('active')) {
      $('#js-appmodal').css('opacity', 0.1);
      $('#js-appmodal').removeClass('active');
      $('#js-appmodal-2').addClass('active');
    }
    else if ($('#js-appmodal-2').hasClass('active')) {
      $('#js-appmodal-2').css('opacity', 0.1);
      $('#js-appmodal-2').removeClass('active');
      $('#js-appmodal-3').addClass('active');
    }
  }

  static prevModal(){
    if ($('#js-appmodal-3').hasClass('active')) {
      $('#js-appmodal-3').removeClass('active');
      $('#js-appmodal-2').css('opacity', 1);
      $('#js-appmodal-2').addClass('active');
      $('body').addClass('modal-open');
    }
    else if ($('#js-appmodal-2').hasClass('active')) {
      $('#js-appmodal-2').removeClass('active');
      $('#js-appmodal').css('opacity', 1);
      $('#js-appmodal').addClass('active');
      $('body').addClass('modal-open');
    }
    else if ($('#js-appmodal').hasClass('active')) {
      $('#js-appmodal').removeClass('active');
    }
  }

  static modalShow(){
    this.nextModal();
    var _this = this;
    $('.js-modal.active').modal('show');
    $('.js-modal.active').off('hidden.bs.modal');
    $('.js-modal.active').on('hidden.bs.modal', (function () {
      _this.prevModal();
    }));
  }

  static modalHide(){
    $('.js-modal.active').modal('hide');
  }

  static modalSize(modalClass='modal-md'){
    $('.js-modal.active .modal-dialog').attr('class', 'modal-dialog ' + modalClass);
  }

  static modalFill(content){
    $('.js-modal.active .modal-content').html(content);
    $('.js-modal.active').trigger('show.bs.modal');
  }

  static modalReset(){
    $('.js-modal.active .modal-dialog').attr('class', 'modal-dialog ');
    $('.js-modal.active .modal-content').html('');
  }
}
