
import  ApplicationController from "../application_controller.js"
import { DOMManager } from '../../lib/dom-manager.js';
import { FlashMessages } from '../../lib/flash-messages.js';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect StockTransfer controller")
    }
  }

  // Success state element
  stateSuccess(event) {
    const [data, status, xhr] = event.detail;
    const targetDomId = event.target.dataset.domId;
    DOMManager.removeRow(targetDomId, '#FFFAAA');
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

  // Fail state element
  stateError(event) {
    const [data, status, xhr] = event.detail;
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

}
