
import  ApplicationController from "../application_controller.js"
import { DOMManager } from '../../lib/dom-manager.js';
import { Modals } from '../../lib/modals.js';
import { FlashMessages } from '../../lib/flash-messages.js';
import { Invoice } from '../../lib/invoice.js';

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect Sale controller");
      let _this = this;
      let controllerContainer = _this.context.element;

      $('.local_sale_form').on('submit', function(event){
        Invoice.sale_modal(event, null, false);
      });
    }
  }


  // Before form submit
  beforeSendForm(event){
    const [data, settings] = event.detail;
    let _this = this;    
    if(!$(event.target).is('#js-add-button')){
      let ajax = $(event.target).is('#sale_form');
      Invoice.sale_modal(event, settings, ajax);
    }
  }

  // Triggerado por el cambio de medio de pago 
  changeFilterPaymentMethod() {
    let _this = this;
    let controllerContainer = _this.context.element;
    _this.updatePaymentMethodData(controllerContainer);
  }

  // Success state element
  stateSuccess(event) {
    const [data, status, xhr] = event.detail;
    const targetDomId = event.target.dataset.domId;
    DOMManager.removeRow(targetDomId, '#FFFAAA');
    FlashMessages.message(this.parseFlashMessage(xhr));
  }

  // Fail state element
  stateError(event) {
    const [data, status, xhr] = event.detail;

    event.stopPropagation();
    if (xhr.responseText != ''){
      Modals.modalShow();
      Modals.modalSize('modal-lg');
      Modals.modalFill(xhr.responseText);
    }
    FlashMessages.message(this.parseFlashMessage(xhr));
  }


  private


  // Toggle campos de cashbox o bank_account
  updatePaymentMethodData(controllerContainer) {
    let _this = this;
    let paymentMethodValue = $(controllerContainer).find('.js-filter_payment_method_field').val();
    let paymentMethodCashField = $(controllerContainer).find('.js-filter_cashbox_field');
    let paymentMethodCashBox = $(controllerContainer).find('.js-filter_cashbox_box');
    let paymentMethodBankTransferField = $(controllerContainer).find('.js-filter_bank_account_field');
    let paymentMethodBankTransferBox = $(controllerContainer).find('.js-filter_bank_account_box');

    if (paymentMethodValue == 'cash') {
      paymentMethodCashBox.show();
      paymentMethodBankTransferBox.hide();
      paymentMethodBankTransferField.val('').trigger('change');
    }
    else if(paymentMethodValue == 'bank_transfer') {
      paymentMethodBankTransferBox.show();
      paymentMethodCashBox.hide();
      paymentMethodCashField.val('').trigger('change');
    }
    else {
      paymentMethodCashBox.hide();
      paymentMethodBankTransferBox.hide();
      paymentMethodCashField.val('').trigger('change');
      paymentMethodBankTransferField.val('').trigger('change');
    }
  }
}
