
import { Alert } from "bootstrap";
import  ApplicationController from "../application_controller.js"

export default class extends ApplicationController {

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("Connect ClientForm controller");
      let _this = this;
      let controllerContainer = _this.context.element;

      _this.updateTaxProfileData(controllerContainer);
      _this.updatePortionInvoiceContainer(controllerContainer);
    }
  }

  changeClientType(){
    let _this = this;
    let client_type = $('.js-client_type').val();

    if (client_type == 'normal') {
      $('#js-sale_point_id_container').hide();
      $(".js-sale_point").val(null).trigger("change");
    } else {
      $('#js-sale_point_id_container').show();
    }
  }

  changeTaxProfile(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.updateTaxProfileData(controllerContainer);
  }






  changeSaleChannel(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setPortionInvoiceContainer(controllerContainer);
    _this.updatePortionInvoiceContainer(controllerContainer);
  }

  changePortionInvoice(){
    let _this = this;
    let controllerContainer = _this.context.element;

    _this.setPortionInvoiceContainer(controllerContainer);
    _this.updatePortionInvoiceContainer(controllerContainer);
  }


  private

  setPortionInvoiceContainer(controllerContainer){
    let _this = this;

    let sale_channel = $(controllerContainer).find('.js-sale_channel_field').val();
    let portion_invoice_perc_field = $(controllerContainer).find('.js-portion_invoice_perc_field')
    let portion_invoice_field = $(controllerContainer).find('.js-portion_invoice_field')

    if(sale_channel == 'channel_b'){
      if (!((portion_invoice_field.is(':checked')) || (portion_invoice_field.val() == 'true'))) {
        portion_invoice_perc_field.val(0.0);
      }
    }
    else if (sale_channel == 'channel_a'){
      portion_invoice_field.prop('checked', false);
      portion_invoice_perc_field.val(0.0);
    }
  }

  updatePortionInvoiceContainer(controllerContainer){
    let _this = this;

    let sale_channel = $(controllerContainer).find('.js-sale_channel_field').val();
    let portion_invoice_container = $(controllerContainer).find('#js-portion_invoice-container');
    let portion_invoice_perc_field = $(controllerContainer).find('.js-portion_invoice_perc_field')
    let portion_invoice_field = $(controllerContainer).find('.js-portion_invoice_field')

    if(sale_channel == 'channel_b'){
      portion_invoice_container.fadeIn();
      if (!((portion_invoice_field.is(':checked')) || (portion_invoice_field.val() == 'true'))) {
        portion_invoice_perc_field.attr('readonly','readonly');
      }
      else{
        portion_invoice_perc_field.removeAttr('readonly');
      }
    }
    else if (sale_channel == 'channel_a'){
      portion_invoice_container.hide();
      portion_invoice_perc_field.attr('readonly','readonly');
    }
  }

  updateTaxProfileData(controllerContainer){
    let tax_profile_enroled_responsable_field = $(controllerContainer).find('#client_tax_profile_enroled_responsable');
    let tax_profile_monotax_responsable_field = $(controllerContainer).find('#client_tax_profile_monotax_responsable');
    let tax_profile_exempted_vat_field = $(controllerContainer).find('#client_tax_profile_exempted_vat');
    let tax_profile_enroled_responsable_data_container = $(controllerContainer).find('#js-tax_profile_enroled_responsable_data_container');
    let tax_profile_other_data_container = $(controllerContainer).find('#js-tax_profile_other_data_container');

    if (
        (tax_profile_enroled_responsable_field.is(':checked') || tax_profile_enroled_responsable_field.val() == 'true') ||
        (tax_profile_monotax_responsable_field.is(':checked') || tax_profile_monotax_responsable_field.val() == 'true') ||
        (tax_profile_exempted_vat_field.is(':checked') || tax_profile_exempted_vat_field.val() == 'true')
      )  {
      tax_profile_other_data_container.hide();
      //$('#client_first_name').val('');
      //$('#client_last_name').val('');
      //$('#client_dni').val('');
      tax_profile_enroled_responsable_data_container.show();
    } else {
      tax_profile_enroled_responsable_data_container.hide();   
      //$('#client_company_name').val('');
      //$('#client_dni').val('');
      tax_profile_other_data_container.show();
    }
  }
}

